import { MoovyQueryHandler, MoovyScrollToTop } from '../../components'

import CatalogueTabs from './catalogueTabs'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import CustomerInformation from '../customers/customerInformation/customerInformation'
import { Grid } from '@mui/material'
import { QUERY_CATALOGUES_BY_REF } from '../../apis/moovyQueryKeys'
import React from 'react'
import { appRoutes } from '../../app/permissions'
import useAuth from '../../hooks/useAuth'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const Catalogue = () => {
  const { catalogueRef } = useParams()
  const { tenant } = useAuth()

  const catalogueId = catalogueRef || ''

  const queryCatalogue = useQuery({
    queryKey: [QUERY_CATALOGUES_BY_REF, catalogueId],
    queryFn: () => CataloguesAPI.getCataloguesByRef(catalogueId),
    enabled: !!catalogueId
  })

  const customerId = queryCatalogue.data?.customer?.id
  const catalogue = queryCatalogue.data

  const lastItem = catalogue?.name || catalogue?.customer?.name || ''

  return (
    <MoovyQueryHandler query={queryCatalogue} rootPageIfNotFoundError>
      <MoovyScrollToTop />
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <CustomerInformation
            header={lastItem}
            customerId={customerId}
            customBreadcrumbs={{
              lastBreadcrumb: lastItem,
              customBreadcrumbs: !tenant
                ? [
                    {
                      route: `${appRoutes.PLACES}/${catalogue?.place.id}`,
                      name: catalogue?.place.name || ''
                    },
                    {
                      route: `${appRoutes.OFFERINGS}/${catalogue?.offering?.id}`,
                      name: catalogue?.offering?.name || ''
                    }
                  ]
                : undefined
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CatalogueTabs catalogue={catalogue} />
        </Grid>
      </Grid>
    </MoovyQueryHandler>
  )
}

export default Catalogue
