import {
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyTable
} from '..'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { appRoutes, appSubRoutes } from '../../app/permissions'

import { FormattedMessage } from 'react-intl'
import { PlaceResponseDTO } from '../../apis/places/dto'
import { UseQueryResult } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

interface TableSubPlacesProps {
  queryPlaces: UseQueryResult<PlaceResponseDTO[], any>
  places: PlaceResponseDTO[]
}

const TableSubPlaces = (props: TableSubPlacesProps) => {
  const navigate = useNavigate()
  const { queryPlaces, places } = props

  const onClickRow = (placeRef: string, parentPlaceRef?: string) => {
    !!parentPlaceRef
      ? navigate(
          `${appRoutes.PLACES}/${parentPlaceRef}${appSubRoutes.SUB_PLACES}/${placeRef}`
        )
      : navigate(`${appRoutes.PLACES}/${placeRef}`)
  }

  return (
    <>
      <MoovyGrid
        emptyTextId="components.tableSubPlaces.action.empty"
        errorTextId="components.tableSubPlaces.action.failed"
        query={queryPlaces}
      >
        {places.map((row) => (
          <MoovyGridCard
            key={row.id}
            cardTitle={row.name}
            cardSecondaryTitle={<MoovyDateTime utcDateTime={row.updatedAt} />}
            cardContentList={[row.streetAddress, row.postalCode, row.city]}
            onCardClicked={() => onClickRow(row.id, row.parentId)}
          />
        ))}
      </MoovyGrid>
      <MoovyTable
        items={places}
        emptyTextId="components.tableSubPlaces.action.empty"
        errorTextId="components.tableSubPlaces.action.failed"
        query={queryPlaces}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.name" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.customer" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.streetAddress" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.postalCode" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.city" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="components.tableSubPlaces.header.capacity" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="places-table-body">
          {places.map((row) => (
            <TableRow
              key={row.id}
              hover
              sx={{ cursor: 'pointer' }}
              onClick={() => onClickRow(row.id, row.parentId)}
            >
              <TableCell>
                <MoovyLink bold>{row.name}</MoovyLink>
              </TableCell>
              <TableCell>{row.customer?.name}</TableCell>
              <TableCell>{row.streetAddress}</TableCell>
              <TableCell>{row.postalCode}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{row.capacity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MoovyTable>
    </>
  )
}

export default TableSubPlaces
