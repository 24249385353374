import * as yup from 'yup'

import { Formik } from 'formik'
import { MoovyForm } from '../../components'
import { useIntl } from 'react-intl'
import { useState } from 'react'

export type FormPasswordProps = {
  onSubmit: (values: { oldPassword: string; newPassword: string }) => void
  formReference: string
}

const FormPassword = (props: FormPasswordProps) => {
  const intl = useIntl()

  interface DefaultValues {
    oldPassword: string
    newPassword: string
    confirmNewPassword: string
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues] = useState<DefaultValues>({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })

  const SchemaValidation = () => {
    return yup.object().shape({
      oldPassword: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      newPassword: yup
        .string()
        .trim()
        .test(
          'newPassword',
          intl.formatMessage({
            id: 'page.users.formPassword.errors.password'
          }),
          function (newPassword?: string) {
            const { confirmNewPassword } = this.parent
            if (newPassword && confirmNewPassword) {
              return confirmNewPassword.localeCompare(newPassword) === 0
            }
            return false
          }
        ),
      confirmNewPassword: yup
        .string()
        .trim()
        .test(
          'confirmNewPassword',
          intl.formatMessage({
            id: 'page.users.formPassword.errors.password'
          }),
          function (confirmNewPassword?: string) {
            const { newPassword } = this.parent
            if (confirmNewPassword && newPassword) {
              return newPassword.localeCompare(confirmNewPassword) === 0
            }
            return false
          }
        )
    })
  }

  const onSubmitFormValues = (values: DefaultValues) => {
    const { oldPassword, newPassword } = values
    props.onSubmit({
      newPassword: newPassword.trim(),
      oldPassword: oldPassword.trim()
    })
  }

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={SchemaValidation()}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTextField
                type="password"
                name="oldPassword"
                data-testid="oldPassword"
                value={values.oldPassword}
                label={intl.formatMessage({
                  id: 'page.users.formPassword.label.oldPassword'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.users.formPassword.label.oldPassword'
                })}
                onChange={handleChange}
                error={!!errors.oldPassword}
                errorText={errors.oldPassword}
              />
              <MoovyForm.FormTextField
                type="password"
                name="newPassword"
                data-testid="newPassword"
                value={values.newPassword}
                label={intl.formatMessage({
                  id: 'page.users.formPassword.label.newPassword'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.users.formPassword.label.newPassword'
                })}
                onChange={handleChange}
                error={!!errors.newPassword}
                errorText={errors.newPassword}
              />
              <MoovyForm.FormTextField
                type="password"
                name="confirmNewPassword"
                data-testid="confirmNewPassword"
                value={values.confirmNewPassword}
                label={intl.formatMessage({
                  id: 'page.users.formPassword.label.confirmNewPassword'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.users.formPassword.label.confirmNewPassword'
                })}
                onChange={handleChange}
                error={!!errors.confirmNewPassword}
                errorText={errors.confirmNewPassword}
              />
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormPassword
