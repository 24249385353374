import '@aws-amplify/ui-react/styles.css'
import './styles/app.scss'
import './i18n'

import { Amplify } from 'aws-amplify'
import App from './app/app'
import { Provider } from 'react-redux'
import React from 'react'
import * as Sentry from '@sentry/react'
import { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { store } from './redux/store'
import {
  COGNITO_USER_POOL_ID,
  COGNITO_CLIENT_ID,
  ENVIRONMENT,
  SENTRY_DSN,
  GTAG_ID
} from './envConfig'

import addGoogleAnalytics from './utils/addGoogleAnalytics'
import addSentry from './utils/addSentry'

if (SENTRY_DSN) {
  addSentry()
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_USER_POOL_ID,
      userPoolClientId: COGNITO_CLIENT_ID,
      loginWith: { email: true }
    }
  }
})

const root = createRoot(document.getElementById('root') as HTMLDivElement)
if (ENVIRONMENT === 'production' && GTAG_ID) {
  addGoogleAnalytics(GTAG_ID)
}

root.render(
  <React.StrictMode>
    {/* Suspense for the case when translations are not yet loaded. Not in use because localizations are immediately loaded*/}
    {/*<Suspense fallback="...is loading">*/}
    <Provider store={store}>
      <App />
    </Provider>
    {/*</Suspense>*/}
  </React.StrictMode>
)
