import {
  CustomerCreateLandlordDTO,
  CustomerResponseDTO
} from '../../apis/customers/dto'
import FormCustomer, { DefaultCustomerValues } from '../customers/formCustomer'
import { MoovyButton, MoovyMutationError } from '../../components'

import CustomersAPI from '../../apis/customers/customersAPI'
import { FormattedMessage } from 'react-intl'
import { Grid } from '@mui/material'
import { LoginStepProps } from '../utils/pagesTypeUtils'
import { MoovyToast } from '../../utils/moovyToast'
import useCustomerData from '../../hooks/useCustomerData'
import { useMutation } from '@tanstack/react-query'

const StepLandlord = (props: LoginStepProps) => {
  const { setCustomer } = useCustomerData()

  const FORM_REFERENCE = 'formStepAddLandlord'

  const createCustomerMutation = useMutation({
    mutationFn: (dto: CustomerCreateLandlordDTO) =>
      CustomersAPI.createCustomer(dto),
    onSuccess: (response) => {
      const customer: CustomerResponseDTO = response.data || {}
      setCustomer(customer, true)
      MoovyToast.showToast(
        <FormattedMessage
          id="page.steps.landlord.actions.added"
          values={{ name: customer.name }}
        />
      )
      props.onStepCompleted()
    }
  })

  const createCustomer = (values: DefaultCustomerValues) => {
    const { name, streetAddress, postalCode, city, businessId } = values
    const dto: CustomerCreateLandlordDTO = {
      type: 'LANDLORD',
      name,
      streetAddress,
      postalCode,
      city,
      businessId
    }
    createCustomerMutation.mutate(dto)
  }

  return (
    <>
      <h1>
        <FormattedMessage id="page.steps.landlord.title" />
      </h1>
      <MoovyMutationError
        mutation={createCustomerMutation}
        errorTitleKey="common.actions.add.error"
      />
      <p>
        <FormattedMessage id="page.steps.landlord.info" />
      </p>
      <FormCustomer
        onSubmit={(values) => {
          createCustomer(values)
        }}
        formReference={FORM_REFERENCE}
      />
      <Grid container pt={12} justifyContent="flex-end">
        <MoovyButton
          type="submit"
          mutation={createCustomerMutation}
          form={FORM_REFERENCE}
        >
          <FormattedMessage id="common.buttons.next" />
        </MoovyButton>
      </Grid>
    </>
  )
}

export default StepLandlord
