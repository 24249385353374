// only allow these values for ENVIRONMENT, otherwise abort
const allowedEnvironmentValues = ['development', 'sales', 'production']
const rawEnvironment = process.env.ENVIRONMENT?.trim().toLowerCase()
if (
  !rawEnvironment ||
  !allowedEnvironmentValues.includes(rawEnvironment || '')
) {
  throw new Error(
    `Invalid environment value: ${
      process.env.ENVIRONMENT
    }. Must be one of ${allowedEnvironmentValues.join(', ')}`
  )
}

export const ENVIRONMENT = rawEnvironment as
  | 'development'
  | 'sales'
  | 'production'

export const COGNITO_USER_POOL_ID = process.env.COGNITO_USER_POOL_ID || ''
export const COGNITO_CLIENT_ID = process.env.COGNITO_CLIENT_ID || ''

export const API_BASE_URL = process.env.API_BASE_URL

export const GTAG_ID = process.env.GTAG_ID

export const SENTRY_DSN = process.env.SENTRY_DSN
export const SENTRY_RELEASE = process.env.SENTRY_RELEASE
export const SENTRY_TRACES_SAMPLE_RATE = parseFloat(
  process.env.SENTRY_TRACES_SAMPLE_RATE || '0'
)

const sentryTracePropagationTargets =
  process.env.SENTRY_TRACE_PROPAGATION_TARGETS?.split(',').map((target) => {
    return new RegExp(target.trim())
  })

export const SENTRY_TRACE_PROPAGATION_TARGETS = sentryTracePropagationTargets

export const SENTRY_REPLAY_SESSION_SAMPLE_RATE = parseFloat(
  process.env.SENTRY_REPLAY_SESSION_SAMPLE_RATE || '0'
)
export const SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE = parseFloat(
  process.env.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE || '0'
)
export const SENTRY_DEBUG = process.env.SENTRY_DEBUG === 'true'
