import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'

import { Formik } from 'formik'
import { MoovyForm } from '../../../../components'
import { OfferingNameIdDTO } from '../../../../apis/offering/dto'
import { isValidLicensePlate } from '../../../../utils/validator'

export interface DefaultRightHolderValues {
  licensePlate: string
  catalogueId: string
}

type SubmitFormCallback = (values: DefaultRightHolderValues) => void
export type FormIndividualRightHolderProps = {
  formReference: string
  catalogueId: string
  offering: OfferingNameIdDTO | undefined
  onSubmit: SubmitFormCallback
  defaultValues?: DefaultRightHolderValues
}

const FormIndividualRightHolder = (props: FormIndividualRightHolderProps) => {
  const intl = useIntl()

  const getFormDefaultValues = () => {
    return {
      licensePlate: props.defaultValues?.licensePlate || '',
      catalogueId: props.catalogueId || ''
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues, setDefaultValues] = useState<DefaultRightHolderValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [props.defaultValues?.licensePlate, props.offering?.id])

  const schemaValidator = () => {
    return yup.object().shape({
      licensePlate: yup
        .string()
        .trim()
        .test(
          'licensePlate',
          intl.formatMessage({
            id: 'common.form.errors.licensePlate'
          }),
          function (licensePlate?: string) {
            return isValidLicensePlate(licensePlate)
          }
        )
    })
  }

  const onSubmitFormValues = (values: DefaultRightHolderValues) => {
    const { licensePlate, catalogueId } = values
    props.onSubmit({
      licensePlate: licensePlate.trim().toLocaleUpperCase(),
      catalogueId
    })
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={schemaValidator()}
        enableReinitialize={true}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.catalogues.tabCatalogue.formVehicleRightHolder.label.vehicleDetails" />
                }
              />
              <MoovyForm.FormTextField
                fullWidth
                name="licensePlate"
                data-testid="licensePlate"
                value={values.licensePlate}
                label={intl.formatMessage({
                  id: 'common.text.licensePlate'
                })}
                placeholder={intl.formatMessage({
                  id: 'common.text.licensePlate'
                })}
                onChange={handleChange}
                autoFocus
                error={!!errors.licensePlate}
                errorText={errors.licensePlate}
              />
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormIndividualRightHolder
