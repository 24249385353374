import {
  Box,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import { ActiveRightSortType } from '../../apis/rights/dto'
import { CatalogueSortType } from '../../apis/catalogues/dto'
import { FC } from 'react'
import { InvitationSortType } from '../../apis/invitations/dto'
import { PlaceSortType } from '../../apis/places/dto'
import { isInteger } from 'formik'

export type SortDirection = 'ASC' | 'DESC'

export const getNewPageAfterItemDeletion = (
  pagination: MoovyPaginationProps | undefined
): number | undefined => {
  if (!pagination || !pagination.page || pagination.page <= 1) {
    return undefined
  }

  const totalNumberOfPages =
    pagination.limit > 0 ? Math.ceil(pagination.total / pagination.limit) : 0

  if (totalNumberOfPages <= 1) {
    return undefined
  }

  const lastItems = pagination.total % pagination.limit || pagination.limit
  if (lastItems !== 1) {
    return undefined
  }

  if (pagination.page === totalNumberOfPages) {
    return pagination.page - 1
  }

  return undefined
}

export type PagingAndSortingValues = {
  page: number
  limit: number
  sort:
    | PlaceSortType
    | InvitationSortType
    | CatalogueSortType
    | ActiveRightSortType
  direction: SortDirection
}

export type PagingAndSortingStringValues = {
  page: string
  limit: string
  sort:
    | PlaceSortType
    | InvitationSortType
    | CatalogueSortType
    | ActiveRightSortType
  direction: SortDirection
}

export const getPagingAndSortInitValues = <
  T extends
    | PlaceSortType
    | InvitationSortType
    | CatalogueSortType
    | ActiveRightSortType
>(
  sort: T,
  direction?: SortDirection
): PagingAndSortingStringValues => {
  return {
    page: '1',
    limit: '20',
    sort: sort,
    direction: direction ?? 'ASC'
  }
}

export const convertPaginationValues = (
  values: PagingAndSortingValues | PagingAndSortingStringValues
): PagingAndSortingValues | PagingAndSortingStringValues => {
  const { page, limit, sort, direction } = values
  return {
    page: isInteger(page) ? page.toString() : page.toString(),
    limit: isInteger(limit) ? limit.toString() : limit.toString(),
    sort,
    direction
  }
}

export type MoovyPaginationProps = {
  onNextPage: (page: number) => void
  page?: number
  total: number
  limit: number
}

const MoovyPagination: FC<MoovyPaginationProps> = ({
  page,
  total,
  limit,
  onNextPage
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const totalNumberOfPages =
    total > 0 && limit > 0 ? Math.ceil(total / limit) : 0

  const start = page ? page * limit - limit + 1 : 0
  const end = Math.min(start + limit - 1, total)

  return (
    <Grid container direction="row" paddingTop={2} alignItems="center">
      <Grid item pl={2} xs={12} md sx={{ pb: { xs: 2, md: 0 } }}>
        <Box
          sx={{ justifyContent: { xs: 'center', md: 'left' } }}
          display={'flex'}
          alignItems={'center'}
        >
          <Typography color="text.secondary">{`${start} - ${end} / ${total}`}</Typography>
        </Box>
      </Grid>
      <Grid item md sx={{ pb: { xs: 1, md: 0 } }} xs={12}>
        <Box
          sx={{ justifyContent: { xs: 'center', md: 'right' } }}
          display={'flex'}
          alignItems={'center'}
        >
          <Pagination
            page={page}
            siblingCount={1}
            boundaryCount={0}
            count={totalNumberOfPages}
            shape="rounded"
            showFirstButton={!isMobile}
            showLastButton={!isMobile}
            onChange={(event, page) => onNextPage(page)}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default MoovyPagination
