import * as yup from 'yup'

import { Box, MenuItem } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { CustomerResponseDTO } from '../../apis/customers/dto'
import { Formik } from 'formik'
import { MoovyForm } from '..'
import useCustomerData from '../../hooks/useCustomerData'

export interface DefaultSubPlaceValues {
  name: string
  streetAddress: string
  postalCode: string
  city: string
  capacity: string
  customerId: string
}
type SubmitFormCallback = (rightValues: DefaultSubPlaceValues) => void
export type FormSubPlaceProps = {
  formReference: string
  customers: CustomerResponseDTO[]
  onSubmit: SubmitFormCallback
  defaultValues?: DefaultSubPlaceValues
}

const FormSubPlace = (props: FormSubPlaceProps) => {
  const intl = useIntl()

  const { customer } = useCustomerData()
  const customerOptions = props.customers.map((item) => {
    return { key: item.id, value: item.name }
  })

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)

  const SubPlaceSchema = () => {
    return yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      capacity: yup
        .number()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        )
        .typeError(
          intl.formatMessage({
            id: 'common.form.errors.number'
          })
        ),
      streetAddress: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      postalCode: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      city: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' }))
    })
  }

  const getFormDefaultValues = () => {
    return {
      name: props.defaultValues?.name || '',
      capacity: props.defaultValues?.capacity || '',
      streetAddress: props.defaultValues?.streetAddress || '',
      postalCode: props.defaultValues?.postalCode || '',
      city: props.defaultValues?.city || '',
      customerId: props.defaultValues?.customerId || customer.id
    }
  }

  const [defaultValues, setDefaultValues] = useState<DefaultSubPlaceValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [props.defaultValues?.name, props.defaultValues?.capacity])

  const onSubmitFormValues = (values: DefaultSubPlaceValues) => {
    const { name, capacity, streetAddress, postalCode, city, customerId } =
      values
    props.onSubmit({
      name: name.trim(),
      capacity: capacity.trim(),
      streetAddress: streetAddress.trim(),
      postalCode: postalCode.trim(),
      city: city.trim(),
      customerId
    })
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        validate={() => setFormikValidateOnChange(true)}
        validateOnChange={formikValidateOnChange}
        initialValues={defaultValues}
        validationSchema={SubPlaceSchema()}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <h4 style={{ marginTop: '5px' }}>
              <FormattedMessage id="page.customer.formSubPlace.title.location" />
            </h4>

            <Box gap={2} display="flex" flexDirection={{ xs: 'column' }}>
              <MoovyForm.FormBoxRow>
                <MoovyForm.FormSelect
                  name="customerId"
                  label={intl.formatMessage({
                    id: 'page.places.formPlace.label.customer'
                  })}
                  value={values.customerId}
                  onChange={handleChange}
                  error={!!errors.customerId}
                >
                  {customerOptions.map((item) => {
                    return (
                      <MenuItem key={item.key} value={item.key}>
                        {item.value}
                      </MenuItem>
                    )
                  })}
                </MoovyForm.FormSelect>
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  fullWidth
                  name="name"
                  data-testid="name"
                  value={values.name}
                  label={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.name'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.name'
                  })}
                  onChange={handleChange}
                  error={!!errors.name}
                  errorText={errors.name}
                />
                <MoovyForm.FormTextField
                  name="streetAddress"
                  data-testid="streetAddress"
                  value={values.streetAddress}
                  label={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.streetAddress'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.streetAddress'
                  })}
                  onChange={handleChange}
                  error={!!errors.streetAddress}
                  errorText={errors.streetAddress}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="postalCode"
                  data-testid="postalCode"
                  value={values.postalCode}
                  label={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.postalCode'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.postalCode'
                  })}
                  onChange={handleChange}
                  error={!!errors.postalCode}
                  errorText={errors.postalCode}
                />
                <MoovyForm.FormTextField
                  name="city"
                  data-testid="city"
                  value={values.city}
                  label={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.city'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customer.formSubPlace.label.city'
                  })}
                  onChange={handleChange}
                  error={!!errors.city}
                  errorText={errors.city}
                />
              </MoovyForm.FormBoxRow>
            </Box>
            <h4>
              <FormattedMessage id="page.customer.formSubPlace.title.space" />
            </h4>
            <MoovyForm.FormBoxRow>
              <MoovyForm.FormTextField
                name="capacity"
                data-testid="capacity"
                value={values.capacity}
                label={intl.formatMessage({
                  id: 'page.customer.formSubPlace.label.spots'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.customer.formSubPlace.label.spots'
                })}
                onChange={handleChange}
                error={!!errors.capacity}
                errorText={errors.capacity}
              />
            </MoovyForm.FormBoxRow>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormSubPlace
