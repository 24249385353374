import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyAction, MoovyResource } from '../../app/permissions'
import {
  MoovyDateTime,
  MoovyDialogConfirmation,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyRestricted,
  MoovyTable
} from '..'
import React, { useState } from 'react'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { UseQueryResult, useMutation } from '@tanstack/react-query'

import { InvitationResponseDTO } from '../../apis/invitations/dto'
import InvitationsAPI from '../../apis/invitations/invitationsAPI'
import ModalAddInvitation from './modalAddInvitation'
import { MoovyPaginationProps } from '../moovyPagination'
import { MoovyToast } from '../../utils/moovyToast'
import useAuth from '../../hooks/useAuth'

type InvitationColumns =
  | 'NAME'
  | 'EMAIL'
  | 'INVITED'
  | 'REGISTERED_IN'
  | 'REMOVE'
type InvitationFrontendType = 'LANDLORD' | 'TENANT' | 'ADMIN'
export type AddingInvitationProps = {
  invitationType: InvitationFrontendType
  landlordId?: string
  tenantId?: string
  catalogueId?: string
}
type InvitationsProps = {
  queryInvitations: UseQueryResult<any, any>
  permissionAccess: { action: MoovyAction; resource: MoovyResource }
  showModalAdd: boolean
  addingInvitationParams: AddingInvitationProps
  setShowModalAdd: (show: boolean) => void
  pagination?: MoovyPaginationProps
  onInvitationAdded: () => void
  onInvitationRemoved: () => void
  visibleColumns?: InvitationColumns[]
}

const Invitations = ({
  queryInvitations,
  permissionAccess,
  showModalAdd,
  addingInvitationParams,
  setShowModalAdd,
  pagination,
  onInvitationAdded,
  onInvitationRemoved,
  visibleColumns = ['NAME', 'EMAIL', 'INVITED', 'REGISTERED_IN', 'REMOVE']
}: InvitationsProps) => {
  interface ParamsShowModalRemove {
    name: string
    id: string
  }

  const intl = useIntl()
  const { isPermission } = useAuth()
  const [showModalRemove, setShowModalRemove] =
    useState<ParamsShowModalRemove | null>(null)

  const invitations: InvitationResponseDTO[] =
    addingInvitationParams.invitationType === 'LANDLORD'
      ? queryInvitations?.data?.data || []
      : queryInvitations?.data || []

  const removeInvitationMutation = useMutation({
    mutationFn: (invitationId: string) =>
      InvitationsAPI.removeInvitation(invitationId),
    onSuccess: (response) => {
      MoovyToast.showToast(
        <FormattedMessage
          id="page.invitations.list.actions.removed"
          values={{ name: showModalRemove?.name }}
        />
      )
      setShowModalRemove(null)
      onInvitationRemoved()
    }
  })

  return (
    <>
      <MoovyGrid
        emptyTextId="page.invitations.list.table.action.empty"
        errorTextId="page.invitations.list.table.action.failed"
        query={queryInvitations}
        pagination={pagination}
      >
        {invitations.map((row) => {
          let cardContentList = []
          if (visibleColumns.includes('EMAIL')) {
            cardContentList.push(<FormattedMessage id="common.text.email" />)
            cardContentList.push(<b>{row.email}</b>)
          }
          if (visibleColumns.includes('REGISTERED_IN')) {
            cardContentList.push(
              <FormattedMessage id="page.invitations.list.card.header.registeredIn" />
            )
            cardContentList.push(
              <b>
                <MoovyDateTime utcDateTime={row.used} emptyText="-" />
              </b>
            )
          }
          return (
            <MoovyGridCard
              key={row.id}
              cardTitle={row.name}
              cardSecondaryTitle={<MoovyDateTime utcDateTime={row.createdAt} />}
              cardContentList={cardContentList}
              cardMenuActions={
                isPermission(permissionAccess.action, permissionAccess.resource)
                  ? [
                      {
                        itemText: intl.formatMessage({
                          id: 'page.invitations.list.button.remove'
                        }),
                        onClick: () =>
                          setShowModalRemove({
                            name: row.name,
                            id: row.id
                          })
                      }
                    ]
                  : undefined
              }
            />
          )
        })}
      </MoovyGrid>
      <MoovyTable
        items={invitations}
        emptyTextId="page.invitations.list.table.action.empty"
        errorTextId="page.invitations.list.table.action.failed"
        query={queryInvitations}
        pagination={pagination}
      >
        <TableHead>
          <TableRow>
            {visibleColumns.includes('NAME') && (
              <TableCell>
                <FormattedMessage id="page.invitations.list.table.header.name" />
              </TableCell>
            )}
            {visibleColumns.includes('EMAIL') && (
              <TableCell>
                <FormattedMessage id="common.text.email" />
              </TableCell>
            )}
            {visibleColumns.includes('INVITED') && (
              <TableCell>
                <FormattedMessage id="page.invitations.list.table.header.invited" />
              </TableCell>
            )}
            {visibleColumns.includes('REGISTERED_IN') && (
              <TableCell>
                <FormattedMessage id="page.invitations.list.table.header.registeredIn" />
              </TableCell>
            )}
            {visibleColumns.includes('REMOVE') && (
              <MoovyRestricted
                action={permissionAccess.action}
                resource={permissionAccess.resource}
              >
                <TableCell />
              </MoovyRestricted>
            )}
          </TableRow>
        </TableHead>
        <TableBody data-testid="invitations-table-body">
          {invitations.map((row) => (
            <TableRow key={row.id}>
              {visibleColumns.includes('NAME') && (
                <TableCell>{row.name}</TableCell>
              )}
              {visibleColumns.includes('EMAIL') && (
                <TableCell>{row.email}</TableCell>
              )}
              {visibleColumns.includes('INVITED') && (
                <TableCell>
                  <MoovyDateTime utcDateTime={row.createdAt} />
                </TableCell>
              )}
              {visibleColumns.includes('REGISTERED_IN') && (
                <TableCell>
                  <MoovyDateTime utcDateTime={row.used} />
                </TableCell>
              )}
              {visibleColumns.includes('REMOVE') && (
                <TableCell>
                  <MoovyRestricted
                    action={permissionAccess.action}
                    resource={permissionAccess.resource}
                    visibility={!row.used}
                  >
                    <MoovyLink
                      red
                      bold
                      onClick={() =>
                        setShowModalRemove({ name: row.name, id: row.id })
                      }
                    >
                      <FormattedMessage id="page.invitations.list.button.remove" />
                    </MoovyLink>
                  </MoovyRestricted>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </MoovyTable>
      <ModalAddInvitation
        open={showModalAdd}
        addingInvitationParams={addingInvitationParams}
        onSubmit={(response) => {
          setShowModalAdd(false)
          MoovyToast.showToast(
            <FormattedMessage
              id="page.invitations.list.actions.added"
              values={{ name: response.name }}
            />
          )
          onInvitationAdded()
        }}
        onCancel={() => setShowModalAdd(false)}
      />
      <MoovyDialogConfirmation
        open={!!showModalRemove}
        lang={{
          confirmButton: 'common.buttons.yes',
          error: 'common.actions.remove.error'
        }}
        header={intl.formatMessage({
          id: 'page.invitations.modalRemove.header'
        })}
        content={intl.formatMessage(
          { id: 'page.invitations.modalRemove.content' },
          { name: showModalRemove?.name }
        )}
        onCancel={() => setShowModalRemove(null)}
        onConfirm={() =>
          showModalRemove?.id &&
          removeInvitationMutation.mutate(showModalRemove.id)
        }
        mutation={removeInvitationMutation}
      />
    </>
  )
}

export default Invitations
