import 'react-toastify/dist/ReactToastify.css'
import '../styles/reactToastify.scss'

import { MoovyPalette, MoovyStyle } from '../styles/theme'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { loginResources, resources } from '../i18nResources'

import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon'
import AppRoutes from './appRoutes'
import { Authenticator } from '@aws-amplify/ui-react'
import CssBaseline from '@mui/material/CssBaseline'
import { I18n } from 'aws-amplify/utils'
import { IntlProvider } from 'react-intl'
import { LicenseInfo } from '@mui/x-license'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { ToastContainer } from 'react-toastify'
import i18n from '../i18n'
import useAuth from '../hooks/useAuth'
import useLocale from '../hooks/useLocale'

const App = () => {
  const queryClient = new QueryClient()
  const { locale } = useLocale()
  const { isAuthenticated } = useAuth()

  LicenseInfo.setLicenseKey(process.env.MUI_X_LICENSE_KEY || '')

  // TODO Antti:
  // Missing theme fixes:
  // * Autofill input color of login must be fixed

  const theme = createTheme({
    palette: {
      primary: {
        main: MoovyPalette.primaryMain
      },
      secondary: {
        main: MoovyPalette.secondaryMain
      },
      error: {
        main: MoovyPalette.errorMain
      },
      warning: {
        main: MoovyPalette.warningMain
      },
      info: {
        main: MoovyPalette.infoMain
      },
      success: {
        main: MoovyPalette.successMain
      },
      text: {
        primary: MoovyPalette.commonBlack
      },
      common: {
        black: MoovyPalette.commonBlack,
        white: MoovyPalette.commonWhite
      }
    },
    shape: {
      borderRadius: 10
    },
    typography: {
      fontFamily: MoovyStyle.fontFamily
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: MoovyPalette.commonBlue
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: MoovyStyle.buttonFontWeight
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: MoovyPalette.colorTableHeader,
            '& .MuiTableCell-root': {
              fontWeight: MoovyStyle.tableHeadFontWeight
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input:-webkit-autofill': {
              '-webkit-text-fill-color': MoovyPalette.commonBlack
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            cursor: 'pointer'
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '0',
            paddingTop: '2px',
            paddingLeft: '2px',
            paddingRight: '0',
            margin: '0'
          }
        }
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            paddingLeft: '10px'
          }
        }
      }
    }
  })

  useEffect(() => {
    const reloadPage = i18n.language != locale
    I18n.putVocabulariesForLanguage(
      locale,
      loginResources[locale as keyof typeof resources].translation
    )
    I18n.setLanguage(locale)
    i18n.changeLanguage(locale)

    if (!isAuthenticated && reloadPage) {
      // Cognito login page needs this
      window.location.reload()
    }
  }, [locale])

  return (
    <IntlProvider
      locale={locale}
      messages={resources[locale as keyof typeof resources].translation}
    >
      <QueryClientProvider client={queryClient}>
        <ToastContainer position="top-right" />
        <Authenticator.Provider>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              dateAdapter={AdapterLuxon}
              adapterLocale={locale || 'fi'}
            >
              <CssBaseline />
              <AppRoutes />
            </LocalizationProvider>
          </ThemeProvider>
        </Authenticator.Provider>
      </QueryClientProvider>
    </IntlProvider>
  )
}

export default App
