import { components } from '../openapi'

export type RightHolderCreateRightHolderDTO =
  components['schemas']['CreateRightHolder']
export type RightHolderCreateIndividualDTO =
  components['schemas']['CreateIndividualRightHolder']
export type RightHolderCreateVehicleDTO =
  components['schemas']['CreateVehicleRightHolder']
export type RightHolderUpdateDTO = components['schemas']['UpdateRightHolder']
export type RightHolderUpdateIndividualDTO =
  components['schemas']['UpdateIndividualRightHolder']
export type RightHolderUpdateVehicleDTO =
  components['schemas']['UpdateVehicleRightHolder']

export type RightHolderResponseDTO = components['schemas']['RightHolder']

export type RightHolderIndividualDTO = components['schemas']['Individual']
export type RightHolderVehicleDTO = components['schemas']['Vehicle']
export type RightDTO = components['schemas']['Right']

export type RightHolderType = components['schemas']['RightHolderType']

export const getRightHolderName = (
  rightHolder: RightHolderIndividualDTO | RightHolderVehicleDTO
): string => {
  if (rightHolder.type === 'individual') {
    const dataIndividual = rightHolder as RightHolderIndividualDTO
    return `${dataIndividual.firstName}${dataIndividual.firstName ? ' ' : ''}${
      dataIndividual.lastName
    }`
  } else if (rightHolder.type === 'vehicle') {
    const dataVehicle = rightHolder as RightHolderVehicleDTO
    return dataVehicle.licensePlate
  }
  return ''
}
