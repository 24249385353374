import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'

import React from 'react'

export type BreadBrumbsRoute = { route: string; name: string }
type BreadcrumbsProps = React.ComponentProps<typeof Breadcrumbs>
interface MoovyBreadcrumbsProps extends BreadcrumbsProps {
  lastBreadcrumb: string
  customBreadcrumbs?: BreadBrumbsRoute[]
}

const MoovyBreadcrumbs = (props: MoovyBreadcrumbsProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const routes = props.customBreadcrumbs || []

  return (
    <Breadcrumbs
      maxItems={isMobile ? 2 : undefined}
      aria-label="breadcrumb"
      style={{ marginTop: '10px' }}
    >
      {routes.map((item, index) => (
        <Link key={index} underline="hover" color="inherit" href={item?.route}>
          <>{item?.name}</>
        </Link>
      ))}
      <Typography color="text.primary">{props.lastBreadcrumb}</Typography>
    </Breadcrumbs>
  )
}

export default MoovyBreadcrumbs
