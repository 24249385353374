import { Navigate, Outlet } from 'react-router-dom'
import React, { ReactNode } from 'react'

import { MoovyProgress } from '../components'
import { appRoutes } from './permissions'
import useAuth from '../hooks/useAuth'

type MoovyRouteProps = {
  isProgress?: boolean
  isAllowed?: boolean
  path?: string
  redirectPath?: string
  loginPath?: string
  children?: ReactNode
}

const MoovyRoute = ({
  isProgress = false,
  isAllowed = true,
  redirectPath = appRoutes.ROOT,
  children
}: MoovyRouteProps) => {
  const { isAuthenticated } = useAuth()

  if (isProgress) {
    return <MoovyProgress />
  }

  if (!isAuthenticated || !isAllowed) {
    return <Navigate to={redirectPath} replace />
  }

  return children ? children : <Outlet />
}

export default MoovyRoute
