import { toast } from 'react-toastify'

export namespace MoovyToast {
  export const showToast = (
    message: string | React.ReactNode,
    success: boolean = true
  ): void => {
    success ? toast.success(message) : toast.error(message)
  }
}
