import {
  LS_LOCALE,
  SS_CATALOGUE_ID,
  SS_CUSTOMER_ID,
  SS_CUSTOMER_INIT,
  SS_CUSTOMER_NAME,
  SS_PLACE_ID,
  SS_PLACE_NAME
} from './keysStorage'

import { createSlice } from '@reduxjs/toolkit'

const stateKey = 'moovyUi'

const initialState = {
  locale: localStorage.getItem(LS_LOCALE) || 'en',
  initialized: sessionStorage.getItem(SS_CUSTOMER_INIT) || '',
  landlord: {
    id: sessionStorage.getItem(SS_CUSTOMER_ID) || '',
    name: sessionStorage.getItem(SS_CUSTOMER_NAME) || ''
  },
  place: {
    id: sessionStorage.getItem(SS_PLACE_ID) || '',
    name: sessionStorage.getItem(SS_PLACE_NAME) || ''
  },
  catalogueId: sessionStorage.getItem(SS_CATALOGUE_ID) || ''
}

const slice = createSlice({
  name: stateKey,
  initialState: initialState,
  reducers: {
    setUI(state, action) {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value
      }
      return newState
    },
    setLocale(state, action) {
      localStorage.setItem(LS_LOCALE, action.payload)
      state.locale = action.payload
    },
    setInitialized(state, action) {
      sessionStorage.setItem(SS_CUSTOMER_INIT, action.payload)
      state.initialized = action.payload
    },
    setCustomer(state, action) {
      sessionStorage.setItem(SS_CUSTOMER_ID, action.payload.id)
      sessionStorage.setItem(SS_CUSTOMER_NAME, action.payload.name)
      state.landlord = { ...action.payload }
    },
    setPlace(state, action) {
      sessionStorage.setItem(SS_PLACE_ID, action.payload.id)
      sessionStorage.setItem(SS_PLACE_NAME, action.payload.name)
      state.place = { ...action.payload }
    },
    setCatalogueId(state, action) {
      sessionStorage.setItem(SS_CATALOGUE_ID, action.payload)
      state.catalogueId = action.payload
    }
  }
})

export default slice.reducer

export const {
  setUI,
  setLocale,
  setCustomer,
  setPlace,
  setInitialized,
  setCatalogueId
} = slice.actions

export const setUiState = (key: any, payload: any) => {
  return setUI({ key, value: payload })
}

export const setLocaleState = (payload: any) => {
  return setLocale(payload)
}

export const setCustomerInitState = (payload: any) => {
  return setInitialized(payload)
}

export const setCustomerState = (payload: any) => {
  return setCustomer(payload)
}

export const setPlaceState = (payload: any) => {
  return setPlace(payload)
}

export const setCatalogueIdState = (payload: any) => {
  return setCatalogueId(payload)
}

export const uiSelectors = {
  keyExists(state: any, uiKey: any) {
    return state[stateKey]?.[uiKey] !== undefined
  },
  getUiState(state: any, uiKey: any) {
    return state[stateKey]?.[uiKey]
  },
  getLocale(state: any) {
    return state[stateKey]?.locale
  },
  getInitialized(state: any) {
    return state[stateKey]?.initialized
  },
  getCustomer(state: any): { id: string; name: string } {
    return state[stateKey]?.landlord
  },
  getPlace(state: any): { id: string; name: string } {
    return state[stateKey]?.place
  },
  getCatalogueId(state: any): { id: string; name: string } {
    return state[stateKey]?.catalogueId
  }
}
