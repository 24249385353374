import {
  ActiveRightSortQueryParams,
  ActiveRightsPagingDTO
} from '../rights/dto'
import {
  CatalogueCreateDTO,
  CatalogueResponseDTO,
  CatalogueSortQueryParams,
  CatalogueUpdateDTO,
  CataloguesPagingDTO
} from './dto'

import { client } from '../moovyAxiosClient'
import { getBaseUrl } from '..'

const BASE_URL = 'catalogues'

const getCataloguesByRef = (ref: string) => {
  return client
    .get<CatalogueResponseDTO>(`${getBaseUrl(false)}/${BASE_URL}/${ref}`)
    .then((response) => response.data)
}

const getCataloguesByCustomer = (ref: string) => {
  return client
    .get<CatalogueResponseDTO[]>(
      `${getBaseUrl(false)}/${BASE_URL}/by-customer-id/${ref}`
    )
    .then((response) => response.data)
}

const getActiveRightsById = (
  catalogueId: string,
  queryParams: ActiveRightSortQueryParams | undefined
) => {
  return client
    .get<ActiveRightsPagingDTO>(
      `${getBaseUrl(false)}/${BASE_URL}/${catalogueId}/active-rights`,
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

const createCatalogue = (catalogue: CatalogueCreateDTO) => {
  return client.post<CatalogueResponseDTO>(
    `${getBaseUrl(false)}/${BASE_URL}`,
    catalogue
  )
}

const updateCatalogue = (
  catalogueRef: string,
  catalogue: CatalogueUpdateDTO
) => {
  return client.put<CatalogueResponseDTO>(
    `${getBaseUrl(false)}/${BASE_URL}/${catalogueRef}`,
    catalogue
  )
}

const getByOffering = (
  offeringRef: string,
  queryParams: CatalogueSortQueryParams | undefined
) => {
  return client
    .get<CataloguesPagingDTO>(
      `${getBaseUrl(false)}/offerings/${offeringRef}/${BASE_URL}`,
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

export const CataloguesAPI = {
  getCataloguesByRef,
  getCataloguesByCustomer,
  getActiveRightsById,
  createCatalogue,
  getByOffering,
  updateCatalogue
}
