import { FormattedMessage, useIntl } from 'react-intl'

import { Divider } from '@mui/material'
import { MoovyListLabelValue } from '../../../components'
import React from 'react'
import { getJoinedValue } from '../../../utils/utils'

export type CataloguePlaceInfoProps = {
  place?: any
}

const CataloguePlaceInfo = ({ place }: CataloguePlaceInfoProps) => {
  const intl = useIntl()

  const data = [
    {
      label: intl.formatMessage({
        id: 'page.catalogues.tabCatalogue.cataloguePlaceInfo.name'
      }),
      value: place?.name
    },
    {
      label: intl.formatMessage({
        id: 'page.catalogues.tabCatalogue.cataloguePlaceInfo.street'
      }),
      value: `${getJoinedValue(
        place?.streetAddress,
        place?.postalCode,
        ', '
      )} ${place?.city}`
    },
    {
      label: intl.formatMessage({
        id: 'page.catalogues.tabCatalogue.cataloguePlaceInfo.capacity'
      }),
      value: place?.capacity
    }
  ]

  return (
    <>
      <h3>
        <FormattedMessage id="page.catalogues.tabCatalogue.cataloguePlaceInfo.title" />
      </h3>
      <Divider style={{ marginBottom: '20px' }} />
      <MoovyListLabelValue data={data} splitByMiddle />
    </>
  )
}

export default CataloguePlaceInfo
