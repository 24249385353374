export const SS_APP_BACKDROP_ANIMATION = 'moovyBackdropAnimation'
export const SS_CATALOGUE_RIGHTS_PAGING = 'moovyCatalogueRightsPaging'
export const SS_CATALOGUE_RIGHTS_SORTING = 'moovyCatalogueRightsSorting'
export const SS_CATALOGUES_PAGING = 'moovyCataloguesPaging'
export const SS_CATALOGUES_SORTING = 'moovyCataloguesSorting'
export const SS_INVITATIONS_ADMIN_PAGING = 'moovyInvitationAdmingPaging'
export const SS_INVITATIONS_LANDLORD_PAGING = 'moovyInvitationLandlordPaging'
export const SS_INVITATIONS_LANDLORD_SORTING = 'moovyInvitationLandlordSorting'
export const SS_OFFERINGS_TAB_CATALOGUES = 'moovyOfferingsTabCatalogues'
export const SS_PLACES_PAGING = 'moovyPlacesPaging'
export const SS_PLACES_SORTING = 'moovyPlacesSort'
export const SS_PLACES_TAB_OFFERINGS = 'moovyPlacesTabOfferings'
export const SS_RIGHT_HOLDERS_TAB_VEHICLES = 'moovyRightHoldersTabVehicles'
