import {
  Avatar,
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import CarLogo from '../resources/carLogo.svg'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import MenuIcon from '@mui/icons-material/Menu'
import { MoovyButton } from '../components'
import { MoovyConstantNames } from '../utils/globalNames'
import React from 'react'
import { appRoutes } from './permissions'
import { resources } from '../i18nResources'
import useAuth from '../hooks/useAuth'
import useCustomerData from '../hooks/useCustomerData'
import useLocale from '../hooks/useLocale'
import { useNavigate } from 'react-router-dom'

interface UserItemProps {
  anchorElement: HTMLElement | null
  onOpenUserMenu: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCloseMenu: () => void
  onLogout: () => void
}
const UserItem: React.FC<UserItemProps> = ({
  anchorElement,
  onOpenUserMenu,
  onCloseMenu,
  onLogout
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { locale, setLocale } = useLocale()
  const { isAuthenticated, usernameShort } = useAuth()

  const locKeys = Object.getOwnPropertyNames(resources)
  const locOptions = locKeys.map((key) => {
    return {
      key: key,
      text: intl.formatMessage({ id: `menu.user.${key}` })
    }
  })
  return (
    <React.Fragment>
      <Avatar
        onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) =>
          onOpenUserMenu(event)
        }
      >
        {usernameShort}
      </Avatar>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {isAuthenticated && (
          <MenuItem
            onClick={() => {
              onCloseMenu()
              navigate(`${appRoutes.USERS}`)
            }}
          >
            <FormattedMessage id="menu.user.detail" />
          </MenuItem>
        )}
        {isAuthenticated && <Divider />}
        {locOptions.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => {
              setLocale(item.key)
              onCloseMenu()
            }}
            disabled={item.key === locale}
          >
            {item.text}
          </MenuItem>
        ))}
        {isAuthenticated && <Divider />}
        {isAuthenticated && (
          <MenuItem
            onClick={() => {
              onLogout()
              onCloseMenu()
            }}
          >
            <Box display="flex" gap="5px">
              <LogoutRoundedIcon />
              <FormattedMessage id="menu.user.logout" />
            </Box>
          </MenuItem>
        )}
      </Menu>
    </React.Fragment>
  )
}

interface AppMenuTopProps {
  anchorElUser: HTMLElement | null
  appMenuOpen: boolean
  onOpenUserMenu: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onCloseMenu: () => void
  onLogout: () => void
  onClickHamburgerMenu: () => void
  onClickLogo: () => void
}
const AppMenuTop: React.FC<AppMenuTopProps> = ({
  anchorElUser,
  appMenuOpen,
  onOpenUserMenu,
  onCloseMenu,
  onLogout,
  onClickHamburgerMenu,
  onClickLogo
}) => {
  const { isAuthenticated } = useAuth()
  const { customerConfigured } = useCustomerData()
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid
          item
          xs={6}
          display="flex"
          justifyContent="flex-start"
          height={30}
          gap={2}
        >
          <div
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={onClickLogo}
          >
            <img src={CarLogo} />
          </div>
          {!isMobile && (
            <div
              style={{
                display: 'flex',
                height: '100%',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={onClickLogo}
            >
              <h2>{MoovyConstantNames.APP_FULLNAME}</h2>
            </div>
          )}
          {isAuthenticated && customerConfigured && (
            <>
              {appMenuOpen && isMobile ? (
                <MoovyButton
                  startIcon={<CloseRoundedIcon style={{ color: 'white' }} />}
                  onClick={onClickHamburgerMenu}
                  showAsIconButton
                  aria-label={intl.formatMessage({
                    id: 'appMenuTop.button.closeMenu'
                  })}
                />
              ) : (
                <MoovyButton
                  startIcon={<MenuIcon style={{ color: 'white' }} />}
                  onClick={onClickHamburgerMenu}
                  showAsIconButton
                  aria-label={intl.formatMessage({
                    id: 'appMenuTop.button.menu'
                  })}
                />
              )}
            </>
          )}
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="right">
          <UserItem
            anchorElement={anchorElUser}
            onOpenUserMenu={onOpenUserMenu}
            onCloseMenu={onCloseMenu}
            onLogout={onLogout}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default AppMenuTop
