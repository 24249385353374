import {
  CustomerCreateDTO,
  CustomerPagingDTO,
  CustomerResponseDTO,
  CustomerSortQueryParams,
  CustomerUpdateDTO
} from './dto'

import { PlaceResponseDTO } from '../places/dto'
import { RightHolderIndividualDTO } from '../rightHolders/dto'
import { client } from '../moovyAxiosClient'
import { getBaseUrl } from '..'

const BASE_URL = `customers`
const getUrl = (admin: boolean) => `${getBaseUrl(admin)}/${BASE_URL}`

const createCustomer = (customer: CustomerCreateDTO) => {
  return client.post<CustomerResponseDTO>(getUrl(false), customer)
}

const updateCustomer = (ref: string, customer: CustomerUpdateDTO) => {
  return client.put<CustomerResponseDTO>(`${getUrl(false)}/${ref}`, customer)
}

const getCustomerByRef = (ref: string) => {
  return client
    .get<CustomerResponseDTO>(`${getUrl(false)}/${ref}`)
    .then((response) => response.data)
}

const getCustomers = (
  admin: boolean,
  queryParams: CustomerSortQueryParams | undefined
) => {
  return client
    .get<CustomerPagingDTO>(
      getUrl(admin),
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

const getLandlords = (
  admin: boolean,
  queryParams: CustomerSortQueryParams | undefined
) => {
  return client
    .get<CustomerPagingDTO>(
      `${getUrl(admin)}/landlords`,
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

const getIndividualsByCustomer = (admin: boolean, ref: string) => {
  return client
    .get<RightHolderIndividualDTO[]>(
      `${getUrl(false)}/${ref}/right-holders/individuals`
    )
    .then((response) => response.data)
}

const getPlacesByCustomer = (admin: boolean, ref: string) => {
  return client
    .get<PlaceResponseDTO[]>(`${getUrl(admin)}/${ref}/places`)
    .then((response) => response.data)
}

const CustomersAPI = {
  createCustomer,
  updateCustomer,
  getCustomerByRef,
  getCustomers,
  getLandlords,
  getIndividualsByCustomer,
  getPlacesByCustomer
}

export default CustomersAPI
