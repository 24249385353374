import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_OFFERINGS_GET_BY_ID
} from '../../apis/moovyQueryKeys'
import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyQueryHandler,
  MoovyScrollToTop,
  MoovyTab
} from '../../components'

import CataloguesTab from './cataloguesTab'
import { Grid } from '@mui/material'
import OfferingInfo from './offeringInfo/offeringInfo'
import { OfferingsAPI } from '../../apis/offering/offeringsAPI'
import React from 'react'
import { SS_OFFERINGS_TAB_CATALOGUES } from '../../redux/globalKeys'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const Offering = () => {
  const intl = useIntl()
  const { offeringId } = useParams()

  const queryOffering = useQuery({
    queryKey: [QUERY_OFFERINGS_GET_BY_ID, offeringId],
    queryFn: () => OfferingsAPI.getOfferingById(offeringId || ''),
    enabled: !!offeringId,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  return (
    <MoovyQueryHandler query={queryOffering} rootPageIfNotFoundError>
      <Grid container spacing={2}>
        <MoovyScrollToTop />
        <Grid item xs={12}>
          <OfferingInfo queryOffering={queryOffering} />
        </Grid>
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerContent>
              <MoovyTab
                tabKey={SS_OFFERINGS_TAB_CATALOGUES}
                tabHeaders={[
                  intl.formatMessage({
                    id: 'page.offering.cataloguesTab.header'
                  })
                ]}
                tabPanels={[<CataloguesTab offering={queryOffering.data} />]}
              />
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
      </Grid>
    </MoovyQueryHandler>
  )
}

export default Offering
