export namespace MoovyPalette {
  export const primaryMain = '#0098d8'
  export const secondaryMain = '#085171'
  export const errorMain = '#ff4848'
  export const warningMain = '#E0732C'
  export const infoMain = primaryMain
  export const successMain = '#0BCD61'
  export const commonBlue = '#555'
  export const commonBlack = '#555'
  export const commonWhite = 'white'
  export const commonRed = '#ff4848'
  // TODO Antti: Should be change to be used via theme instead?
  export const colorMainBackground = '#f9fafb'
  export const colorTableHeader = '#efefef'
  // TODO Antti: Should be change to be used via theme instead?
  export const colorMenuUnderline = commonWhite
  // TODO Antti: Should be change to be used via theme instead?
  export const progressBlockContentColor = 'white'
  export const colorMenuBackgroundGradient =
    'linear-gradient(135deg, #08cbef 0%, #0098d8 99.43%);'
}
export namespace MoovyStyle {
  export const fontFamily = 'Fira Sans, sans-serif'
  export const menuFontSize = '17px'
  export const menuFontWeight = 500
  export const buttonFontWeight = 700
  export const tableHeadFontWeight = 700
}
