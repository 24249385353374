import axios, { AxiosError } from 'axios'

import { NavigateFunction } from 'react-router-dom'
import { appRoutes } from '../app/permissions'

export const navigateToRootIfNotFoundError = (
  navigate: NavigateFunction,
  error: any
) => {
  if (error && axios.isAxiosError(error)) {
    const axiosError: AxiosError = error
    if (axiosError?.response?.status === 404) {
      navigate(appRoutes.ROOT)
    }
  }
}
