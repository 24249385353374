import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'
import React, { useEffect } from 'react'

export interface MoovyCardMenuItem {
  itemText: string
  onClick: () => void
}

export type MoovyCardMenuProps = {
  open: boolean
  setOpen: (open: boolean) => void
  anchorRef: React.RefObject<HTMLButtonElement>
  menuItems?: MoovyCardMenuItem[]
}

const MoovyCardMenu = ({
  open,
  setOpen,
  anchorRef,
  menuItems
}: MoovyCardMenuProps) => {
  const handleClose = (
    event: Event | React.SyntheticEvent,
    onMenuItemClick?: () => void
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
    if (onMenuItemClick) {
      event.preventDefault()
      event.stopPropagation()
      onMenuItemClick()
    }
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    } else if (event.key === 'Escape') {
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const isMenu = menuItems && menuItems.length > 0

  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      style={{ zIndex: 1 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom'
          }}
        >
          <Paper>
            {isMenu && (
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => handleClose(event, item.onClick)}
                    >
                      {item.itemText}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            )}
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default MoovyCardMenu
