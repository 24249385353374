import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_INVITATION_GET_ADMINS,
  QUERY_USERS_ADMIN_GET_BY_REF
} from '../../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyDialogConfirmation,
  MoovyRestricted,
  MoovyScrollToTop
} from '../../../../components'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import InvitationsAPI from '../../../../apis/invitations/invitationsAPI'
import InvitationsOngoing from './invitationsOngoing'
import ModalAddInvitation from '../../../../components/invitations/modalAddInvitation'
import ModalAdminInvitations from './modalAdminInvitations'
import { MoovyToast } from '../../../../utils/moovyToast'
import TableAdministrators from './tableAdministrators'
import UsersAPI from '../../../../apis/user/usersAPI'
import { useState } from 'react'

const TabAdministrators = ({ customerId }: { customerId: string }) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const [modalAdminInvitations, setModalAdminInvitations] = useState(false)

  const [showModalAdd, setShowModalAdd] = useState(false)
  // TODO Antti: Deletion of the admin user has not been implemented yet. See the ticket https://finnpark.atlassian.net/browse/MV2-394
  const [showModalRemove, setShowModalRemove] = useState(false)

  const queryAdmins = useQuery({
    queryKey: [QUERY_USERS_ADMIN_GET_BY_REF, customerId],
    queryFn: () => UsersAPI.getUsers({ customerId }),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const queryAdminInvitations = useQuery({
    queryKey: [QUERY_INVITATION_GET_ADMINS, customerId],
    queryFn: () => InvitationsAPI.getInvitationsByCustomerId(false, customerId),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  // TODO Antti: Deletion of the admin user has not been implemented yet. See the ticket https://finnpark.atlassian.net/browse/MV2-394
  const removeAdminUserMutation = useMutation({
    mutationFn: (invitationId: string) => UsersAPI.removeUser(invitationId),
    onSuccess: (response) => {
      MoovyToast.showToast(
        <FormattedMessage
          id="page.customers.tabAdministrators.modalRemove.actions.removed"
          values={{ name: 'TODO Antti' /*showModalRemove?.name*/ }}
        />
      )
      setShowModalRemove(false)
    }
  })

  const onRefreshView = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_INVITATION_GET_ADMINS, customerId]
    })
  }

  return (
    <>
      <MoovyScrollToTop />
      <MoovyContainer>
        <MoovyContainerSubHeader
          description={
            <>
              <FormattedMessage id="page.customers.tabAdministrators.infoText" />
              <InvitationsOngoing
                customerId={customerId}
                onLinkClicked={() => setModalAdminInvitations(true)}
              />
            </>
          }
          button={
            <MoovyRestricted action="CREATE" resource="ADMIN_INVITATIONS">
              <MoovyButton onClick={() => setShowModalAdd(true)}>
                <FormattedMessage id="page.customers.tabAdministrators.button.newAdminInvitation" />
              </MoovyButton>
            </MoovyRestricted>
          }
        />
        <MoovyContainerContent>
          <TableAdministrators
            queryAdministrators={queryAdmins}
            setShowModalRemove={() => setShowModalRemove(true)}
          />
        </MoovyContainerContent>
      </MoovyContainer>
      <ModalAddInvitation
        addingInvitationParams={{
          invitationType: 'ADMIN',
          landlordId: customerId
        }}
        open={showModalAdd}
        onSubmit={(response) => {
          setShowModalAdd(false)
          MoovyToast.showToast(
            <FormattedMessage
              id="page.customers.tabAdministrators.actions.added"
              values={{ name: response.name }}
            />
          )
          onRefreshView()
        }}
        onCancel={() => setShowModalAdd(false)}
      />
      <ModalAdminInvitations
        open={modalAdminInvitations}
        landlordId={customerId}
        queryAdminInvitations={queryAdminInvitations}
        onClose={() => setModalAdminInvitations(false)}
      />
      <MoovyDialogConfirmation
        open={!!showModalRemove}
        lang={{
          confirmButton: 'common.buttons.yes',
          error: 'common.actions.remove.error'
        }}
        header={intl.formatMessage({
          id: 'page.customers.tabAdministrators.modalRemove.header'
        })}
        content={intl.formatMessage(
          { id: 'page.customers.tabAdministrators.modalRemove.content' },
          { name: 'TODO Antti' }
        )}
        onCancel={() => setShowModalRemove(false)}
        onConfirm={() =>
          // TODO Antti: Deletion of the admin user has not been implemented yet. See the ticket https://finnpark.atlassian.net/browse/MV2-394
          setShowModalRemove(false)
        }
        mutation={removeAdminUserMutation}
      />
    </>
  )
}

export default TabAdministrators
