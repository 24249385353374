import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyListLabelValue,
  MoovyParkingEnforcementStatus
} from '../../../components'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { getAmountAndPercentage } from '../../../utils/utils'

interface SituationOverviewProps {
  parkingEnforcement: boolean
  isLoading: boolean
  capacity?: number
  activeRightsTotal: number
  cataloguesTotal: number
}

const SituationOverview: React.FC<SituationOverviewProps> = ({
  parkingEnforcement,
  isLoading,
  activeRightsTotal,
  cataloguesTotal,
  capacity
}) => {
  const intl = useIntl()

  const data = [
    {
      label: intl.formatMessage({
        id: 'page.placeRights.situationOverview.totalAmount'
      }),
      value:
        capacity &&
        cataloguesTotal &&
        getAmountAndPercentage(capacity, cataloguesTotal)
    },
    {
      label: intl.formatMessage({
        id: 'page.placeRights.situationOverview.activeRights'
      }),
      value:
        capacity &&
        activeRightsTotal &&
        getAmountAndPercentage(capacity, activeRightsTotal)
    },
    {
      label: intl.formatMessage({
        id: 'page.placeRights.situationOverview.parkingEnforcement'
      }),
      value: (
        <MoovyParkingEnforcementStatus
          parkingEnforcement={parkingEnforcement}
        />
      )
    }
  ]
  return (
    <MoovyContainer loading={isLoading}>
      <MoovyContainerSubHeader
        title={
          <FormattedMessage id="page.placeRights.situationOverview.header" />
        }
        button={
          <MoovyButton
            startIcon={<EditRoundedIcon />}
            showAsIconButton
            aria-label=""
            hidden
          />
        }
        divider
      />
      <MoovyContainerContent>
        <MoovyListLabelValue splitByMiddle data={data} breakpoint="lg" />
      </MoovyContainerContent>
    </MoovyContainer>
  )
}

export default SituationOverview
