import { Box, CircularProgress, CircularProgressProps } from '@mui/material'
import { FC, ReactElement } from 'react'

const MoovyProgress: FC<CircularProgressProps> = (props): ReactElement => {
  const style = props.sx || {
    display: 'flex',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }

  return (
    <Box sx={style}>
      <CircularProgress {...props} />
    </Box>
  )
}

export default MoovyProgress
