import FormPlace, { DefaultPlaceValues } from '../formPlace'
import { PlaceResponseDTO, PlaceUpdateDTO } from '../../../apis/places/dto'
import { getValueIfNoMatch, hasAnyValues } from '../../../utils/utils'

import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '../../../components'
import { PlacesAPI } from '../../../apis/places/placesAPI'
import { useMutation } from '@tanstack/react-query'

type SubmitModalCallback = (response: PlaceResponseDTO) => void
type CancelModalCallback = () => void
export type ModalEditPlaceProps = {
  open: boolean
  defaultValues: any
  editablePlaceRef: string
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
}

const ModalEditPlace = (props: ModalEditPlaceProps) => {
  const FORM_REFERENCE = 'formUpdatePlace'
  const { defaultValues } = props

  const updatePlaceMutation = useMutation({
    mutationFn: (dto: PlaceUpdateDTO) =>
      PlacesAPI.updatePlace(props.editablePlaceRef, dto),
    onSuccess: (response) => {
      props.onSubmit(response.data)
    }
  })

  const updatePlace = (values: DefaultPlaceValues) => {
    const { name, streetAddress, postalCode, city } = values
    // Note, capacity cannot be modify
    const dto: PlaceUpdateDTO = {
      name: getValueIfNoMatch(name, defaultValues.name),
      streetAddress: getValueIfNoMatch(
        streetAddress,
        defaultValues.streetAddress
      ),
      postalCode: getValueIfNoMatch(postalCode, defaultValues.postalCode),
      city: getValueIfNoMatch(city, defaultValues.city)
    }

    if (hasAnyValues(dto)) {
      updatePlaceMutation.mutate(dto)
    }
  }

  const cancel = () => {
    updatePlaceMutation.reset()
    props.onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        errorTitleKey="common.actions.add.error"
        modalTitle={<FormattedMessage id="page.place.modalEditPlace.title" />}
        submitButtonContent={<FormattedMessage id="common.buttons.save" />}
        mutation={updatePlaceMutation}
      >
        <FormPlace
          showTitle
          onSubmit={(values) => {
            updatePlace(values)
          }}
          formReference={FORM_REFERENCE}
          defaultValues={defaultValues}
          editView
          customers={[]}
        />
      </MoovyDialog>
    </>
  )
}

export default ModalEditPlace
