import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyDialog, MoovyForm } from '../../../components'
import {
  OfferingResponseDTO,
  OfferingUpdateDTO
} from '../../../apis/offering/dto'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Formik } from 'formik'
import { MoovyToast } from '../../../utils/moovyToast'
import { OfferingsAPI } from '../../../apis/offering/offeringsAPI'
import { QUERY_OFFERINGS_GET_BY_PLACE } from '../../../apis/moovyQueryKeys'
import React from 'react'

type ModalEditOfferingProps = {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  offering?: OfferingResponseDTO
}

type FormOffering = {
  amount: string
}

const ModalEditOffering: React.FC<ModalEditOfferingProps> = ({
  open,
  onCancel,
  onSubmit,
  offering
}) => {
  const intl = useIntl()
  const FORM_REFERENCE = 'formEditOffering'
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: (dto: OfferingUpdateDTO) =>
      OfferingsAPI.updateOffering(
        offering?.id ?? '',
        offering?.placeId ?? '',
        dto
      ),
    onSuccess: () => {
      MoovyToast.showToast(
        <FormattedMessage id="page.offering.modalEditOffering.mutation.success" />
      )
      onSubmit()
      queryClient.invalidateQueries({
        queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, offering?.placeId]
      })
    }
  })

  const schema = yup.object().shape({
    amount: yup
      .number()
      .min(
        0,
        intl.formatMessage({
          id: 'page.offering.modalEditOffering.amount.minimum'
        })
      )
      .required(
        intl.formatMessage({
          id: 'common.form.errors.empty'
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'common.form.errors.number'
        })
      )
  })

  const submitHandler = (values: FormOffering) => {
    if (values?.amount) {
      return mutation.mutate({ amount: Number.parseInt(values.amount) })
    }
  }

  return (
    <MoovyDialog
      open={open}
      modalTitle={
        <FormattedMessage id="page.offering.modalEditOffering.title" />
      }
      submitButtonContent={<FormattedMessage id="common.buttons.save" />}
      onCancel={onCancel}
      formReference={FORM_REFERENCE}
      mutation={mutation}
    >
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={{
          amount: offering?.amount ? '' + offering.amount : ''
        }}
        validationSchema={schema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit} id={FORM_REFERENCE}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.offering.modalEditOffering.product" />
                }
              />
              {offering?.name}
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.offering.modalEditOffering.amountLabel" />
                }
              />
              <MoovyForm.FormTextField
                name="amount"
                value={values.amount}
                onChange={handleChange}
                error={!!errors.amount}
                errorText={errors.amount}
                sx={{ minWidth: '50%' }}
              />
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </MoovyDialog>
  )
}

export default ModalEditOffering
