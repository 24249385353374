import { FormattedMessage } from 'react-intl'
import { MoovyDateTime } from '../../components'
import { styled } from '@mui/material'

interface TimestampsProps {
  createdAt: string
  updatedAt: string
  createdText?: string | React.ReactNode
  dateOnly?: boolean
}

const TimeStampItems: React.FC<TimestampsProps> = ({
  createdAt,
  updatedAt,
  createdText,
  dateOnly
}) => {
  const Item = styled('span')`
    display: block;
    margin: 0;
  `
  const StyledLabel = styled('span')`
    margin-right: 0.3rem;
  `

  return (
    <>
      <Item>
        <StyledLabel>
          {createdText ? (
            createdText
          ) : (
            <FormattedMessage id="timeStampItems.createdAt" />
          )}
        </StyledLabel>
        <MoovyDateTime
          utcDateTime={createdAt}
          emptyText="-"
          dateOnly={dateOnly}
        />
      </Item>
      <Item>
        <StyledLabel>
          <FormattedMessage id={'timeStampItems.updatedAt'} />
        </StyledLabel>
        <MoovyDateTime
          utcDateTime={updatedAt}
          emptyText="-"
          dateOnly={dateOnly}
        />
      </Item>
    </>
  )
}

export default TimeStampItems
