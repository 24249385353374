import { BackendDatetime, UIDate } from '../../utils/datetimeUtils'
import { CatalogueReportDTO, RightReportDTO } from '../../apis/reports/dto'
import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CUSTOMER_GET_ALL,
  QUERY_REPORTS_GET_CATALOGUES,
  QUERY_REPORTS_GET_RIGHTS
} from '../../apis/moovyQueryKeys'
import { FormControl, Grid, MenuItem, Radio, RadioGroup } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyForm,
  MoovyMutationError,
  MoovyQueryHandler,
  MoovyScrollToTop
} from '../../components'
import React, { useEffect, useState } from 'react'

import CustomersAPI from '../../apis/customers/customersAPI'
import { DateRangePicker } from '@mui/x-date-pickers-pro'
import { DateTime } from 'luxon'
import { MoovyToast } from '../../utils/moovyToast'
import { ReportsAPI } from '../../apis/reports/reportsAPI'
import { assertExhaustive } from '../../utils/utils'
import jsonexport from 'jsonexport'
import useAuth from '../../hooks/useAuth'
import useLocale from '../../hooks/useLocale'
import { useQuery } from '@tanstack/react-query'

const Reports = () => {
  const intl = useIntl()
  const { locale } = useLocale()
  const { isPermission } = useAuth()
  type ReportType = 'LANDLORD' | 'TENANT'

  const [selectedCustomerId, setSelectedCustomerId] = useState('')
  const [reportType, setReportType] = useState<ReportType>(
    isPermission('READ', 'CATALOGUE_REPORTS') ? 'LANDLORD' : 'TENANT'
  )
  const initialDateTime = DateTime.local().setLocale(locale || 'fi')
  const [givenStartDate, setGivenStartDate] = useState<DateTime>(
    initialDateTime.startOf('day')
  )
  const [givenEndDate, setGivenEndDate] = useState<DateTime>(
    initialDateTime.endOf('day')
  )

  const queryCustomers = useQuery({
    queryKey: [QUERY_CUSTOMER_GET_ALL],
    queryFn: () => CustomersAPI.getCustomers(false, { limit: 500 }),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const customers = queryCustomers.data?.data || []
  const customerOptions = customers.map((item) => {
    return { key: item.id, value: item.name }
  })

  const queryCataloguesReport = useQuery({
    queryKey: [QUERY_REPORTS_GET_CATALOGUES, selectedCustomerId],
    queryFn: () =>
      ReportsAPI.getCataloguesReport(
        [selectedCustomerId],
        BackendDatetime.toUTC(givenStartDate),
        BackendDatetime.toUTC(givenEndDate)
      ),
    staleTime: DEFAULT_CACHE_TIME_MS,
    enabled: false
  })

  const queryRightsReport = useQuery({
    queryKey: [QUERY_REPORTS_GET_RIGHTS, selectedCustomerId],
    queryFn: () =>
      ReportsAPI.getRightsReport(
        selectedCustomerId,
        BackendDatetime.toUTC(givenStartDate),
        BackendDatetime.toUTC(givenEndDate)
      ),
    staleTime: DEFAULT_CACHE_TIME_MS,
    enabled: false
  })

  useEffect(() => {
    if (
      queryCustomers.isSuccess &&
      !selectedCustomerId &&
      customerOptions.length > 0
    ) {
      setSelectedCustomerId(customerOptions[0].key)
    }
  }, [queryCustomers.isSuccess])

  const generateCSVReport = (
    catalogueResponse: CatalogueReportDTO | RightReportDTO | undefined
  ) => {
    if (!catalogueResponse?.data) {
      return ''
    }

    let csvResult = ''

    try {
      jsonexport(catalogueResponse.data, (err, csv) => {
        if (err) {
          console.warn('Failed to generate CSV')
          return ''
        }
        csvResult = csv
      })

      return csvResult
    } catch (error) {
      console.warn('Failed to generate CSV')
    }

    return ''
  }

  const generateCSVFile = (reportName: string, result: any) => {
    const startDate = givenStartDate
      ? UIDate.toCustomString(givenStartDate)
      : ''
    const endDate = givenEndDate ? UIDate.toCustomString(givenEndDate) : ''
    const filename = `${reportName}-${startDate}-${endDate}`

    const blob = new Blob([result], { type: 'data:text/csv;charset=utf-8,' })
    const blobURL = window.URL.createObjectURL(blob)

    const anchor = document.createElement('a')
    anchor.download = `${filename}.csv`
    anchor.href = blobURL
    anchor.dataset.downloadurl = [
      'text/csv',
      anchor.download,
      anchor.href
    ].join(':')
    anchor.click()

    // Remove URL.createObjectURL. The browser should not save the reference to the file.
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(blobURL)
    }, 100)
  }

  const requestReport = async () => {
    if (!givenStartDate && !givenEndDate && !selectedCustomerId) {
      return
    }

    switch (reportType) {
      case 'LANDLORD':
        await queryCataloguesReport.refetch()
        break
      case 'TENANT':
        await queryRightsReport.refetch()
        break
      default:
        assertExhaustive(reportType)
    }
  }

  useEffect(() => {
    if (
      queryCataloguesReport.fetchStatus === 'idle' &&
      queryCataloguesReport.isSuccess
    ) {
      const response = queryCataloguesReport.data
      if (!response) {
        MoovyToast.showToast(
          <FormattedMessage id="page.reports.toast.error.emptyReport" />
        )
        return
      }
      const result = generateCSVReport(response)
      if (!result) {
        MoovyToast.showToast(
          <FormattedMessage id="page.reports.toast.error.emptyReport" />,
          false
        )
        return
      }
      generateCSVFile(response.reportName, result)
    }
  }, [queryCataloguesReport.fetchStatus])

  useEffect(() => {
    if (
      queryRightsReport.fetchStatus === 'idle' &&
      queryRightsReport.isSuccess
    ) {
      const response = queryRightsReport.data
      if (!response) {
        MoovyToast.showToast(
          <FormattedMessage id="page.reports.toast.error.emptyReport" />
        )
        return
      }
      const result = generateCSVReport(response)
      if (!result) {
        MoovyToast.showToast(
          <FormattedMessage id="page.reports.toast.error.emptyReport" />,
          false
        )
        return
      }
      generateCSVFile(response.reportName, result)
    }
  }, [queryRightsReport.fetchStatus])

  return (
    <MoovyQueryHandler query={queryCustomers} rootPageIfNotFoundError>
      <MoovyScrollToTop />
      <MoovyContainer>
        <MoovyContainerHeader>
          <FormattedMessage id="page.reports.title" />
        </MoovyContainerHeader>
        <MoovyContainerContent>
          <form>
            <Grid container>
              <Grid item md={6}>
                <MoovyForm.FormContainer>
                  <MoovyForm.FormBoxRow>
                    <MoovyMutationError
                      query={queryCataloguesReport}
                      errorTitleKey="common.actions.get.error"
                    />
                    <MoovyMutationError
                      query={queryRightsReport}
                      errorTitleKey="common.actions.get.error"
                    />
                  </MoovyForm.FormBoxRow>
                  {(isPermission('READ', 'CATALOGUE_REPORTS') ||
                    isPermission('READ', 'RIGHT_REPORTS')) && (
                    <MoovyForm.FormBoxRow>
                      <RadioGroup
                        value={reportType}
                        onChange={(event) =>
                          setReportType(event.target.value as ReportType)
                        }
                      >
                        <MoovyForm.FormLabel
                          sx={{ fontWeight: 'bold' }}
                          focused={false}
                        >
                          <FormattedMessage id="page.reports.type.radioGroup.title" />
                        </MoovyForm.FormLabel>
                        {isPermission('READ', 'CATALOGUE_REPORTS') && (
                          <MoovyForm.FormRadio
                            control={<Radio />}
                            label={
                              <FormattedMessage id="page.reports.type.radioGroup.landlord" />
                            }
                            value={'LANDLORD'}
                          />
                        )}
                        <MoovyForm.FormRadio
                          control={<Radio />}
                          label={
                            <FormattedMessage id="page.reports.type.radioGroup.tenant" />
                          }
                          value={'TENANT'}
                        />
                      </RadioGroup>
                    </MoovyForm.FormBoxRow>
                  )}
                  <MoovyForm.FormLabel
                    sx={{ fontWeight: 'bold' }}
                    focused={false}
                  >
                    <FormattedMessage id="page.reports.customer.title" />
                  </MoovyForm.FormLabel>
                  <MoovyForm.FormBoxRow>
                    <MoovyForm.FormSelect
                      name="customerId"
                      label={intl.formatMessage({
                        id: 'page.reports.customer.label'
                      })}
                      value={selectedCustomerId}
                      onChange={(event) =>
                        setSelectedCustomerId(
                          (event?.target?.value as string) || ''
                        )
                      }
                    >
                      {customerOptions.map((item) => {
                        return (
                          <MenuItem key={item.key} value={item.key}>
                            {item.value}
                          </MenuItem>
                        )
                      })}
                    </MoovyForm.FormSelect>
                  </MoovyForm.FormBoxRow>
                  <MoovyForm.FormLabel
                    sx={{ fontWeight: 'bold' }}
                    focused={false}
                  >
                    <FormattedMessage id="page.reports.range.title" />
                  </MoovyForm.FormLabel>
                  <MoovyForm.FormBoxRow>
                    <FormControl fullWidth>
                      <DateRangePicker
                        localeText={{
                          start: intl.formatMessage({
                            id: 'page.reports.daterange.start'
                          }),
                          end: intl.formatMessage({
                            id: 'page.reports.daterange.end'
                          }),
                          toolbarTitle: intl.formatMessage({
                            id: 'page.reports.range.title'
                          }),
                          cancelButtonLabel: intl.formatMessage({
                            id: 'common.buttons.cancel'
                          }),
                          clearButtonLabel: intl.formatMessage({
                            id: 'common.buttons.clear'
                          }),
                          okButtonLabel: intl.formatMessage({
                            id: 'common.ok'
                          })
                        }}
                        value={[givenStartDate, givenEndDate]}
                        displayWeekNumber
                        onAccept={(values) => {
                          const startDate = values[0]
                          const endDate = values[1]
                          setGivenStartDate(
                            startDate || initialDateTime.startOf('day')
                          )
                          setGivenEndDate(
                            endDate || initialDateTime.endOf('day')
                          )
                        }}
                      />
                    </FormControl>
                  </MoovyForm.FormBoxRow>
                  <MoovyForm.FormBoxRow>
                    {(isPermission('READ', 'CATALOGUE_REPORTS') ||
                      isPermission('READ', 'RIGHT_REPORTS')) && (
                      <MoovyButton
                        onClick={requestReport}
                        query={
                          reportType === 'LANDLORD'
                            ? queryCataloguesReport
                            : queryRightsReport
                        }
                      >
                        <FormattedMessage id="page.reports.submit.button" />
                      </MoovyButton>
                    )}
                  </MoovyForm.FormBoxRow>
                </MoovyForm.FormContainer>
              </Grid>
            </Grid>
          </form>
        </MoovyContainerContent>
      </MoovyContainer>
    </MoovyQueryHandler>
  )
}

export default Reports
