import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import MoovyPagination, { MoovyPaginationProps } from '../moovyPagination'
import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import MoovyMutationError from '../moovyMutationError'
import MoovyProgress from '../moovyProgress'
import { UseQueryResult } from '@tanstack/react-query'

interface MoovyGridProps {
  emptyTextId: string
  errorTextId: string
  hidden?: boolean
  query: UseQueryResult<any, any> | undefined
  pagination?: MoovyPaginationProps
  children?: React.ReactElement[]
}

const MoovyGrid = ({
  emptyTextId,
  errorTextId,
  hidden,
  query,
  pagination,
  children
}: MoovyGridProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [page, setPage] = useState(pagination?.page || 1)
  const cards = children
  const showEmptyText = !query?.isError && (!cards || cards.length <= 0)
  const showLoading = query?.isLoading || query?.isFetching

  // As a default, the grid is hidden in browser view.
  const isHidden = hidden || !isMobile

  return (
    !isHidden && (
      <Grid container gap={2} justifyContent={'center'}>
        {showLoading && <MoovyProgress />}
        <MoovyMutationError
          query={query}
          errorTitleKey={errorTextId || 'common.actions.error'}
        />
        {cards?.map((item, index) => (
          <Grid key={index} item xs={12}>
            {item}
          </Grid>
        ))}
        {showEmptyText && (
          <Grid container xs={12} justifyContent={'center'}>
            {(emptyTextId && <FormattedMessage id={emptyTextId} />) || (
              <FormattedMessage id="common.actions.get.error" />
            )}
          </Grid>
        )}
        {pagination && pagination.total > pagination.limit && (
          <Grid item xs={12}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <MoovyPagination
                page={page}
                total={pagination.total}
                limit={pagination.limit}
                onNextPage={(page) => {
                  setPage(page)
                  pagination.onNextPage(page)
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    )
  )
}

export default MoovyGrid
