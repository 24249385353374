import * as yup from 'yup'

import React, { useState } from 'react'
import {
  RightHolderIndividualDTO,
  RightHolderVehicleDTO
} from '../../../apis/rightHolders/dto'
import {
  isValidLicensePlate,
  isValidPhoneNumber
} from '../../../utils/validator'

import { Formik } from 'formik'
import { MoovyForm } from '../../../components'
import isEmail from 'validator/lib/isEmail'
import { useIntl } from 'react-intl'

export interface EditRightHolderValues {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  licensePlate?: string
}

type SubmitFormCallback = (values: EditRightHolderValues) => void
export type FormEditRightHolderProps = {
  formReference: string
  onSubmit: SubmitFormCallback
  rightHolder: RightHolderIndividualDTO | RightHolderVehicleDTO
}

const FormEditRightHolder = (props: FormEditRightHolderProps) => {
  const intl = useIntl()

  const getRightHolderValues = () => {
    let firstName,
      lastName,
      email,
      phoneNumber,
      licensePlate = ''
    if (props.rightHolder?.type === 'individual') {
      const dataIndividual = props.rightHolder as RightHolderIndividualDTO
      firstName = dataIndividual.firstName
      lastName = dataIndividual.lastName
      email = dataIndividual.email
      phoneNumber = dataIndividual.phoneNumber
    } else if (props.rightHolder?.type === 'vehicle') {
      const dataVehicle = props.rightHolder as RightHolderVehicleDTO
      licensePlate = dataVehicle.licensePlate
    }

    return { firstName, lastName, email, phoneNumber, licensePlate }
  }

  const getFormDefaultValues = () => {
    const { firstName, lastName, email, phoneNumber, licensePlate } =
      getRightHolderValues()
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      licensePlate: licensePlate || ''
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)

  const schemaValidator = () => {
    return yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .test(
          'firstName',
          intl.formatMessage({ id: 'common.form.errors.empty' }),
          function (firstName?: string) {
            return props.rightHolder?.type !== 'individual' || !!firstName
          }
        ),
      lastName: yup
        .string()
        .trim()
        .test(
          'lastName',
          intl.formatMessage({ id: 'common.form.errors.empty' }),
          function (lastName?: string) {
            return props.rightHolder?.type !== 'individual' || !!lastName
          }
        ),
      email: yup
        .string()
        .test(
          'email',
          intl.formatMessage({ id: 'common.form.errors.email' }),
          function (email?: string) {
            return (
              props.rightHolder?.type !== 'individual' ||
              (!!email && isEmail(email))
            )
          }
        ),
      phoneNumber: yup
        .string()
        .trim()
        .test(
          'phoneNumber',
          intl.formatMessage({
            id: 'common.form.errors.phoneNumber'
          }),
          function (phoneNumber?: string) {
            return (
              props.rightHolder?.type !== 'individual' ||
              isValidPhoneNumber(phoneNumber)
            )
          }
        ),
      licensePlate: yup
        .string()
        .trim()
        .test(
          'licensePlate',
          intl.formatMessage({
            id: 'common.form.errors.licensePlate'
          }),
          function (licensePlate?: string) {
            return (
              props.rightHolder?.type !== 'vehicle' ||
              isValidLicensePlate(licensePlate)
            )
          }
        )
    })
  }

  const onSubmitFormValues = (values: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    licensePlate: string
  }) => {
    const { firstName, lastName, email, phoneNumber, licensePlate } = values

    if (props.rightHolder?.type === 'individual') {
      props.onSubmit({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        phoneNumber: phoneNumber.trim()
      })
    } else {
      props.onSubmit({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
        licensePlate: licensePlate.trim().toLocaleUpperCase()
      })
    }
  }

  const FormIndividual = ({
    values,
    handleChange,
    errors
  }: {
    values: EditRightHolderValues
    handleChange: any
    errors: any
  }) => {
    return (
      <>
        <MoovyForm.FormBoxRow>
          <MoovyForm.FormTextField
            name="firstName"
            data-testid="firstName"
            value={values.firstName}
            label={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.firstName'
            })}
            placeholder={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.firstName'
            })}
            onChange={handleChange}
            error={!!errors.firstName}
            errorText={errors.firstName}
          />
          <MoovyForm.FormTextField
            name="lastName"
            data-testid="lastName"
            value={values.lastName}
            label={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.lastName'
            })}
            placeholder={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.lastName'
            })}
            onChange={handleChange}
            error={!!errors.lastName}
            errorText={errors.lastName}
          />
        </MoovyForm.FormBoxRow>
        <MoovyForm.FormBoxRow>
          <MoovyForm.FormTextField
            name="email"
            data-testid="email"
            value={values.email}
            label={intl.formatMessage({
              id: 'common.text.email'
            })}
            placeholder={intl.formatMessage({
              id: 'common.text.email'
            })}
            onChange={handleChange}
            error={!!errors.email}
            errorText={errors.email}
          />
          <MoovyForm.FormTextField
            name="phoneNumber"
            data-testid="phoneNumber"
            value={values.phoneNumber}
            label={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.phoneNumber'
            })}
            placeholder={intl.formatMessage({
              id: 'page.rightHolders.rightHolder.formEditRightHolder.label.phoneNumber'
            })}
            onChange={handleChange}
            error={!!errors.phoneNumber}
            errorText={errors.phoneNumber}
          />
        </MoovyForm.FormBoxRow>
      </>
    )
  }

  const FormVehicle = ({
    values,
    handleChange,
    errors
  }: {
    values: EditRightHolderValues
    handleChange: any
    errors: any
  }) => {
    return (
      <>
        <MoovyForm.FormTextField
          name="licensePlate"
          data-testid="licensePlate"
          value={values.licensePlate}
          label={intl.formatMessage({
            id: 'common.text.licensePlate'
          })}
          placeholder={intl.formatMessage({
            id: 'common.text.licensePlate'
          })}
          onChange={handleChange}
          error={!!errors.licensePlate}
          errorText={errors.licensePlate}
        />
      </>
    )
  }

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={schemaValidator()}
        enableReinitialize={true}
        initialValues={getFormDefaultValues()}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              {props.rightHolder?.type === 'individual' && (
                <FormIndividual
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                />
              )}
              {props.rightHolder?.type === 'vehicle' && (
                <FormVehicle
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                />
              )}
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormEditRightHolder
