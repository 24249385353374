import { useMediaQuery, useTheme } from '@mui/material'

import MoovyCard from '../moovyCard'
import { MoovyCardMenuItem } from '../moovyCard/moovyCardMenu'
import React from 'react'

interface MoovyGridCardProps {
  key: React.Key
  avatarName?: string
  cardTitle: React.ReactElement | string
  cardSecondaryTitle: React.ReactElement | string
  cardContentList: string[] | React.ReactNode[]
  cardExpandContentList?: string[]
  cardMenuActions?: MoovyCardMenuItem[]
  onCardClicked?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MoovyGridCard = (props: MoovyGridCardProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return isMobile && <MoovyCard {...props} />
}

export default MoovyGridCard
