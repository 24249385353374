import * as Sentry from '@sentry/react'
import {
  SENTRY_DSN,
  SENTRY_RELEASE,
  SENTRY_TRACES_SAMPLE_RATE,
  SENTRY_TRACE_PROPAGATION_TARGETS,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  SENTRY_DEBUG,
  ENVIRONMENT
} from '../envConfig'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

export default () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    debug: SENTRY_DEBUG,
    release: SENTRY_RELEASE,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        // Default values, but makes it explicit for privacy reasons
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true
      })
    ],

    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: SENTRY_TRACE_PROPAGATION_TARGETS,

    // Setting these will enable the replay feature.
    // By default, all user input and text is masked with * and all media is blocked for privacy reasons
    replaysSessionSampleRate: SENTRY_REPLAY_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
  })
}
