import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import { useEffect, useState } from 'react'

import { FinnishBusinessIds } from 'finnish-business-ids'
import { Formik } from 'formik'
import { MoovyForm } from '../../components'

export interface DefaultCustomerValues {
  name: string
  streetAddress: string
  postalCode: string
  city: string
  businessId: string
}

type SubmitFormCallback = (values: DefaultCustomerValues) => void
export type FormCustomerProps = {
  showTitle?: boolean
  onSubmit: SubmitFormCallback
  formReference: string
  defaultValues?: DefaultCustomerValues
  editView?: boolean
}

const FormCustomer = (props: FormCustomerProps) => {
  const intl = useIntl()

  const getFormDefaultValues = () => {
    return {
      name: props.defaultValues?.name || '',
      streetAddress: props.defaultValues?.streetAddress || '',
      postalCode: props.defaultValues?.postalCode || '',
      city: props.defaultValues?.city || '',
      businessId: props.defaultValues?.businessId || ''
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues, setDefaultValues] = useState<DefaultCustomerValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [
    props.defaultValues?.businessId,
    props.defaultValues?.city,
    props.defaultValues?.name,
    props.defaultValues?.postalCode,
    props.defaultValues?.streetAddress
  ])

  const CustomerSchema = () => {
    return yup.object().shape({
      name: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      businessId: yup.string().test(
        'businessId',
        intl.formatMessage({
          id: 'common.form.errors.businessId'
        }),
        function (businessId?: string) {
          return (
            !!businessId && FinnishBusinessIds.isValidBusinessId(businessId)
          )
        }
      ),
      streetAddress: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      postalCode: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      city: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        )
    })
  }

  const onSubmitFormValues = (values: DefaultCustomerValues) => {
    const { name, streetAddress, postalCode, city, businessId } = values
    props.onSubmit({
      name: name.trim(),
      streetAddress: streetAddress.trim(),
      postalCode: postalCode.trim(),
      city: city.trim(),
      businessId: businessId.trim()
    })
  }

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={CustomerSchema()}
        enableReinitialize={true}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              {props.showTitle && (
                <MoovyForm.FormTitle
                  title={
                    <FormattedMessage id="page.customers.formCustomer.contentTitle" />
                  }
                />
              )}
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="name"
                  data-testid="name"
                  value={values.name}
                  autoFocus
                  label={intl.formatMessage({
                    id: 'page.customers.formCustomer.label.name'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customers.formCustomer.label.name'
                  })}
                  onChange={handleChange}
                  error={!!errors.name}
                  errorText={errors.name}
                />
                <MoovyForm.FormTextField
                  name="businessId"
                  data-testid="businessId"
                  value={values.businessId}
                  label={intl.formatMessage({
                    id: 'page.customers.formCustomer.label.businessId'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.customers.formCustomer.label.businessId'
                  })}
                  onChange={handleChange}
                  error={!!errors.businessId}
                  errorText={errors.businessId}
                  disabled={props.editView}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormBoxRow>
                <MoovyForm.FormBoxRow flexDirection={'column'}>
                  <MoovyForm.FormTextField
                    name="streetAddress"
                    data-testid="streetAddress"
                    value={values.streetAddress}
                    label={intl.formatMessage({
                      id: 'page.customers.formCustomer.label.streetAddress'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'page.customers.formCustomer.label.streetAddress'
                    })}
                    onChange={handleChange}
                    error={!!errors.streetAddress}
                    errorText={errors.streetAddress}
                  />
                  <MoovyForm.FormBoxRow>
                    <MoovyForm.FormTextField
                      name="postalCode"
                      data-testid="postalCode"
                      value={values.postalCode}
                      label={intl.formatMessage({
                        id: 'page.customers.formCustomer.label.postalCode'
                      })}
                      placeholder={intl.formatMessage({
                        id: 'page.customers.formCustomer.label.postalCode'
                      })}
                      onChange={handleChange}
                      error={!!errors.postalCode}
                      errorText={errors.postalCode}
                    />
                    <MoovyForm.FormTextField
                      name="city"
                      data-testid="city"
                      value={values.city}
                      label={intl.formatMessage({
                        id: 'page.customers.formCustomer.label.city'
                      })}
                      placeholder={intl.formatMessage({
                        id: 'page.customers.formCustomer.label.city'
                      })}
                      onChange={handleChange}
                      error={!!errors.city}
                      errorText={errors.city}
                    />
                  </MoovyForm.FormBoxRow>
                </MoovyForm.FormBoxRow>
              </MoovyForm.FormBoxRow>
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormCustomer
