import { MoovyAction, MoovyResource } from '../../app/permissions'

import useAuth from '../../hooks/useAuth'

export type MoovyRestrictedProps = {
  action: MoovyAction
  resource: MoovyResource
  visibility?: boolean
  children?: React.ReactNode
}

const MoovyRestricted = ({
  action,
  resource,
  visibility = true,
  children
}: MoovyRestrictedProps) => {
  const { isPermission } = useAuth()

  return <>{isPermission(action, resource) && visibility && children}</>
}

export default MoovyRestricted
