import { Box, Container, Grid, Paper, styled } from '@mui/material'
import MoovyContainerContent, {
  MoovyContainerContentProps
} from './moovyContainerContent'
import MoovyContainerHeader, {
  MoovyContainerHeaderProps
} from './moovyContainerHeader'
import MoovyContainerSubHeader, {
  MoovyContainerSubHeaderProps
} from './moovyContainerSubHeader'
import { MoovyMutationError, MoovyProgress, MoovyRestricted } from '..'
import React, { ReactNode } from 'react'

import { UseQueryResult } from '@tanstack/react-query'

interface MoovyContainerProps {
  loading?: boolean
  children:
    | [
        React.ReactElement<MoovyContainerHeaderProps>,
        (
          | React.ReactElement<MoovyContainerSubHeaderProps>
          | React.ReactElement<MoovyContainerContentProps>
        )
      ]
    | [
        React.ReactElement<MoovyContainerHeaderProps>,
        (
          | React.ReactElement<MoovyContainerSubHeaderProps>
          | React.ReactElement<MoovyContainerContentProps>
        ),
        (
          | React.ReactElement<MoovyContainerSubHeaderProps>
          | React.ReactElement<MoovyContainerContentProps>
        )
      ]
    | [
        React.ReactElement<MoovyContainerSubHeaderProps>,
        React.ReactElement<MoovyContainerContentProps>
      ]
    | React.ReactElement<MoovyContainerSubHeaderProps>
    | React.ReactElement<MoovyContainerContentProps>
}

const MoovyHeader = ({
  breadcrumbsVisible,
  children
}: {
  breadcrumbsVisible: boolean
  children: ReactNode
}) => {
  return (
    <React.Fragment>
      {children && (
        <h1 style={{ marginTop: breadcrumbsVisible ? '0' : undefined }}>
          {children}
        </h1>
      )}
    </React.Fragment>
  )
}

const MoovySubHeader = ({ children }: { children: ReactNode }) => {
  return <React.Fragment>{children}</React.Fragment>
}

const MoovyContent = ({
  query,
  children
}: {
  children: ReactNode
  query?: UseQueryResult<any, any>
}) => {
  return (
    <React.Fragment>
      {query && query.error && (
        <Grid>
          <Grid item pl={2} pr={2} pb={2}>
            <MoovyMutationError
              query={query}
              errorTitleKey={'common.actions.get.error'}
            />
          </Grid>
        </Grid>
      )}
      {children && (
        <Grid>
          <Grid item pl={2} pr={2} pb={2}>
            {children}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

const MoovyContainer: React.FC<MoovyContainerProps> = ({
  loading,
  children
}) => {
  if (
    React.Children.toArray(children).some(
      (child) =>
        React.isValidElement(child) &&
        (child.type as any) !== MoovyContainerHeader &&
        (child.type as any) !== MoovyContainerSubHeader &&
        (child.type as any) !== MoovyContainerContent &&
        (child.type as any) !== MoovyRestricted
    )
  ) {
    throw new Error(
      'MoovyContainer contains invalid components. They must be MoovyContainerHeader, MoovyContainerSubHeader or MoovyContainerContent.'
    )
  }

  const getContent = <T,>(
    source: React.ReactNode,
    target: React.ComponentType<T>
  ): React.ReactElement<T> | undefined => {
    return React.Children.toArray(source).find(
      (child) => React.isValidElement(child) && (child.type as any) === target
    ) as React.ReactElement<T> | undefined
  }

  const header = getContent<MoovyContainerHeaderProps>(
    children,
    MoovyContainerHeader
  )
  const subHeader = getContent<MoovyContainerSubHeaderProps>(
    children,
    MoovyContainerSubHeader
  )
  const content = getContent<MoovyContainerContentProps>(
    children,
    MoovyContainerContent
  )

  const breadcrumbsVisible = !!header?.props.breadcrumbs

  const StyledPaper = styled(Paper)({
    boxShadow: '6px 6px 54px 0px rgba(0, 0, 0, 0.05)'
  })

  return (
    <Box
      sx={{
        position: 'relative'
      }}
    >
      {loading ? (
        <MoovyProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        />
      ) : (
        <React.Fragment>
          <MoovyHeader breadcrumbsVisible={breadcrumbsVisible}>
            {header}
          </MoovyHeader>
          <Container style={{ padding: 0 }} component={StyledPaper}>
            <React.Fragment>
              <MoovySubHeader>{subHeader}</MoovySubHeader>
              <MoovyContent>{content}</MoovyContent>
            </React.Fragment>
          </Container>
        </React.Fragment>
      )}
    </Box>
  )
}

export default MoovyContainer
