import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'

import { FormattedMessage } from 'react-intl'
import { useEffect } from 'react'
import useGlobalUI from '../../hooks/useGlobalUI'

type MoovySortAndFilterProps = {
  filterKey: string
  items: { key: string; value: string }[]
  value: string
  pt?: number
  pb?: number
  onChange: (value: string) => void
}
const MoovySortAndFilter = ({
  filterKey,
  items,
  value,
  pt,
  pb,
  onChange
}: MoovySortAndFilterProps) => {
  const [state, setState] = useGlobalUI({
    key: filterKey,
    initialValue: { value: value || '' }
  })

  useEffect(() => {
    if (state.value) {
      onChange(state.value)
    }
  }, [state.value])
  return (
    <Grid container direction="row" pt={pt} pb={pb}>
      <Grid item md={4} xs={12}>
        <FormControl fullWidth size="small">
          <InputLabel id="moovy-sort-and-filter-select-label">
            <FormattedMessage id="moovySortAndFilter.sortingCriteria" />
          </InputLabel>
          <Select
            labelId="moovy-sort-and-filter-select-label"
            id="moovy-sort-and-filter-select"
            label={<FormattedMessage id="moovySortAndFilter.sortingCriteria" />}
            value={value}
            onChange={(event) =>
              setState({ ...state, value: event.target.value })
            }
          >
            {items.map((item, index) => (
              <MenuItem key={index} value={item.key}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default MoovySortAndFilter
