import { Button, CircularProgress, Link, styled } from '@mui/material'
import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react'

export type MoovyLinkProps = {
  style?: CSSProperties | undefined
  loading?: boolean
  red?: boolean
  disabled?: boolean
  bold?: boolean
  onClick?: MouseEventHandler
  children: ReactNode
}

const LinkButton = styled(Button)({
  textTransform: 'none',
  padding: 0,
  minWidth: 0,
  '&:hover': {
    backgroundColor: 'transparent' // Disable hover effect
  }
})

const MoovyLink = (props: MoovyLinkProps) => {
  return (
    <>
      <LinkButton
        style={props.style}
        onClick={(event) => {
          if (props.onClick) {
            if (props.disabled || props.loading) {
              event.preventDefault()
            } else {
              props.onClick(event)
            }
          }
        }}
        color={props?.red ? 'error' : undefined}
      >
        <>{(props.bold && <b>{props.children}</b>) || props.children}</>
      </LinkButton>
      {props.loading && <CircularProgress size="mini" />}
    </>
  )
}

export default MoovyLink
