import { BackendDatetime, UIDate, UIDatetime } from '../../utils/datetimeUtils'

import useLocale from '../../hooks/useLocale'

export type MoovyDateTimeProps = {
  utcDateTime?: string | undefined
  dateOnly?: boolean
  emptyText?: string
}
const MoovyDateTime = (props: MoovyDateTimeProps) => {
  if (!props.utcDateTime) {
    return props?.emptyText || ''
  }

  const { locale } = useLocale()
  const datetime = BackendDatetime.fromUTC(props.utcDateTime, locale)
  if (props.dateOnly) {
    return <>{UIDate.toString(datetime)}</>
  }
  return <>{UIDatetime.toString(datetime)}</>
}

export default MoovyDateTime
