export default (gtagId: string) => {
  const scriptElements = [0, 1].map((_) => document.createElement('script'))

  if (!/^[a-zA-Z0-9-]*$/.test(gtagId)) {
    throw new Error(
      `GTAG_ID must only contain letters, numbers and hyphens (got: "${gtagId}")`
    )
  }

  // first script element (loading Google Analytics)
  scriptElements[0].attributes.setNamedItem(document.createAttribute('async'))
  const srcAttribute = document.createAttribute('src')
  srcAttribute.value = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`
  scriptElements[0].attributes.setNamedItem(srcAttribute)

  // second script element (inline script)
  scriptElements[1].textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${gtagId}');
  `

  // inject the script elements
  scriptElements.forEach((scriptElement) =>
    document.getElementsByTagName('body')[0].appendChild(scriptElement)
  )
}
