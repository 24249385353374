import { Box, Tab } from '@mui/material'
import { ReactNode, useEffect } from 'react'

import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import useGlobalUI from '../../hooks/useGlobalUI'

export type MoovyTabProps = {
  defaultPanel?: string
  tabKey: string
  tabHeaders: string[]
  tabPanels: ReactNode[]
}

const MoovyTab = ({
  tabKey,
  tabHeaders,
  defaultPanel,
  tabPanels
}: MoovyTabProps) => {
  const [state, setState] = useGlobalUI({
    key: tabKey,
    initialValue: { value: defaultPanel || '0' }
  })

  useEffect(() => {
    if (state.value >= tabPanels.length) {
      setState({ ...state, value: (tabPanels.length - 1).toString() })
    }
  }, [state])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setState({ ...state, value: newValue })
  }

  return (
    <TabContext value={state.value}>
      <Box sx={{ paddingTop: 2, borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange}>
          {tabHeaders.map((header, index) => (
            <Tab label={header} key={index} value={index.toString()} />
          ))}
        </TabList>
      </Box>
      {tabPanels.map((item, index) => (
        <TabPanel key={index} value={index.toString()} style={{ padding: 0 }}>
          {item}
        </TabPanel>
      ))}
    </TabContext>
  )
}

export default MoovyTab
