{
  "Account recovery requires verified contact information": "Tilin palauttaminen vaatii vahvistetut yhteystiedot",
  "Add your Profile": "Lisää profiili",
  "Add your Website": "Lisää www-sivu",
  "Back to Sign In": "Takaisin kirjautumiseen",
  "Change Password": "Vaihda salasana",
  "Changing": "Vaihdetaan",
  "Code": "Koodi",
  "Confirm Password": "Vahvista salasana",
  "Confirm Sign Up": "Vahvista tilin luonti",
  "Confirm SMS Code": "Vahvista SMS -koodi",
  "Confirm TOTP Code": "Vahvista TOTP -koodi",
  "Confirm": "Vahvista",
  "Confirmation Code": "Vahvistuskoodi",
  "Confirming": "Vahvistetaan",
  "Create a new account": "Luo uusi tili",
  "Create Account": "Luo tili",
  "Creating Account": "Tiliä luodaan",
  "Dismiss alert": "Hylkää ilmoitukset",
  "Given Name": "Etunimi",
  "Family Name": "Sukunimi",
  "Name": "Nimi",
  "Email": "Sähköposti",
  "Enter your Birthdate": "Syötä syntymäpäivä",
  "Enter your code": "Syötä koodi",
  "Enter your Confirmation Code": "Syötä vahvistuskoodi",
  "Enter your Email": "Syötä sähköposti",
  "Enter your Family Name": "Syötä sukunimi",
  "Enter your Given Name": "Syötä nimi",
  "Enter your Middle Name": "Syötä toinen nimi",
  "Enter your Name": "Syötä nimi",
  "Enter your Nickname": "Syötä lempinimi",
  "Enter your Password": "Syötä salasana",
  "Enter your phone number": "Syötä puhelinnumero",
  "Enter your Preferred Username": "Syötä ensisijainen käyttäjänimi",
  "Enter your username": "Syötä käyttäjänimi",
  "Forgot password?": "Unohtuiko salasana?",
  "Forgot your password?": "Unohtuiko salasana?",
  "Hide password": "Piilota salasana",
  "It may take a minute to arrive": "Saapuminen voi kestää minuutin",
  "Loading": "Ladataan",
  "New password": "Uusi salasana",
  "New Password": "Uusi salasana",
  "or": "tai",
  "Password": "Salasana",
  "Phone Number": "Puhelinnumero",
  "Please confirm your Password": "Vahvista salasana",
  "Resend Code": "Lähetä koodi uudestaan",
  "Reset your password": "Nollaa salasana",
  "Reset your Password": "Nollaa salasana",
  "Reset Password": "Nollaa salasana",
  "Enter your email": "Syötä sähköpostiosoite",
  "Code *": "Koodi *",
  "Send code": "Lähetä koodi",
  "Send Code": "Lähetä koodi",
  "Sending": "Lähetetään",
  "Setup TOTP": "Aseta TOTP",
  "Show password": "Näytä salasana",
  "Sign in to your account": "Kirjaudu tiliin",
  "Sign In with Amazon": "Kirjaudu Amazon -tilillä",
  "Sign In with Apple": "Kirjaudu Apple -tilillä",
  "Sign In with Facebook": "Kirjaudu Facebook -tilillä",
  "Sign In with Google": "Kirjaudu Google -tilillä",
  "Sign in": "Kirjaudu sisään",
  "Sign In": "Kirjaudu sisään",
  "Signing in": "Kirjaudutaan sisään",
  "Skip": "Ohita",
  "Submit": "Hyväksy",
  "Submitting": "Hyväksytään",
  "Username": "Käyttäjänimi",
  "Verify Contact": "Vahvista yhteystieto",
  "Verify": "Vahvista",
  "We Emailed You": "Lähetimme sinulle sähköpostia",
  "We Sent A Code": "Lähetimme sinulle koodin",
  "We Texted You": "Lähetimme sinulle viestin",
  "Your code is on the way. To log in, enter the code we emailed to": "Koodisi on matkalla sinulle. Kirjaudu sisään sähköpostiisi lähetetyllä koodilla",
  "Your code is on the way. To log in, enter the code we sent you": "Koodisi on matkalla sinulle. Kirjaudu sisään sinulle lähetetyllä koodilla",
  "Your code is on the way. To log in, enter the code we texted to": "Koodisi on matkalla sinulle. Kirjaudu sisään sinulle lähetetyllä koodilla",
  "User does not exist.": "Käyttäjää ei ole olemassa.",
  "Incorrect username or password.": "Virheellinen käyttäjänimi tai salasana.",
  "Your passwords must match": "Salasanojen pitää olla samoja.",
  "Password must have at least 8 characters": "Salasanassa pitää olla vähintään 8 merkkiä.",
  "An account with the given email already exists.": "Samalla sähköpostilla on jo tili olemassa.",
  "Invalid verification code provided, please try again.": "Virheellinen vahvistuskoodi, yritä uudelleen.",
  "Password does not conform to policy: Password must have uppercase characters": "Salasana ei täytä vaatimuksia. Salasanassa on oltava erikoismerkkejä.",
  "Attempt limit exceeded, please try after some time.": "Maksimimäärä yrityksiä saavutettu, yritä uudelleen hetken kuluttua.",
  "Username/client id combination not found.": "Käyttäjänimi tai salasana on virheellinen."
}
