import { MoovyTab } from '../../../components'
import { PlaceResponseDTO } from '../../../apis/places/dto'
import TabAdministrators from './tabAdministrators'
import TabSubPlaces from './tabSubPlaces'
import { UseQueryResult } from '@tanstack/react-query'
import { useIntl } from 'react-intl'

const CustomerTabs = ({
  customerId,
  enableTabPlaces,
  enableTabAdmins,
  queryPlaces
}: {
  customerId: string
  enableTabPlaces: boolean
  enableTabAdmins: boolean
  queryPlaces: UseQueryResult<PlaceResponseDTO[], any>
}) => {
  const intl = useIntl()

  const tabHeaders: string[] = [
    enableTabPlaces
      ? intl.formatMessage({
          id: 'page.customer.customerTabs.locations.header'
        })
      : '',
    enableTabAdmins
      ? intl.formatMessage({
          id: 'page.customer.customerTabs.admins.header'
        })
      : ''
  ].filter((item) => item)

  const tabPanels = [
    enableTabPlaces ? (
      <TabSubPlaces customerId={customerId} queryPlaces={queryPlaces} />
    ) : (
      ''
    ),
    enableTabAdmins ? <TabAdministrators customerId={customerId} /> : ''
  ].filter((item) => item)

  return (
    <MoovyTab
      tabKey="tabsRightHolderGroup"
      tabHeaders={tabHeaders}
      tabPanels={tabPanels}
    />
  )
}

export default CustomerTabs
