import {
  ModalSubPlace,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  TableSubPlaces
} from '../../../../components'

import { PlaceResponseDTO } from '../../../../apis/places/dto'
import { UseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'

const TabSubPlaces = ({
  customerId,
  queryPlaces
}: {
  customerId: string
  queryPlaces: UseQueryResult<PlaceResponseDTO[], any>
}) => {
  const [showSubPlaceModal, setShowSubPlaceModal] = useState(false)

  const places: PlaceResponseDTO[] = queryPlaces.data || []

  return (
    <MoovyContainer loading={queryPlaces.isFetching}>
      <MoovyContainerSubHeader />
      <MoovyContainerContent>
        <TableSubPlaces queryPlaces={queryPlaces} places={places} />
        <ModalSubPlace
          open={showSubPlaceModal}
          onSubmit={(values) => {
            queryPlaces.refetch()
            setShowSubPlaceModal(false)
          }}
          onCancel={() => setShowSubPlaceModal(false)}
          customerId={customerId}
        />
      </MoovyContainerContent>
    </MoovyContainer>
  )
}

export default TabSubPlaces
