import {
  setCatalogueIdState,
  setCustomerInitState,
  setCustomerState,
  setPlaceState,
  uiSelectors
} from '../redux/globalUI'
import { useDispatch, useSelector } from 'react-redux'

import { CatalogueResponseDTO } from '../apis/catalogues/dto'
import { CustomerResponseDTO } from '../apis/customers/dto'
import { LoginCustomerType } from './useAuth'
import { PlaceResponseDTO } from '../apis/places/dto'
import { setCustomerTypeState } from '../redux/auth'

export default function useCustomerData() {
  const dispatch = useDispatch()

  const setInitialized = (initialized: boolean) => {
    dispatch(setCustomerInitState(initialized))
  }

  const setCustomer = (
    customer?: CustomerResponseDTO,
    defineCustomType = false
  ) => {
    let customerToBeSet = { id: '', name: '' }
    let customerTypeToBeSet = ''

    if (!customer) {
      if (defineCustomType) {
        dispatch(setCustomerTypeState(customerTypeToBeSet))
      }
      dispatch(setCustomerState(customerToBeSet))
      return
    }

    customerToBeSet = { id: customer.id, name: customer.name }
    customerTypeToBeSet = customer.type

    if (defineCustomType) {
      dispatch(
        setCustomerTypeState(
          (customerTypeToBeSet as string)?.charAt(0) as LoginCustomerType
        )
      )
    }
    dispatch(setCustomerState(customerToBeSet))
  }

  const setCustomerFromArray = (
    customers: CustomerResponseDTO[],
    defineCustomType = false
  ) => {
    // At first, try to find landlord user
    let firstCustomer = customers.find(
      (item) => !item.parentId && item.type === 'LANDLORD'
    )
    // If not, use the first tenant
    if (!firstCustomer) {
      firstCustomer = customers.find((item) => item.type === 'TENANT')
    }

    setCustomer(firstCustomer, defineCustomType)
  }

  const place = useSelector(uiSelectors.getPlace)

  const setPlace = (
    newPlace?: PlaceResponseDTO | { id: string; name: string }
  ) => {
    let placeToBeSet = { id: '', name: '' }
    if (!newPlace) {
      if (!place || !place.id || !place.name) {
        dispatch(setPlaceState(placeToBeSet))
      }
      return
    }
    placeToBeSet = { id: newPlace.id, name: newPlace.name }
    if (
      place.id != placeToBeSet.id ||
      place.name.localeCompare(newPlace.name)
    ) {
      dispatch(setPlaceState(placeToBeSet))
    }
  }

  const setPlaceFromArray = (places?: PlaceResponseDTO[]) => {
    const firstPlace = places?.find((item) => !item.parentId)
    setPlace(firstPlace)
    setInitialized(true)
  }

  const setPlaceFromCatalogue = (catalogue: CatalogueResponseDTO) => {
    setPlace({ id: catalogue.place?.id, name: catalogue.place?.name })
    setCatalogueId(catalogue.id)
    setInitialized(true)
  }

  const setCatalogueId = (catalogueId: string) => {
    dispatch(setCatalogueIdState(catalogueId))
  }

  const catalogueId = useSelector(uiSelectors.getCatalogueId)
  const customer = useSelector(uiSelectors.getCustomer)
  const customerConfigured = !!place.id && !!customer.id
  const initialized = useSelector(uiSelectors.getInitialized)

  return {
    setPlace,
    setPlaceFromArray,
    setPlaceFromCatalogue,
    place,
    setCustomer,
    setCustomerFromArray,
    customer,
    setInitialized,
    initialized,
    customerConfigured,
    setCatalogueId,
    catalogueId
  }
}
