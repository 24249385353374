import {
  Divider,
  Grid,
  GridProps,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { MoovyAction, MoovyResource } from '../../app/permissions'

import MoovyRestricted from '../moovyRestricted'
import React from 'react'

export interface MoovyContainerSubHeaderProps {
  title?: React.ReactNode | string
  description?: React.ReactNode | string
  button?: React.ReactNode
  divider?: boolean
  props?: GridProps
  titleProps?: GridProps
  action?: MoovyAction
  resource?: MoovyResource
}

const MoovyContainerSubHeader: React.FC<MoovyContainerSubHeaderProps> = ({
  action,
  resource,
  title,
  description,
  button,
  divider,
  props,
  titleProps
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const Render = () => (
    <Grid>
      <Grid container direction="row" alignItems={'center'} p={2} {...props}>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems={description ? 'flex-end' : 'center'}
          gap={isMobile && description ? 1 : 0}
        >
          <Grid item xs={description ? 12 : 8} md={8} {...titleProps}>
            {/*TODO Antti: h3 style should be commonly defined as well as other header styles like h1 and h2*/}
            <h3 style={{ padding: 0, margin: 0, lineHeight: '120%' }}>
              {title}
            </h3>
          </Grid>
          {description && (
            <Grid item xs={12} md={8}>
              {description}
            </Grid>
          )}
          <Grid
            item
            md={4}
            xs={description ? 12 : 4}
            display="flex"
            container
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <>{button}</>
          </Grid>
        </Grid>
      </Grid>
      {divider && (
        <Grid item xs={12} pb={2}>
          <Divider />
        </Grid>
      )}
    </Grid>
  )

  return (
    <>
      {action && resource ? (
        <MoovyRestricted action={action} resource={resource}>
          <Render />
        </MoovyRestricted>
      ) : (
        <Render />
      )}
    </>
  )
}

export default MoovyContainerSubHeader
