import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableProps,
  TableRow,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { MoovyMutationError, MoovyPagination } from '..'
import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { MoovyPaginationProps } from '../moovyPagination'
import { MoovyPalette } from '../../styles/theme'
import { MoovyProgress } from '..'
import { SS_APP_BACKDROP_ANIMATION } from '../../redux/globalKeys'
import { UseQueryResult } from '@tanstack/react-query'
import useGlobalUI from '../../hooks/useGlobalUI'

interface MoovyTableProps extends TableProps {
  items: any[]
  emptyTextId: string
  errorTextId: string
  query: UseQueryResult<any, any> | undefined
  pagination?: MoovyPaginationProps
}

interface MoovyEmptyTableProps {
  emptyTextId: string
  errorTextId: string
  query: UseQueryResult<any, any> | undefined
  children?: React.ReactNode
}

const StyledPaper = styled(Paper)({
  boxShadow: 'none'
})

const EmptyTable = (props: MoovyEmptyTableProps) => {
  const { children, query, emptyTextId, errorTextId } = props
  return (
    <>
      {React.Children.map(children, (child, i) => {
        // Parse only the first content which is the header of the table
        if (i > 1) return
        return child
      })}
      <TableBody>
        <TableRow>
          <TableCell colSpan={99}>
            {query?.isError ? (
              <div style={{ textAlign: 'center' }}>
                <MoovyMutationError
                  query={query}
                  errorTitleKey={
                    errorTextId || 'shared.moovyTable.action.failed'
                  }
                />
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                {(emptyTextId && <FormattedMessage id={emptyTextId} />) || (
                  <FormattedMessage id="shared.moovyTable.action.emptySet" />
                )}
              </div>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  )
}

const ContentTable: React.FC<MoovyTableProps> = ({
  items,
  emptyTextId,
  errorTextId,
  query,
  pagination,
  ...tableProps
}) => {
  const [page, setPage] = useState(pagination?.page || 1)

  if (items?.length > 0 && tableProps.children) {
    return (
      <TableContainer component={StyledPaper}>
        <Table {...tableProps}>{tableProps.children}</Table>
        {pagination && pagination.total > pagination.limit && (
          <MoovyPagination
            page={page}
            total={pagination.total}
            limit={pagination.limit}
            onNextPage={(page) => {
              setPage(page)
              pagination.onNextPage(page)
            }}
          />
        )}
      </TableContainer>
    )
  } else {
    return (
      <Table {...tableProps}>
        <EmptyTable
          query={query}
          emptyTextId={emptyTextId}
          errorTextId={errorTextId}
        >
          {tableProps.children}
        </EmptyTable>
      </Table>
    )
  }
}

const LoadingTable = (props: MoovyTableProps) => {
  const [isProgressVisible, setIsProgressVisible] = useState(false)
  const [state] = useGlobalUI({
    key: SS_APP_BACKDROP_ANIMATION,
    initialValue: { value: '' }
  })

  useEffect(() => {
    setIsProgressVisible(!!state.value)
  }, [state.value])

  return (
    <React.Fragment>
      <Box
        sx={{
          opacity: 0.5,
          color: MoovyPalette.progressBlockContentColor,
          pointerEvents: 'none',
          position: 'relative'
        }}
      >
        <ContentTable {...props} />
        {!isProgressVisible && (
          <MoovyProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          />
        )}
      </Box>
    </React.Fragment>
  )
}

const MoovyTable = (props: MoovyTableProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // As a default, the table is hidden in mobile view.
  const isHidden = props.hidden || isMobile

  return (
    !isHidden &&
    (props.query?.isLoading || props.query?.isFetching ? (
      <LoadingTable {...props} />
    ) : (
      <ContentTable {...props} />
    ))
  )
}

export default MoovyTable
