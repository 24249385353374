import {
  OfferingCreateDTO,
  OfferingResponseDTO,
  OfferingUpdateDTO
} from './dto'

import { client } from '../moovyAxiosClient'
import { getBaseUrl } from '..'

const BASE_URL = 'offerings'

const getOfferingById = (id: string) => {
  return client
    .get<OfferingResponseDTO>(`${getBaseUrl(false)}/${BASE_URL}/${id}`)
    .then((response) => response.data)
}

const createOffering = (placeRef: string, offering: OfferingCreateDTO) => {
  return client.post<OfferingResponseDTO>(
    `${getBaseUrl(false)}/places/${placeRef}/offering`,
    offering
  )
}

const updateOffering = (
  ref: string,
  placeRef: string,
  offering: OfferingUpdateDTO
) => {
  return client.put<OfferingResponseDTO>(
    `${getBaseUrl(false)}/places/${placeRef}/${BASE_URL}/${ref}`,
    offering
  )
}

export const OfferingsAPI = {
  getOfferingById,
  createOffering,
  updateOffering
}
