import { Backdrop, Box, Grid, Skeleton, useTheme } from '@mui/material'
import { FC, ReactElement, useEffect } from 'react'

import { MoovyConstantDOMIds } from '../../utils/globalNames'
import MoovyErrorPage from '../moovyErrorPage'
import MoovyMutationError from '../moovyMutationError'
import { MoovyPalette } from '../../styles/theme'
import MoovyProgress from '../moovyProgress'
import { SS_APP_BACKDROP_ANIMATION } from '../../redux/globalKeys'
import { UseQueryResult } from '@tanstack/react-query'
import { navigateToRootIfNotFoundError } from '../../utils/errorNavigation'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useNavigate } from 'react-router-dom'

type MoovyQueryHandlerProps = {
  query: UseQueryResult<any, any>
  rootPageIfNotFoundError: boolean
  children: any
}

const BackdropAnimation = ({ show }: { show: boolean }) => {
  const theme = useTheme()
  const [state, setState] = useGlobalUI({
    key: SS_APP_BACKDROP_ANIMATION,
    initialValue: { value: '' }
  })

  useEffect(() => {
    if (show != !!state.value) {
      setState({ value: show ? 'true' : '' })
    }
  }, [show])

  return (
    <Box
      sx={{
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: theme.zIndex.drawer + 1,
        visibility: show ? 'visible' : 'hidden'
      }}
      id={MoovyConstantDOMIds.MOOVY_BACKDROP_ANIMATION}
    >
      <Backdrop
        sx={{
          backgroundColor: MoovyPalette.colorMainBackground
        }}
        open={show}
      >
        <MoovyProgress />
      </Backdrop>
    </Box>
  )
}

const ProgressAnimation = () => {
  const getSkeletonAnimations = () => {
    const skeletonCount = 8
    const skeletonItems = []
    for (let i = 0; i < skeletonCount; i++) {
      skeletonItems.push(<Skeleton key={i} animation="wave" />)
    }
    return skeletonItems.map((item) => item)
  }

  return (
    <Grid container gap={2} mt={2}>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height={60} />
      </Grid>
      <Grid item xs={12}>
        {getSkeletonAnimations()}
      </Grid>
    </Grid>
  )
}

const MoovyQueryHandler: FC<MoovyQueryHandlerProps> = ({
  query,
  rootPageIfNotFoundError,
  children
}): ReactElement => {
  const navigate = useNavigate()
  if (rootPageIfNotFoundError) {
    navigateToRootIfNotFoundError(navigate, query.error)
  }

  const noDataAnimation = query.isFetching && !query.data
  const dataAnimation = query.isFetching && !!query.data

  return (
    <>
      {query.isError ? (
        <MoovyErrorPage />
      ) : noDataAnimation ? (
        <ProgressAnimation />
      ) : (
        <>
          <BackdropAnimation show={dataAnimation} />
          <>{children}</>
        </>
      )}
      <MoovyMutationError
        query={query}
        errorTitleKey={'common.actions.get.error'}
      />
    </>
  )
}

export default MoovyQueryHandler
