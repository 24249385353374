import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CUSTOMER_GET_ALL
} from '../../apis/moovyQueryKeys'
import FormPlace, { DefaultPlaceValues } from './formPlace'
import { MoovyDialog, MoovyMutationError } from '../../components'
import { PlaceCreateDTO, PlaceResponseDTO } from '../../apis/places/dto'
import { useMutation, useQuery } from '@tanstack/react-query'

import CustomersAPI from '../../apis/customers/customersAPI'
import { FormattedMessage } from 'react-intl'
import { PlacesAPI } from '../../apis/places/placesAPI'
import useAuth from '../../hooks/useAuth'

type SubmitModalCallback = (response: PlaceResponseDTO) => void
type CancelModalCallback = () => void
export type ModalNewRightHolderProps = {
  open: boolean
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
}

const ModalAddPlace = (props: ModalNewRightHolderProps) => {
  const FORM_REFERENCE = 'formAddPlace'
  const { admin } = useAuth()

  const queryCustomers = useQuery({
    queryKey: [QUERY_CUSTOMER_GET_ALL],
    queryFn: () =>
      admin
        ? CustomersAPI.getLandlords(true, { limit: 10 })
        : CustomersAPI.getCustomers(false, { limit: 10 }),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const customers = queryCustomers.data?.data || []

  const createPlaceMutation = useMutation({
    mutationFn: (dto: PlaceCreateDTO) =>
      PlacesAPI.createPlaceWithDefaultOffering(dto),
    onSuccess: (response) => {
      const place: PlaceResponseDTO = response.data || {}
      props.onSubmit(place)
    }
  })

  const createPlace = (values: DefaultPlaceValues) => {
    const { name, streetAddress, postalCode, city, capacity, customerId } =
      values
    const dto: PlaceCreateDTO = {
      name,
      streetAddress,
      postalCode,
      city,
      customerId,
      capacity: Number.parseInt(capacity)
    }
    createPlaceMutation.mutate(dto)
  }

  const cancel = () => {
    createPlaceMutation.reset()
    props.onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        errorTitleKey="common.actions.add.error"
        modalTitle={<FormattedMessage id="page.places.modalAddPlace.title" />}
        submitButtonContent={
          <FormattedMessage id="page.places.modalAddPlace.button.add" />
        }
      >
        <MoovyMutationError
          mutation={createPlaceMutation}
          errorTitleKey="common.actions.add.error"
        />
        <FormPlace
          onSubmit={(values) => createPlace(values)}
          formReference={FORM_REFERENCE}
          customers={customers}
        />
      </MoovyDialog>
    </>
  )
}

export default ModalAddPlace
