import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React, { ReactNode, useState } from 'react'

import AppMenuTop from './appMenuTop'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import EmojiTransportationRounded from '@mui/icons-material/EmojiTransportationRounded'
import { FormattedMessage } from 'react-intl'
import LoginAPI from '../apis/authenticate/loginAPI'
import { MoovyPalette } from '../styles/theme'
import PhonelinkSetupRoundedIcon from '@mui/icons-material/PhonelinkSetupRounded'
import PlaceRounded from '@mui/icons-material/PlaceRounded'
import { appRoutes } from './permissions'
import useAuth from '../hooks/useAuth'
import useCustomerData from '../hooks/useCustomerData'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const StyledToolbar = styled(Toolbar)({
  background: MoovyPalette.colorMenuBackgroundGradient
})

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 16,
  paddingRight: '10px'
})

const drawerWidth = 280

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'mobile'
})<{
  open?: boolean
  mobile?: boolean
}>(({ theme, open, mobile }) => ({
  flexGrow: 1,
  paddingTop: 0,
  paddingBottom: 20,
  paddingLeft: 0,
  paddingRight: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: mobile ? 0 : `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  }),
  backgroundColor: MoovyPalette.colorMainBackground,
  minHeight: '100vh',
  maxHeight: '-webkit-fill-available'
}))

const AppMenu = (props: { children: ReactNode }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [showSideMenu, setShowSideMenu] = useState(mobile ? false : true)
  const { customer, place, customerConfigured, catalogueId } = useCustomerData()
  const { isPermission, isAuthenticated } = useAuth()

  const navigate = useNavigate()

  const handleOpenUserMenu = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorElUser(null)
  }

  const logoutMutation = useMutation({
    mutationFn: () => LoginAPI.logout(),
    onSuccess: () => {
      window.location.reload()
    }
  })

  const closeSideMenu = () => {
    mobile && setShowSideMenu(false)
    return true
  }

  const menuAdminItems = [
    {
      key: appRoutes.INVITATIONS,
      active: location.pathname === appRoutes.INVITATIONS,
      onClick: () => closeSideMenu() && navigate(appRoutes.INVITATIONS),
      value: <FormattedMessage id="menu.invitations" />,
      icon: <PhonelinkSetupRoundedIcon />,
      visible: isPermission('READ', 'LANDLORD_INVITATIONS')
    },
    {
      key: appRoutes.PLACES,
      active: location.pathname === appRoutes.PLACES,
      onClick: () => closeSideMenu() && navigate(appRoutes.PLACES),
      value: <FormattedMessage id="menu.allPlaces" />,
      icon: <PlaceRounded />,
      visible: isPermission('READ', 'ADMIN_PLACES')
    }
  ]

  const showAdminMenu = menuAdminItems.filter((item) => item.visible).length > 0

  const isValidBasePlusOtherRoute = (appRoute: string) => {
    // The route must be something else than the base route.
    // E.g. /places/0455e4b5-546c-4b21-949c-db7dc87c6c23
    return RegExp(`^${appRoute}/.`).test(location.pathname)
  }

  const menuPlaceItems = [
    {
      key: appRoutes.PLACES,
      active:
        isValidBasePlusOtherRoute(appRoutes.PLACES) ||
        isValidBasePlusOtherRoute(appRoutes.CATALOGUES) ||
        isValidBasePlusOtherRoute(appRoutes.RIGHT_HOLDERS) ||
        isValidBasePlusOtherRoute(appRoutes.OFFERINGS),
      onClick: () => {
        closeSideMenu()
        if (isPermission('READ', 'PLACES')) {
          navigate(`${appRoutes.PLACES}/${place.id}`)
        } else {
          navigate(`${appRoutes.CATALOGUES}/${catalogueId}`)
        }
      },
      value: <FormattedMessage id="menu.places" />,
      icon: <PlaceRounded />,
      visible:
        isPermission('READ', 'PLACES') || isPermission('READ', 'CATALOGUES')
    }
  ]

  const menuCustomerItems = [
    {
      key: appRoutes.CUSTOMERS,
      active: location.pathname == `${appRoutes.CUSTOMERS}/${customer.id}`,
      onClick: () =>
        closeSideMenu() && navigate(`${appRoutes.CUSTOMERS}/${customer.id}`),
      value: <FormattedMessage id="menu.customer" />,
      icon: <EmojiTransportationRounded />,
      visible: true
    }
  ]

  const menuReportItems = [
    {
      key: appRoutes.REPORTS,
      active: location.pathname === appRoutes.REPORTS,
      onClick: () => closeSideMenu() && navigate(appRoutes.REPORTS),
      value: <FormattedMessage id="menu.reports" />,
      icon: <AssessmentOutlinedIcon />,
      visible:
        isPermission('READ', 'CATALOGUE_REPORTS') ||
        isPermission('READ', 'RIGHT_REPORTS')
    }
  ]

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          elevation={0}
        >
          <StyledToolbar>
            <AppMenuTop
              anchorElUser={anchorElUser}
              onOpenUserMenu={handleOpenUserMenu}
              onCloseMenu={handleCloseMenu}
              onLogout={() => logoutMutation.mutate()}
              onClickHamburgerMenu={() => setShowSideMenu(!showSideMenu)}
              onClickLogo={() => closeSideMenu() && navigate(appRoutes.ROOT)}
              appMenuOpen={showSideMenu}
            />
          </StyledToolbar>
        </AppBar>
        {isAuthenticated && customerConfigured && (
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                border: 'none'
              }
            }}
            variant={mobile ? undefined : 'persistent'}
            anchor="left"
            open={showSideMenu}
            onClose={() => setShowSideMenu(false)}
          >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
              {showAdminMenu && (
                <>
                  <List
                    subheader={
                      <ListSubheader>
                        <FormattedMessage id="menu.subHeader.moovyAdmin" />
                      </ListSubheader>
                    }
                  >
                    {menuAdminItems
                      .filter((item) => item.visible)
                      .map((item) => (
                        <ListItem key={item.key} disablePadding>
                          <ListItemButton
                            onClick={item.onClick}
                            selected={item.active}
                          >
                            <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                            <ListItemText primary={item.value} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                  <Divider />
                </>
              )}
              <List subheader={<ListSubheader>{place.name}</ListSubheader>}>
                {menuPlaceItems
                  .filter((item) => item.visible)
                  .map((item) => (
                    <ListItem key={item.key} disablePadding>
                      <ListItemButton
                        onClick={item.onClick}
                        selected={item.active}
                      >
                        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                        <ListItemText primary={item.value} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
              <Divider />
              <List subheader={<ListSubheader>{customer.name}</ListSubheader>}>
                {menuCustomerItems
                  .filter((item) => item.visible)
                  .map((item) => (
                    <ListItem key={item.key} disablePadding>
                      <ListItemButton
                        onClick={item.onClick}
                        selected={item.active}
                      >
                        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                        <ListItemText primary={item.value} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                {menuReportItems
                  .filter((item) => item.visible)
                  .map((item) => (
                    <ListItem key={item.key} disablePadding>
                      <ListItemButton
                        onClick={item.onClick}
                        selected={item.active}
                      >
                        <StyledListItemIcon>{item.icon}</StyledListItemIcon>
                        <ListItemText primary={item.value} />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            </Box>
          </Drawer>
        )}
        <Main open={showSideMenu} mobile={mobile}>
          <Container>
            <Toolbar />
            <>{props.children}</>
          </Container>
        </Main>
      </Box>
    </React.Fragment>
  )
}

export default AppMenu
