import {
  Autocomplete,
  AutocompleteProps,
  Box,
  BoxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormLabelProps,
  IconButton,
  InputLabel,
  FormLabel as MUIFormLabel,
  Radio,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  TextField,
  Tooltip,
  styled
} from '@mui/material'
import { FC, ReactElement, ReactNode, useState } from 'react'

import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import { MoovyFormRestrictions } from '../../utils/validator'
import { TextFieldProps } from '@mui/material/TextField'

const FormContainer: FC<FormControlProps> = (props): ReactElement => {
  return (
    <FormControl fullWidth>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mb={1}
        mt={1}
        gap={2}
      >
        {props.children}
      </Box>
    </FormControl>
  )
}

interface MoovyFormTextFieldProps extends Omit<TextFieldProps, 'errorText'> {
  errorText?: string
}

interface MoovyFormAutocompleteProps
  extends AutocompleteProps<any, any, any, any, any> {
  errorText?: string
}

const StyledTextField = styled(TextField)<MoovyFormTextFieldProps>(
  ({ error, theme }) => ({
    color: error ? theme.palette.error.main : 'inherit'
  })
)
const FormTextField: FC<MoovyFormTextFieldProps> = ({
  errorText,
  ...textFieldProps
}): ReactElement => {
  let inputProps = undefined
  if (textFieldProps?.inputProps) {
    inputProps = textFieldProps.inputProps?.maxLength
      ? textFieldProps.inputProps
      : {
          ...textFieldProps.inputProps,
          maxLength: MoovyFormRestrictions.INPUT_MAX_CHARS
        }
  } else {
    inputProps = { maxLength: MoovyFormRestrictions.INPUT_MAX_CHARS }
  }

  return (
    <StyledTextField
      {...textFieldProps}
      inputProps={inputProps}
      helperText={errorText ? errorText : ''}
      sx={{ flex: 1 }}
      /* TODO Antti: This is an example to use info button inside input field.
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <InfoRoundedIcon />
          </InputAdornment>
        )
      }}*/
    />
  )
}
const FormAutoCompleteTextField: FC<MoovyFormAutocompleteProps> = (
  props
): ReactElement => {
  return <Autocomplete {...props} />
}
const FormRadio: FC<FormControlLabelProps> = (props): ReactElement => {
  return <FormControlLabel {...props} control={<Radio />} />
}

interface MoovyFormLabelProps extends FormLabelProps {
  info?: React.ReactNode | string
}
const FormLabel: FC<MoovyFormLabelProps> = (props): ReactElement => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <MUIFormLabel {...props}>
      {props.info ? (
        <Box display="flex" alignItems="center">
          <div>{props.children}</div>
          <Tooltip
            title={props.info}
            open={tooltipOpen}
            onClose={() => setTooltipOpen(false)}
            onClick={() => setTooltipOpen(true)}
            placement="top"
            arrow
          >
            <IconButton
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            >
              <InfoRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        props.children
      )}
    </MUIFormLabel>
  )
}

interface MoovyFormSwitchProps extends Omit<SwitchProps, 'label'> {
  label: string
}
const FormSwitch: FC<MoovyFormSwitchProps> = (props): ReactElement => {
  return (
    <FormControlLabel label={props.label} control={<Switch {...props} />} />
  )
}

const FormBoxRow: FC<BoxProps> = (props): ReactElement => {
  const { children, gap, flexDirection } = props
  return (
    <Box
      display="flex"
      gap={gap ? gap : 2}
      flex={1}
      flexDirection={flexDirection ? { xs: 'column', md: 'row' } : undefined}
    >
      {children}
    </Box>
  )
}

const FormSelect: FC<SelectProps> = (props): ReactElement => {
  const { children, ...rest } = props
  return (
    <FormControl fullWidth>
      <InputLabel id={rest.labelId}>{rest.label}</InputLabel>
      <Select {...rest}>{children}</Select>
    </FormControl>
  )
}

interface MoovyFormTitleProps {
  title: ReactNode | string
}
const FormTitle: FC<MoovyFormTitleProps> = (props): ReactNode => (
  <FormBoxRow>
    <h4 style={{ margin: 0 }}>{props.title}</h4>
  </FormBoxRow>
)

export default {
  FormAutoCompleteTextField,
  FormTextField,
  FormContainer,
  FormRadio,
  FormSwitch,
  FormLabel,
  FormBoxRow,
  FormSelect,
  FormTitle
}
