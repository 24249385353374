import { AwsJwtPayload } from '../apis/authenticate/loginAPI'

export function assertExhaustive(
  value: never,
  message: string = 'Reached unexpected case in exhaustive switch'
): never {
  throw new Error(message)
}

export const hasAnyValues = (obj: any) => {
  return (
    obj &&
    Object.keys(obj).some((key) => obj[key as keyof typeof obj] !== undefined)
  )
}

export const getValueIfNoMatch = <T extends string | number | undefined | null>(
  value: T,
  original: T
): string | undefined => {
  if (!value) return undefined
  if (!original) return value?.toString()

  if (typeof value === 'number' && typeof original === 'number') {
    return value != original ? value.toString() : undefined
  }

  if (typeof value === 'string' && typeof original === 'string') {
    return original.localeCompare(value.trim()) != 0 ? value : undefined
  }

  return undefined
}

export const getShortAvatarName = (avatarName: string) => {
  if (!avatarName) {
    return ''
  }

  const spaceIndex = avatarName.lastIndexOf(' ')
  const secondIndex =
    spaceIndex === -1 ? avatarName.lastIndexOf('-') : spaceIndex
  const firstChar = avatarName.substring(0, 1)
  const secondChar =
    avatarName.length > 1
      ? avatarName.substring(
          secondIndex === -1 ? 1 : secondIndex + 1,
          secondIndex === -1 ? 2 : secondIndex + 2
        )
      : ''

  return `${firstChar}${secondChar}`.toUpperCase()
}

export const getLoggedName = (jwtToken: AwsJwtPayload) => {
  if (!jwtToken) {
    return ''
  }

  if (!jwtToken?.given_name && !jwtToken?.family_name) {
    return jwtToken.name || ''
  } else {
    return jwtToken?.given_name
      ? `${jwtToken?.given_name} ${jwtToken?.family_name || ''}`
      : `${jwtToken?.family_name || ''}`
  }
}

export const getJoinedValue = (
  first?: string,
  second?: string,
  separator: string = ' '
) => {
  if (first) {
    return second ? `${first}${separator}${second}` : first
  } else {
    return second ? second : ''
  }
}

export const getAmountAndPercentage = (
  totalCount: number,
  count: number,
  showTotal?: boolean
) => {
  const slashPlusTotal = showTotal ? ` / ${totalCount} ` : ' '
  return `${count}${slashPlusTotal}${
    totalCount > 0 ? `(${Math.round((count / totalCount) * 100)} %)` : '(0 %)'
  }`
}
