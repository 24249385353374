import React, { useState } from 'react'

import { CatalogueResponseDTO } from '../../../apis/catalogues/dto'
import { InvitationsTenant } from '../../../components'

export interface TabInvitationsProps {
  catalogue: CatalogueResponseDTO | undefined
}

const TabInvitations: React.FC<TabInvitationsProps> = ({ catalogue }) => {
  // The component is only intended to be shown tenant invitations
  if (catalogue?.customer.type !== 'TENANT') {
    return ''
  }

  const tenantId = catalogue?.customer.id || ''
  const landlordId = catalogue?.customer.parentId || ''
  const catalogueId = catalogue?.id || ''

  return (
    <InvitationsTenant
      tenantId={tenantId}
      landlordId={landlordId}
      catalogueId={catalogueId}
    />
  )
}

export default TabInvitations
