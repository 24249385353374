import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { List, ListItemText } from '@mui/material'
import MoovyCardMenu, { MoovyCardMenuItem } from './moovyCardMenu'
import React, { useEffect, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoovyLink from '../moovyLink'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { getShortAvatarName } from '../../utils/utils'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

export type MoovyCardProps = {
  key: React.Key
  avatarName?: string
  cardTitle: React.ReactElement | string
  cardSecondaryTitle: React.ReactElement | string
  cardContentList: string[] | React.ReactNode[]
  cardExpandContentList?: string[]
  cardMenuActions?: MoovyCardMenuItem[]
  onCardClicked?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MoovyCard = (props: MoovyCardProps) => {
  const [expanded, setExpanded] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const handleToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setOpen((prevOpen) => !prevOpen)
  }

  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleExpandClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setExpanded(!expanded)
  }

  const expandList = props.cardExpandContentList
  const avatarName =
    props.avatarName ||
    (typeof props.cardTitle === 'string' ? props.cardTitle : '')
  const shortAvatarName = getShortAvatarName(avatarName)

  return (
    <Card
      key={props.key}
      sx={{ minWidth: 200 }}
      onClick={(event) => props.onCardClicked && props.onCardClicked(event)}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: grey[500] }} aria-label={avatarName}>
            {shortAvatarName}
          </Avatar>
        }
        action={
          props.cardMenuActions ? (
            <IconButton
              aria-label="settings"
              ref={anchorRef}
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MoreVertIcon />
            </IconButton>
          ) : undefined
        }
        title={
          <MoovyLink
            style={{
              textAlign: 'left'
            }}
          >
            {props.cardTitle}
          </MoovyLink>
        }
        subheader={props.cardSecondaryTitle}
      />
      <CardContent
        style={{
          paddingBottom:
            props.cardContentList.length > 0 || expandList ? 12 : 0,
          paddingTop: 0
        }}
      >
        <List style={{ paddingBottom: 0, paddingTop: 0 }}>
          {props.cardContentList.map((item, index) => (
            <ListItemText
              key={index}
              secondary={item}
              style={{ wordBreak: 'break-all' }}
            />
          ))}
        </List>
      </CardContent>
      {expandList && (
        <>
          <CardActions disableSpacing>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent style={{ paddingBottom: 0, paddingTop: 0 }}>
              <List>
                {expandList.map((item, index) => (
                  <ListItemText
                    key={index}
                    secondary={item}
                    style={{ wordBreak: 'break-all' }}
                  />
                ))}
              </List>
            </CardContent>
          </Collapse>
        </>
      )}
      <MoovyCardMenu
        open={open}
        setOpen={setOpen}
        anchorRef={anchorRef}
        menuItems={props.cardMenuActions}
      />
    </Card>
  )
}

export default MoovyCard
