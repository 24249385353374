import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyTab
} from '../../components'

import { CatalogueResponseDTO } from '../../apis/catalogues/dto'
import TabCatalogue from './tabCatalogue'
import TabInvitations from './tabInvitations'
import useAuth from '../../hooks/useAuth'
import { useIntl } from 'react-intl'

export type CatalogueTabsProps = {
  catalogue: CatalogueResponseDTO | undefined
}
const CatalogueTabs = ({ catalogue }: CatalogueTabsProps) => {
  const intl = useIntl()
  const { admin, isPermission } = useAuth()

  // Invitations tab is only shown for tenant customer type and logged users who is not admin
  const isVisible =
    catalogue?.customer.type === 'TENANT' &&
    !admin &&
    isPermission('READ', 'TENANT_INVITATIONS')

  let tabHeaders: string[] = [
    intl.formatMessage({
      id: 'page.places.catalogueTabs.catalogue.header'
    })
  ]

  isVisible &&
    tabHeaders.push(
      intl.formatMessage({
        id: 'page.places.catalogueTabs.invitations.header'
      })
    )

  let tabPanels = [<TabCatalogue catalogue={catalogue} />]

  isVisible && tabPanels.push(<TabInvitations catalogue={catalogue} />)

  return (
    <MoovyContainer loading={false}>
      <MoovyContainerContent>
        <MoovyTab
          tabKey="tabsRightHolderGroup"
          tabHeaders={tabHeaders}
          tabPanels={tabPanels}
        />
      </MoovyContainerContent>
    </MoovyContainer>
  )
}

export default CatalogueTabs
