import { API_ROOT_URL } from '..'
import { UserDTO } from './dto'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/users`

const getUsers = (queryParams: { customerId: string } | undefined) => {
  return client
    .get<UserDTO[]>(
      BASE_URL,
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

const removeUser = (userId: string) => {
  return client.delete<string>(`${BASE_URL}/${userId}`)
}

const UsersAPI = {
  getUsers,
  removeUser
}

export default UsersAPI
