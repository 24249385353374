import { FormattedMessage, useIntl } from 'react-intl'

import { CatalogueResponseDTO } from '../../../apis/catalogues/dto'
import { Divider } from '@mui/material'
import { MoovyListLabelValue } from '../../../components'
import React from 'react'
import { getAmountAndPercentage } from '../../../utils/utils'

export type CatalogueStatusInfoProps = {
  catalogue?: CatalogueResponseDTO
  whitelistOffering: boolean
}

const CatalogueStatusInfo = ({
  catalogue,
  whitelistOffering
}: CatalogueStatusInfoProps) => {
  const intl = useIntl()

  const grantAmount = catalogue?.amount || 0
  const grantedAmount = catalogue?.activeRights || 0

  const data = []
  !whitelistOffering &&
    data.push({
      label: intl.formatMessage({
        id: 'page.catalogues.tabCatalogue.catalogueStatusInfo.grantAmount'
      }),
      value: grantAmount
    })
  data.push({
    label: intl.formatMessage({
      id: 'page.catalogues.tabCatalogue.catalogueStatusInfo.grantedAmount'
    }),
    value: whitelistOffering
      ? grantedAmount
      : getAmountAndPercentage(grantAmount, grantedAmount)
  })

  return (
    <>
      <h3>
        <FormattedMessage id="page.catalogues.tabCatalogue.catalogueStatusInfo.title" />
      </h3>
      <Divider style={{ marginBottom: '20px' }} />
      <MoovyListLabelValue data={data} splitByMiddle />
    </>
  )
}

export default CatalogueStatusInfo
