import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { CustomerResponseDTO } from '../../apis/customers/dto'
import { Formik } from 'formik'
import { MenuItem } from '@mui/material'
import { MoovyForm } from '../../components'
import useCustomerData from '../../hooks/useCustomerData'

export interface DefaultPlaceValues {
  name: string
  streetAddress: string
  postalCode: string
  city: string
  capacity: string
  customerId: string
}

type SubmitFormCallback = (values: DefaultPlaceValues) => void
export type FormPlaceProps = {
  showTitle?: boolean
  onSubmit: SubmitFormCallback
  formReference: string
  defaultValues?: DefaultPlaceValues
  editView?: boolean
  customers: CustomerResponseDTO[]
}

const FormPlace = (props: FormPlaceProps) => {
  const intl = useIntl()
  const { customer } = useCustomerData()

  const customerOptions = props.customers.map((item) => {
    return { key: item.id, value: item.name }
  })

  const getFormDefaultValues = () => {
    return {
      name: props.defaultValues?.name || '',
      streetAddress: props.defaultValues?.streetAddress || '',
      postalCode: props.defaultValues?.postalCode || '',
      city: props.defaultValues?.city || '',
      capacity: props.defaultValues?.capacity || '',
      customerId: props.defaultValues?.customerId || customer.id
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues, setDefaultValues] = useState<DefaultPlaceValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [
    props.defaultValues?.capacity,
    props.defaultValues?.city,
    props.defaultValues?.name,
    props.defaultValues?.postalCode,
    props.defaultValues?.streetAddress,
    props.defaultValues?.customerId
  ])

  const placeSchema = () => {
    return yup.object().shape({
      customerId: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),

      name: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      streetAddress: yup
        .string()
        .trim()
        .required(
          intl.formatMessage({
            id: 'common.form.errors.empty'
          })
        ),
      postalCode: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      city: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      capacity: yup
        .number()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' }))
    })
  }

  const onSubmitFormValues = (values: DefaultPlaceValues) => {
    const { name, streetAddress, postalCode, city, capacity, customerId } =
      values
    props.onSubmit({
      name: name.trim(),
      streetAddress: streetAddress.trim(),
      postalCode: postalCode.trim(),
      city: city.trim(),
      capacity,
      customerId
    })
  }

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={placeSchema()}
        enableReinitialize={true}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              {props.showTitle && (
                <MoovyForm.FormTitle
                  title={
                    <FormattedMessage id="page.places.formPlace.contentTitle" />
                  }
                />
              )}
              {customerOptions.length > 1 && (
                <MoovyForm.FormBoxRow flexDirection={'column'}>
                  <MoovyForm.FormSelect
                    name="customerId"
                    label={intl.formatMessage({
                      id: 'page.places.formPlace.label.customer'
                    })}
                    value={values.customerId}
                    onChange={handleChange}
                    error={!!errors.customerId}
                  >
                    {customerOptions.map((item) => {
                      return (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      )
                    })}
                  </MoovyForm.FormSelect>
                </MoovyForm.FormBoxRow>
              )}
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormTextField
                  name="name"
                  data-testid="name"
                  value={values.name}
                  autoFocus
                  label={intl.formatMessage({
                    id: 'page.places.formPlace.label.name'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.places.formPlace.label.name'
                  })}
                  onChange={handleChange}
                  error={!!errors.name}
                  errorText={errors.name}
                />
                <MoovyForm.FormTextField
                  name="streetAddress"
                  data-testid="streetAddress"
                  value={values.streetAddress}
                  label={intl.formatMessage({
                    id: 'page.places.formPlace.label.streetAddress'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.places.formPlace.label.streetAddress'
                  })}
                  onChange={handleChange}
                  error={!!errors.streetAddress}
                  errorText={errors.streetAddress}
                />
              </MoovyForm.FormBoxRow>
              <MoovyForm.FormBoxRow flexDirection={'column'}>
                <MoovyForm.FormBoxRow flexDirection={'column'}>
                  <MoovyForm.FormTextField
                    name="postalCode"
                    data-testid="postalCode"
                    value={values.postalCode}
                    label={intl.formatMessage({
                      id: 'page.places.formPlace.label.postalCode'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'page.places.formPlace.label.postalCode'
                    })}
                    onChange={handleChange}
                    error={!!errors.postalCode}
                    errorText={errors.postalCode}
                  />
                  <MoovyForm.FormTextField
                    name="city"
                    data-testid="city"
                    value={values.city}
                    label={intl.formatMessage({
                      id: 'page.places.formPlace.label.city'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'page.places.formPlace.label.city'
                    })}
                    onChange={handleChange}
                    error={!!errors.city}
                    errorText={errors.city}
                  />
                </MoovyForm.FormBoxRow>
                <MoovyForm.FormBoxRow>
                  <MoovyForm.FormTextField
                    name="capacity"
                    data-testid="capacity"
                    value={values.capacity || ''}
                    type="number"
                    label={intl.formatMessage({
                      id: 'page.places.formPlace.label.capacity'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'page.places.formPlace.label.capacity'
                    })}
                    onChange={handleChange}
                    error={!!errors.capacity}
                    errorText={errors.capacity}
                    disabled={props?.editView}
                  />
                </MoovyForm.FormBoxRow>
              </MoovyForm.FormBoxRow>
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormPlace
