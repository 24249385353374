import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_RIGHT_HOLDER_GET_BY_REF
} from '../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Link, List, ListItem, ListItemText } from '@mui/material'
import ModalEditRightHolder, { TaggedRightHolder } from './modalEditRightHolder'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyQueryHandler,
  MoovyRestricted,
  MoovyScrollToTop
} from '../../../components'
import React, { ReactNode, useState } from 'react'
import {
  RightHolderIndividualDTO,
  RightHolderType,
  RightHolderVehicleDTO,
  getRightHolderName
} from '../../../apis/rightHolders/dto'
import { useLocation, useParams } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import MailRoundedIcon from '@mui/icons-material/MailRounded'
import { MoovyContainerBreadcrumbsProps } from '../../../components/moovyContainer/moovyContainerHeader'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import RightHoldersAPI from '../../../apis/rightHolders/rightHoldersAPI'
import RightsAndVehicles from './rightsAndVehicles'
import { appRoutes } from '../../../app/permissions'
import useAuth from '../../../hooks/useAuth'

export type VehicleDataType = {
  id: string
  licensePlate: string
}

const RightHolder = () => {
  const intl = useIntl()
  let { rightHolderRef } = useParams()
  const queryClient = useQueryClient()
  const { tenant } = useAuth()

  const { state } = useLocation()

  const [showModalEditRightHolder, setShowModalEditRightHolder] =
    useState(false)

  const queryRightHolder = useQuery({
    queryKey: [QUERY_RIGHT_HOLDER_GET_BY_REF, rightHolderRef],
    queryFn: () => RightHoldersAPI.getIndividualByRef(rightHolderRef || ''),
    enabled: !!rightHolderRef,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const rightHolderData = queryRightHolder.data

  let rightHolder: {
    id: string
    name: string
    email?: string
    phone?: string
    vehicles: RightHolderVehicleDTO[]
    type?: RightHolderType
  } = {
    id: '',
    name: '-',
    email: undefined,
    phone: undefined,
    vehicles: [],
    type: rightHolderData?.type
  }

  let dataIndividual =
    rightHolderData?.type === 'individual'
      ? (rightHolderData as RightHolderIndividualDTO)
      : undefined
  let dataVehicle =
    rightHolderData?.type === 'vehicle'
      ? (rightHolderData as RightHolderVehicleDTO)
      : undefined

  if (dataIndividual) {
    const id = dataIndividual.id
    const name = getRightHolderName(dataIndividual)
    const email = dataIndividual.email
    const phone = dataIndividual.phoneNumber
    const vehicles = dataIndividual.vehicles
    rightHolder = { id, name, email, phone, vehicles }
  } else if (dataVehicle) {
    const id = dataVehicle.id
    const name = getRightHolderName(dataVehicle)
    const vehicles =
      rightHolderData?.type === 'vehicle'
        ? [rightHolderData as RightHolderVehicleDTO]
        : []
    rightHolder = { id, name, vehicles }
  }

  const {
    catalogueId = undefined,
    offeringName = undefined,
    placeId = undefined,
    placeName = undefined
  } = state || {}
  const customBreadcrumbs: MoovyContainerBreadcrumbsProps | undefined =
    catalogueId && offeringName && placeId && placeName
      ? {
          lastBreadcrumb: rightHolder.name,
          customBreadcrumbs: tenant
            ? [{ route: `${appRoutes.PLACES}/${placeId}`, name: placeName }]
            : [
                { route: `${appRoutes.PLACES}/${placeId}`, name: placeName },
                {
                  route: `${appRoutes.CATALOGUES}/${catalogueId}`,
                  name: offeringName
                }
              ]
        }
      : undefined

  const RightHolderListItem = ({ children }: { children: ReactNode }) => {
    return (
      <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
        {children}
      </ListItem>
    )
  }

  const RightHolderListItemText = ({ children }: { children: ReactNode }) => {
    return <ListItemText sx={{ pl: '10px' }}>{children}</ListItemText>
  }

  const showEditButton = rightHolderData?.type === 'individual'

  return (
    <MoovyQueryHandler query={queryRightHolder} rootPageIfNotFoundError>
      <MoovyScrollToTop />
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerHeader breadcrumbs={customBreadcrumbs}>
              {rightHolder.name}
            </MoovyContainerHeader>
            <MoovyContainerSubHeader
              title={
                <FormattedMessage id="page.rightHolders.rightHolder.title" />
              }
              button={
                <MoovyRestricted
                  action="EDIT"
                  resource="RIGHT_HOLDERS"
                  visibility={showEditButton}
                >
                  <MoovyButton
                    startIcon={<EditRoundedIcon />}
                    onClick={() => setShowModalEditRightHolder(true)}
                    showAsIconButton
                    aria-label={intl.formatMessage({
                      id: 'page.rightHolders.rightHolder.button.edit'
                    })}
                  />
                </MoovyRestricted>
              }
              divider
            />
            <MoovyContainerContent>
              <List>
                <RightHolderListItem>
                  <PersonRoundedIcon />
                  <RightHolderListItemText>
                    {rightHolder.name}
                  </RightHolderListItemText>
                </RightHolderListItem>
                {rightHolder.email && (
                  <RightHolderListItem>
                    <MailRoundedIcon />
                    <RightHolderListItemText>
                      <Link href={`mailto:${rightHolder.email}`}>
                        {rightHolder.email}
                      </Link>
                    </RightHolderListItemText>
                  </RightHolderListItem>
                )}
                {rightHolder.phone && (
                  <RightHolderListItem>
                    <PhoneRoundedIcon />
                    <RightHolderListItemText>
                      <Link href={`tel:${rightHolder.phone}`}>
                        {rightHolder.phone}
                      </Link>
                    </RightHolderListItemText>
                  </RightHolderListItem>
                )}
              </List>
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerContent>
              <RightsAndVehicles
                queryRightHolder={queryRightHolder}
                individualRightHolder={dataIndividual}
                vehicles={rightHolder.vehicles}
              />
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
        <ModalEditRightHolder
          open={showModalEditRightHolder}
          rightHolderRef={rightHolderRef || ''}
          rightHolder={
            {
              kind: rightHolderData?.type,
              dto: rightHolderData
            } as TaggedRightHolder
          }
          onSubmit={(values) => {
            setShowModalEditRightHolder(false)
            queryClient.invalidateQueries({
              queryKey: [QUERY_RIGHT_HOLDER_GET_BY_REF, rightHolderRef]
            })
          }}
          onCancel={() => setShowModalEditRightHolder(false)}
        />
      </Grid>
    </MoovyQueryHandler>
  )
}

export default RightHolder
