import { FormattedMessage } from 'react-intl'
import { Box } from '@mui/material'
import { MoovyPalette } from '../../styles/theme'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface MoovyParkingEnforcementStatusProps {
  parkingEnforcement: boolean
}

const MoovyParkingEnforcementStatus: React.FC<
  MoovyParkingEnforcementStatusProps
> = ({ parkingEnforcement }) => {
  return parkingEnforcement ? (
    <Box display="flex" alignItems="center">
      <CheckCircleOutlineIcon sx={{ mr: 1 }} color={'success'} />{' '}
      <FormattedMessage id="moovyParkingEnforcementStatus.yes" />
    </Box>
  ) : (
    <span style={{ color: MoovyPalette.commonRed }}>
      <FormattedMessage id={'moovyParkingEnforcementStatus.no'} />
    </span>
  )
}

export default MoovyParkingEnforcementStatus
