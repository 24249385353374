import { JwtPayload, jwtDecode } from 'jwt-decode'
import { SS_CUSTOMER_TYPE, SS_TOKEN } from './keysStorage'
import { getLoggedName, getShortAvatarName } from '../utils/utils'

import { AwsJwtPayload } from '../apis/authenticate/loginAPI'
import { LoginCustomerType } from '../hooks/useAuth'
import { createSlice } from '@reduxjs/toolkit'

const stateKey = 'moovyAuth'

const initialState = {
  token: sessionStorage.getItem(SS_TOKEN) || '',
  roles: [],
  userGroups: undefined,
  customerType: sessionStorage.getItem(SS_CUSTOMER_TYPE) || ''
}

const slice = createSlice({
  name: stateKey,
  initialState,
  reducers: {
    setAuthentication(state, action) {
      sessionStorage.setItem(SS_TOKEN, action.payload.token)
      state.token = action.payload.token
      state.userGroups = action.payload.userGroups || ''
    },
    setToken(state, action) {
      const token = action.payload
      sessionStorage.setItem(SS_TOKEN, token)
      state.token = token
    },
    setCustomerType(state, action) {
      const customerType = action.payload
      sessionStorage.setItem(SS_CUSTOMER_TYPE, customerType)
      state.customerType = customerType
    }
  }
})

export default slice.reducer

export const { setAuthentication, setToken, setCustomerType } = slice.actions

export const setAuthenticationState = (payload: any) => {
  return setAuthentication(payload)
}

export const setTokenState = (payload: any) => {
  return setToken(payload)
}

export const setCustomerTypeState = (payload: any) => {
  return setCustomerType(payload)
}

export const authSelectors = {
  getShortUsername: (state: any) => {
    const decodedToken =
      state[stateKey]?.token && jwtDecode<AwsJwtPayload>(state[stateKey]?.token)
    return getShortAvatarName(getLoggedName(decodedToken))
  },
  getToken: (state: any) => state[stateKey]?.token,
  getCustomerType: (state: any) =>
    state[stateKey]?.customerType as LoginCustomerType,
  getUserGroups: (state: any) => state[stateKey]?.userGroups,
  getRoles: (state: any) => state[stateKey]?.roles,
  isAuthenticated: (state: any) => {
    return !!state[stateKey]?.token
  }
}
