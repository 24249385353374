import {
  CreateAdditionalLandlordInvitationDTO,
  CreateLandlordInvitationDTO,
  CreateTenantWithCatalogueInvitationDTO,
  InvitationResponseDTO
} from '../../apis/invitations/dto'
import FormInvitation, { DefaultInvitationValues } from './formInvitation'
import { MoovyDialog, MoovyMutationError } from '..'
import { assertExhaustive, getJoinedValue } from '../../utils/utils'

import { AddingInvitationProps } from './invitations'
import { FormattedMessage } from 'react-intl'
import InvitationsAPI from '../../apis/invitations/invitationsAPI'
import useAuth from '../../hooks/useAuth'
import { useMutation } from '@tanstack/react-query'

type SubmitModalCallback = (response: InvitationResponseDTO) => void
export type ModalAddInvitationProps = {
  open: boolean
  addingInvitationParams: AddingInvitationProps
  onSubmit: SubmitModalCallback
  onCancel: () => void
}

const ModalAddInvitation = ({
  open,
  addingInvitationParams,
  onSubmit,
  onCancel
}: ModalAddInvitationProps) => {
  const FORM_REFERENCE = 'formAddInvitation'
  const { admin } = useAuth()

  const createLandlordInvitationMutation = useMutation({
    mutationFn: (dto: CreateLandlordInvitationDTO) =>
      InvitationsAPI.createLandlordInvitation(admin, dto),
    onSuccess: (response) => {
      const invitation: InvitationResponseDTO = response.data || {}
      onSubmit(invitation)
    }
  })

  const createTenantInvitationMutation = useMutation({
    mutationFn: (dto: CreateTenantWithCatalogueInvitationDTO) =>
      InvitationsAPI.createTenantWithCatalogueInvitation(dto),
    onSuccess: (response) => {
      const invitation: InvitationResponseDTO = response.data || {}
      onSubmit(invitation)
    }
  })

  const createInvitation = (values: DefaultInvitationValues) => {
    const { firstName, lastName, email } = values
    const name = getJoinedValue(firstName, lastName)
    const { invitationType, landlordId, tenantId, catalogueId } =
      addingInvitationParams

    switch (invitationType) {
      case 'TENANT':
        if (landlordId && tenantId && catalogueId) {
          const dtoTenant: CreateTenantWithCatalogueInvitationDTO = {
            type: 'TENANT_WITH_CATALOGUE',
            name,
            email,
            landlordId,
            tenantId,
            catalogueId
          }
          createTenantInvitationMutation.mutate(dtoTenant)
        }
        break
      case 'LANDLORD':
        const dtoLandlord: CreateLandlordInvitationDTO = {
          name,
          email
        }
        createLandlordInvitationMutation.mutate(dtoLandlord)
        break
      case 'ADMIN':
        if (landlordId) {
          const dtoAdmin: CreateAdditionalLandlordInvitationDTO = {
            type: 'LANDLORD',
            name,
            email,
            landlordId
          }
          createLandlordInvitationMutation.mutate(dtoAdmin)
        }
        break
      default:
        assertExhaustive(invitationType)
    }
  }

  const cancel = () => {
    createLandlordInvitationMutation.reset()
    createTenantInvitationMutation.reset()
    onCancel()
  }

  const TitleText = () => {
    const { invitationType } = addingInvitationParams

    switch (invitationType) {
      case 'TENANT':
        return (
          <FormattedMessage id="page.invitations.modalAddInvitation.tenant.title" />
        )
      case 'LANDLORD':
        return (
          <FormattedMessage id="page.invitations.modalAddInvitation.landlord.title" />
        )
      case 'ADMIN':
        return (
          <FormattedMessage id="page.invitations.modalAddInvitation.tenant.title" />
        )
      default:
        return ''
    }
  }

  return (
    <>
      <MoovyDialog
        open={open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        errorTitleKey="common.actions.add.error"
        modalTitle={<TitleText />}
        submitButtonContent={<FormattedMessage id="common.buttons.add" />}
      >
        <div
          style={{
            marginBottom:
              createLandlordInvitationMutation?.error ||
              createTenantInvitationMutation?.error
                ? '1rem'
                : undefined
          }}
        >
          <MoovyMutationError
            mutation={createLandlordInvitationMutation}
            errorTitleKey="common.actions.add.error"
          />
          <MoovyMutationError
            mutation={createTenantInvitationMutation}
            errorTitleKey="common.actions.add.error"
          />
        </div>
        <FormInvitation
          onSubmit={(values) => createInvitation(values)}
          formReference={FORM_REFERENCE}
        />
      </MoovyDialog>
    </>
  )
}

export default ModalAddInvitation
