import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_PLACE_GET_ALL
} from '../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyRestricted,
  MoovyScrollToTop,
  MoovySortAndFilter,
  MoovyTable
} from '../../components'
import {
  MoovyPaginationProps,
  PagingAndSortingValues,
  convertPaginationValues,
  getPagingAndSortInitValues
} from '../../components/moovyPagination'
import { PlaceResponseDTO, PlaceSortType } from '../../apis/places/dto'
import React, { useState } from 'react'
import { SS_PLACES_PAGING, SS_PLACES_SORTING } from '../../redux/globalKeys'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import {
  getDirection,
  getSortItems
} from '../../components/moovySortAndFilter/sortValues'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import ModalAddPlace from './modalAddPlace'
import { MoovyToast } from '../../utils/moovyToast'
import { PlacesAdminAPI } from '../../apis/places/placesAPI'
import { appRoutes } from '../../app/permissions'
import useCustomerData from '../../hooks/useCustomerData'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useNavigate } from 'react-router-dom'

const Places = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { setCustomer, customer, setPlace } = useCustomerData()
  const [showModalAdd, setShowModalAdd] = useState(false)

  const [pagingAndSortValues, setPagingAndSortValues] = useGlobalUI({
    key: SS_PLACES_PAGING,
    initialValue: getPagingAndSortInitValues<PlaceSortType>('NAME')
  })

  const queryPlaces = useQuery({
    queryKey: [
      QUERY_PLACE_GET_ALL,
      pagingAndSortValues.page,
      pagingAndSortValues.sort
    ],
    queryFn: () => {
      const { page, limit, sort, direction } = convertPaginationValues(
        pagingAndSortValues
      ) as PagingAndSortingValues

      return PlacesAdminAPI.getPlaces({
        page,
        limit,
        sort: sort as PlaceSortType,
        direction
      })
    },
    enabled: !!customer?.id,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const places: PlaceResponseDTO[] = queryPlaces.data?.data || []
  const pagination: MoovyPaginationProps | undefined = queryPlaces.data
    ?.pageable
    ? {
        page: queryPlaces.data.pageable.page,
        total: queryPlaces.data.pageable.total,
        limit: queryPlaces.data.pageable.limit,
        onNextPage: (page) =>
          setPagingAndSortValues({
            ...pagingAndSortValues,
            page: page.toString()
          })
      }
    : undefined

  const items = getSortItems(intl, 'PLACES')

  const onClickRow = (place: PlaceResponseDTO) => {
    setPlace(place)
    setCustomer(place.customer)
    navigate(`${appRoutes.PLACES}/${place.id}`)
  }
  return (
    <React.Fragment>
      <MoovyScrollToTop />
      <MoovyContainer loading={queryPlaces.isPending}>
        <MoovyContainerHeader>
          <FormattedMessage id="page.places.list.title" />
        </MoovyContainerHeader>
        <MoovyContainerSubHeader
          action="CREATE"
          resource="PLACES"
          button={
            <MoovyButton onClick={() => setShowModalAdd(true)}>
              <FormattedMessage id="page.places.list.button.newPlace" />
            </MoovyButton>
          }
        />
        <MoovyContainerContent>
          <MoovySortAndFilter
            filterKey={SS_PLACES_SORTING}
            items={items}
            value={pagingAndSortValues.sort}
            onChange={(value) => {
              pagingAndSortValues.sort !== value &&
                setPagingAndSortValues({
                  ...pagingAndSortValues,
                  sort: value,
                  direction: getDirection(value as PlaceSortType)
                })
            }}
            pt={2}
            pb={2}
          />
          <MoovyGrid
            emptyTextId="page.places.list.table.action.empty"
            errorTextId="page.places.list.table.action.failed"
            query={queryPlaces}
            pagination={pagination}
          >
            {places.map((row) => (
              <MoovyGridCard
                key={row.id}
                cardTitle={row.name}
                cardSecondaryTitle={
                  <MoovyDateTime utcDateTime={row.createdAt} />
                }
                cardContentList={[
                  row.streetAddress,
                  row.postalCode,
                  row.city,
                  row.customer?.name ?? ''
                ]}
                onCardClicked={() => onClickRow(row)}
              />
            ))}
          </MoovyGrid>
          <MoovyTable
            items={places}
            emptyTextId="page.places.list.table.action.empty"
            errorTextId="page.places.list.table.action.failed"
            query={queryPlaces}
            pagination={pagination}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.streetAddress" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.postalCode" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.city" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.capacity" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="page.places.list.table.header.customer" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-testid="places-table-body">
              {places.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => onClickRow(row)}
                  hover
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <MoovyLink bold>{row.name}</MoovyLink>
                  </TableCell>
                  <TableCell>{row.streetAddress}</TableCell>
                  <TableCell>{row.postalCode}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.capacity}</TableCell>
                  <TableCell>{row.customer?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MoovyTable>
        </MoovyContainerContent>
      </MoovyContainer>
      <ModalAddPlace
        open={showModalAdd}
        onSubmit={(response) => {
          setShowModalAdd(false)
          MoovyToast.showToast(
            <FormattedMessage
              id="page.places.list.actions.added"
              values={{ name: response.name }}
            />
          )
          queryClient.invalidateQueries({
            queryKey: [QUERY_PLACE_GET_ALL]
          })
        }}
        onCancel={() => setShowModalAdd(false)}
      />
    </React.Fragment>
  )
}

export default Places
