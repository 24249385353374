import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { MoovyButton, MoovyMutationError } from '..'

import { AxiosResponse } from 'axios'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { FormattedMessage } from 'react-intl'
import { MoovyPalette } from '../../styles/theme'
import { ReactNode } from 'react'
import { UseMutationResult } from '@tanstack/react-query'

export interface MoovyDialogProps extends DialogProps {
  open: boolean
  mutation?: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>
  errorTitleKey?: string
  children: ReactNode
  modalTitle: any
  submitButtonContent: ReactNode | string
  formReference?: string
  leftSideButtons?: [ReactNode]
  contentPadding?: number
  hideCancelButton?: boolean
  onCancel: () => void
  onSubmit?: (event: any) => void
}

const MoovyDialog = (props: MoovyDialogProps) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const {
    open,
    mutation,
    errorTitleKey,
    children,
    modalTitle,
    submitButtonContent,
    formReference,
    leftSideButtons,
    contentPadding,
    hideCancelButton,
    onCancel,
    onSubmit,
    ...other
  } = props

  return (
    <>
      <Dialog
        open={open}
        onSubmit={onSubmit}
        fullScreen={fullScreen}
        onClose={onCancel}
        sx={
          fullScreen
            ? undefined
            : {
                '& .MuiDialog-paper': {
                  // Minimum width of all Moovy dialogs is set here
                  minWidth: '350px'
                }
              }
        }
        {...other}
      >
        <DialogTitle sx={{ pr: 7 }}>{modalTitle}</DialogTitle>
        <IconButton
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <DialogContent
          dividers
          sx={{ padding: contentPadding, position: 'relative' }}
        >
          {mutation && (
            <div
              style={{
                marginBottom: mutation?.error ? '1rem' : undefined
              }}
            >
              <MoovyMutationError
                mutation={mutation}
                errorTitleKey={errorTitleKey || 'common.actions.error'}
              />
            </div>
          )}
          {children}
          <Box sx={{ opacity: 0.5 }}>
            <Backdrop
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: MoovyPalette.colorMainBackground,
                zIndex: 1
              }}
              open={mutation?.isPending || false}
            >
              <CircularProgress />
            </Backdrop>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: leftSideButtons ? 'space-between' : 'right',
              width: '100%'
            }}
          >
            {leftSideButtons?.map((item) => item)}
            <Box sx={{ display: 'flex', gap: 1 }}>
              {!hideCancelButton && (
                <MoovyButton
                  onClick={() => {
                    onCancel()
                  }}
                  variant="text"
                >
                  <FormattedMessage id="common.buttons.cancel" />
                </MoovyButton>
              )}
              <MoovyButton
                type="submit"
                form={formReference}
                mutation={mutation}
                onClick={onSubmit}
              >
                {submitButtonContent}
              </MoovyButton>
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MoovyDialog
