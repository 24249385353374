import {
  InvitationsAdmin,
  MoovyDialog,
  MoovyScrollToTop
} from '../../../../components'

import { FormattedMessage } from 'react-intl'
import { InvitationResponseDTO } from '../../../../apis/invitations/dto'
import { UseQueryResult } from '@tanstack/react-query'

export type ModalAdminInvitationsProps = {
  open: boolean
  queryAdminInvitations: UseQueryResult<InvitationResponseDTO[], any>
  landlordId: string
  onClose: () => void
}

const ModalAdminInvitations = ({
  open,
  queryAdminInvitations,
  landlordId,
  onClose
}: ModalAdminInvitationsProps) => (
  <MoovyDialog
    maxWidth={'lg'}
    hideCancelButton
    open={open}
    onCancel={onClose}
    onSubmit={onClose}
    errorTitleKey="common.actions.add.error"
    modalTitle={
      <FormattedMessage id="page.customers.tabAdministrators.modalAdminInvitations.title" />
    }
    submitButtonContent={<FormattedMessage id="common.ok" />}
    contentPadding={0}
  >
    <MoovyScrollToTop />
    <InvitationsAdmin
      queryInvitations={queryAdminInvitations}
      customerId={landlordId}
    />
  </MoovyDialog>
)

export default ModalAdminInvitations
