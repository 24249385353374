import { AxiosInstance, default as axios } from 'axios'
import {
  SS_CUSTOMER_ID,
  SS_CUSTOMER_INIT,
  SS_CUSTOMER_NAME,
  SS_CUSTOMER_TYPE,
  SS_PLACE_ID,
  SS_PLACE_NAME,
  SS_TOKEN
} from '../redux/keysStorage'

import { API_BASE_URL } from '../envConfig'
import LoginAPI from './authenticate/loginAPI'

let failedQueue: any = []
let isRefreshing = false

const processQueue = (error: any) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve()
    }
  })

  failedQueue = []
}

const setJwtToken = (jwtToken: string) => {
  sessionStorage.setItem(SS_TOKEN, jwtToken)
  updateJwtTokenInInterceptor(client, jwtToken)
}

export const logoutUser = () => {
  sessionStorage.setItem(SS_TOKEN, '')
  sessionStorage.setItem(SS_CUSTOMER_ID, '')
  sessionStorage.setItem(SS_CUSTOMER_NAME, '')
  sessionStorage.setItem(SS_PLACE_ID, '')
  sessionStorage.setItem(SS_PLACE_NAME, '')
  sessionStorage.setItem(SS_CUSTOMER_TYPE, '')
  sessionStorage.setItem(SS_CUSTOMER_INIT, '')
}

export const client = createAxiosClient({
  options: {
    baseURL: API_BASE_URL,
    timeout: 300000
  },
  logout: logoutUser,
  setJwtToken: setJwtToken
})

export const updateJwtTokenInInterceptor = (
  client: AxiosInstance,
  jwtToken: string
) => {
  client.interceptors.request.clear()
  client.interceptors.request.use(
    (config) => {
      config.headers.Authorization = 'Bearer ' + jwtToken
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

function createAxiosClient({
  options,
  logout,
  setJwtToken
}: any): AxiosInstance {
  const client = axios.create(options)

  const token = sessionStorage.getItem(SS_TOKEN)
  if (token) {
    client.interceptors.request.use(
      (config) => {
        config.headers.Authorization = 'Bearer ' + token
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
  client.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      const originalRequest = error.config
      // If error, process all the requests in the queue and logout the user.
      const handleError = (error: any) => {
        processQueue(error)
        logout()
        return Promise.reject(error)
      }

      // Refresh token conditions
      if (
        error.response?.status === 401 &&
        error.response.data.error === 'Token is not valid or has expired' &&
        originalRequest?._retry !== true
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then(() => {
              return client(originalRequest)
            })
            .catch((err) => {
              return Promise.reject(err)
            })
        }
        isRefreshing = true
        originalRequest._retry = true

        LoginAPI.getAuthSession()
          .then((authSession) => {
            setJwtToken(authSession.tokens?.idToken?.toString() || '')
            processQueue(null)
            return client(originalRequest)
          }, handleError)
          .finally(() => {
            isRefreshing = false
          })
      }

      // Refresh token missing or expired => logout user...
      if (
        error.response?.status === 401 &&
        error.response.data.error === 'Token is not valid or has expired'
      ) {
        return handleError(error)
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )

  return client
}
