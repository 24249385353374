import { ActiveRightSortType } from '../../apis/rights/dto'
import { CatalogueSortType } from '../../apis/catalogues/dto'
import { IntlShape } from 'react-intl'
import { InvitationSortType } from '../../apis/invitations/dto'
import { PlaceSortType } from '../../apis/places/dto'
import { SortDirection } from '../moovyPagination'

type SortType = 'PLACES' | 'INVITATIONS' | 'ACTIVE_RIGHTS' | 'CATALOGUES'

export const getSortItems = (
  intl: IntlShape,
  sortType: SortType
): { key: string; value: string }[] => {
  const getLocalizedItems = (
    sortType: SortType,
    keys:
      | PlaceSortType[]
      | InvitationSortType[]
      | ActiveRightSortType[]
      | CatalogueSortType[]
  ) => {
    return keys.map((key) => {
      return {
        key: key.toString(),
        value: intl.formatMessage({
          id: `sort.${sortType}.${key}`
        })
      }
    })
  }

  switch (sortType) {
    case 'PLACES': {
      const keys: PlaceSortType[] = ['NAME', 'CITY', 'CAPACITY', 'CUSTOMER_ID']
      return getLocalizedItems(sortType, keys)
    }
    case 'INVITATIONS': {
      const keys: InvitationSortType[] = ['NAME', 'EMAIL', 'CREATED_AT', 'USED']
      return getLocalizedItems(sortType, keys)
    }
    case 'ACTIVE_RIGHTS': {
      const keys: ActiveRightSortType[] = [
        'INDIVIDUAL',
        'VEHICLE',
        'VALIDITY_START',
        'VALIDITY_END'
      ]
      return getLocalizedItems(sortType, keys)
    }
    case 'CATALOGUES': {
      const keys: CatalogueSortType[] = [
        'CUSTOMER_ID',
        'CREATED_AT',
        'UPDATED_AT',
        'AMOUNT'
      ]
      return getLocalizedItems(sortType, keys)
    }
    default:
      return []
  }
}

export const getDirection = (
  sortKey:
    | PlaceSortType
    | InvitationSortType
    | ActiveRightSortType
    | CatalogueSortType
): SortDirection => {
  const invitationDescKeys: InvitationSortType[] = ['CREATED_AT', 'USED']
  const placeDescKeys: PlaceSortType[] = ['CAPACITY']
  const activeRightDescKeys: ActiveRightSortType[] = [
    'VALIDITY_START',
    'VALIDITY_END'
  ]
  const catalogueDescKeys: CatalogueSortType[] = [
    'CREATED_AT',
    'UPDATED_AT',
    'AMOUNT'
  ]

  if (
    invitationDescKeys.includes(sortKey as InvitationSortType) ||
    placeDescKeys.includes(sortKey as PlaceSortType) ||
    activeRightDescKeys.includes(sortKey as ActiveRightSortType) ||
    catalogueDescKeys.includes(sortKey as CatalogueSortType)
  ) {
    return 'DESC'
  }

  return 'ASC'
}
