import { FormattedMessage, useIntl } from 'react-intl'
import {
  ModalEditVehicle,
  MoovyDateTime,
  MoovyGrid,
  MoovyGridCard,
  MoovyLink,
  MoovyTable
} from '../../../../components'
import React, { useState } from 'react'
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { ActiveRightsPagingDTO } from '../../../../apis/rights/dto'
import { CatalogueResponseDTO } from '../../../../apis/catalogues/dto'
import ModalAddRightHolder from './modalAddRightHolder'
import ModalAddVehicle from './modalAddVehicle'
import { MoovyPaginationProps } from '../../../../components/moovyPagination'
import { OfferingResponseDTO } from '../../../../apis/offering/dto'
import { UseQueryResult } from '@tanstack/react-query'
import { appRoutes } from '../../../../app/permissions'
import { getJoinedValue } from '../../../../utils/utils'
import { useNavigate } from 'react-router-dom'

export type RightHoldersProps = {
  queryActiveRights: UseQueryResult<ActiveRightsPagingDTO, any>
  place: { id: string; name: string }
  customerId: string
  catalogue: CatalogueResponseDTO | undefined
  offering: OfferingResponseDTO | undefined
  showModalAddRightHolder: boolean
  showModalAddVehicle: boolean
  pagination?: MoovyPaginationProps
  onSubmitAdd: (individualId?: string) => void
  onSubmitEdit: (individualId?: string) => void
  onTerminate: (individualId?: string) => void
  onCancel: () => void
}

const RightHolders = ({
  queryActiveRights,
  place,
  customerId,
  catalogue,
  offering,
  showModalAddRightHolder,
  showModalAddVehicle,
  pagination,
  onSubmitAdd,
  onSubmitEdit,
  onTerminate,
  onCancel
}: RightHoldersProps) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [showModalEditVehicle, setShowModalEditVehicle] = useState<
    { individualId?: string; rightId: string; licensePlate: string } | undefined
  >(undefined)
  const activeRights = queryActiveRights.data?.data || []

  const onClickRow = (ref: string) => {
    navigate(`${appRoutes.RIGHT_HOLDERS}/${ref}`, {
      state: {
        placeId: place.id,
        placeName: place.name,
        catalogueId: catalogue?.id,
        offeringName: catalogue?.offering?.name
      }
    })
  }

  const isWhitelist = offering?.type === 'WHITELIST'

  return (
    <>
      <MoovyGrid
        emptyTextId="page.catalogues.tabCatalogue.rightHolders.table.action.empty"
        errorTextId="page.catalogues.tabCatalogue.rightHolders.table.action.failed"
        query={queryActiveRights}
        pagination={pagination}
      >
        {activeRights.map((row) => {
          const normalContentList = [
            <FormattedMessage id="common.text.email" />,
            <b>{row.individual?.email || ''}</b>,
            <FormattedMessage id="common.text.licensePlate" />,
            <b>{row.vehicle?.licensePlate || ''}</b>
          ]
          const commonContentList = [
            <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.card.header.place" />,
            <b>{row.place.name}</b>,
            <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.card.header.validUntil" />,
            <b>
              <MoovyDateTime
                utcDateTime={row.validityEnd}
                emptyText={intl.formatMessage({
                  id: 'page.rightHolders.rightHolder.tab.vehicles.table.item.permanent'
                })}
              />
            </b>
          ]
          return (
            <MoovyGridCard
              key={row.id}
              cardTitle={
                isWhitelist
                  ? row.vehicle?.licensePlate || ''
                  : getJoinedValue(
                      row.individual?.lastName,
                      row.individual?.firstName
                    )
              }
              cardSecondaryTitle={
                <MoovyDateTime utcDateTime={row.validityStart} />
              }
              cardContentList={
                isWhitelist
                  ? commonContentList
                  : [...normalContentList, ...commonContentList]
              }
              onCardClicked={() => onClickRow(row.individual?.id || '')}
            />
          )
        })}
      </MoovyGrid>
      <MoovyTable
        items={activeRights}
        emptyTextId="page.catalogues.tabCatalogue.rightHolders.table.action.empty"
        errorTextId="page.catalogues.tabCatalogue.rightHolders.table.action.failed"
        query={queryActiveRights}
        pagination={pagination}
      >
        <TableHead>
          <TableRow>
            {!isWhitelist && (
              <>
                <TableCell>
                  <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.table.header.name" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="common.text.email" />
                </TableCell>
              </>
            )}
            <TableCell>
              <FormattedMessage id="common.text.licensePlate" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.table.header.place" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.table.header.validFrom" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="page.catalogues.tabCatalogue.rightHolders.table.header.validUntil" />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="places-table-body">
          {activeRights.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onClickRow(row.individual?.id || '')}
              hover
              sx={{ cursor: 'pointer' }}
            >
              {!isWhitelist && (
                <>
                  <TableCell>
                    <MoovyLink>
                      {getJoinedValue(
                        row.individual?.lastName,
                        row.individual?.firstName
                      )}
                    </MoovyLink>
                  </TableCell>
                  <TableCell>{row.individual?.email || ''}</TableCell>
                </>
              )}
              <TableCell>{row.vehicle?.licensePlate || ''}</TableCell>
              <TableCell>{row.place.name}</TableCell>
              <TableCell>
                <MoovyDateTime utcDateTime={row.validityStart} />
              </TableCell>
              <TableCell>
                <MoovyDateTime
                  utcDateTime={row.validityEnd}
                  emptyText={intl.formatMessage({
                    id: 'page.rightHolders.rightHolder.tab.vehicles.table.item.permanent'
                  })}
                />
              </TableCell>
              <TableCell align="right">
                <MoovyLink
                  onClick={(evt) => {
                    evt.stopPropagation()
                    setShowModalEditVehicle({
                      individualId: row.individual?.id,
                      rightId: row.id,
                      licensePlate: row.vehicle?.licensePlate || ''
                    })
                  }}
                >
                  <FormattedMessage id="common.buttons.edit" />
                </MoovyLink>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MoovyTable>
      <ModalAddRightHolder
        open={showModalAddRightHolder}
        customerId={customerId}
        catalogueId={catalogue?.id || ''}
        offering={catalogue?.offering}
        onSubmit={() => {
          onSubmitAdd(showModalEditVehicle?.individualId)
        }}
        onCancel={() => onCancel()}
      />
      <ModalEditVehicle
        open={!!showModalEditVehicle}
        rightId={showModalEditVehicle?.rightId || ''}
        licensePlate={showModalEditVehicle?.licensePlate || ''}
        onTerminated={() => {
          onTerminate(showModalEditVehicle?.individualId)
          setShowModalEditVehicle(undefined)
        }}
        onSubmit={() => {
          onSubmitEdit(showModalEditVehicle?.individualId)
          setShowModalEditVehicle(undefined)
        }}
        onCancel={() => setShowModalEditVehicle(undefined)}
      />
      <ModalAddVehicle
        open={showModalAddVehicle}
        customerId={customerId}
        catalogueId={catalogue?.id || ''}
        offering={catalogue?.offering}
        onSubmit={() => {
          onSubmitAdd(showModalEditVehicle?.individualId)
        }}
        onCancel={() => onCancel()}
      />
    </>
  )
}

export default RightHolders
