import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyListLabelValue,
  MoovyRestricted
} from '../../../components'
import { getAmountAndPercentage, getJoinedValue } from '../../../utils/utils'

import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { PlaceResponseDTO } from '../../../apis/places/dto'

interface PlaceInformationProps {
  loading: boolean
  place?: PlaceResponseDTO
  offeringAmount: number
  onEdit: () => void
}

const PlaceInformation: React.FC<PlaceInformationProps> = ({
  loading,
  place,
  offeringAmount,
  onEdit
}) => {
  const intl = useIntl()

  const data = [
    {
      label: intl.formatMessage({
        id: 'page.placeRights.placeInformation.address'
      }),
      value: `${getJoinedValue(
        place?.streetAddress,
        place?.postalCode,
        ', '
      )} ${place?.city}`
    },
    {
      label: intl.formatMessage({
        id: 'page.placeRights.placeInformation.capacity'
      }),
      value: place?.capacity
    },
    {
      label: intl.formatMessage({
        id: 'page.placeRights.placeInformation.offeringAmount'
      }),
      value: getAmountAndPercentage(place?.capacity || 0, offeringAmount)
    }
  ]

  return (
    <MoovyContainer loading={loading}>
      <MoovyContainerSubHeader
        title={
          <FormattedMessage id="page.placeRights.placeInformation.header" />
        }
        button={
          <MoovyRestricted action="EDIT" resource="PLACES">
            <MoovyButton
              startIcon={<EditRoundedIcon />}
              onClick={onEdit}
              showAsIconButton
              aria-label={intl.formatMessage({
                id: 'page.placeRights.placeInformation.button.edit'
              })}
            />
          </MoovyRestricted>
        }
        divider
      />
      <MoovyContainerContent>
        <MoovyListLabelValue data={data} />
      </MoovyContainerContent>
    </MoovyContainer>
  )
}

export default PlaceInformation
