import FormEditRightHolder, {
  EditRightHolderValues
} from './formEditRightHolder'
import {
  RightHolderIndividualDTO,
  RightHolderResponseDTO,
  RightHolderUpdateDTO,
  RightHolderUpdateIndividualDTO,
  RightHolderUpdateVehicleDTO,
  RightHolderVehicleDTO,
  getRightHolderName
} from '../../../apis/rightHolders/dto'

import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '../../../components'
import { MoovyToast } from '../../../utils/moovyToast'
import RightHoldersAPI from '../../../apis/rightHolders/rightHoldersAPI'
import { getValueIfNoMatch } from '../../../utils/utils'
import { useMutation } from '@tanstack/react-query'

interface IndividualRightHolder {
  kind: 'individual'
  dto: RightHolderIndividualDTO
}

interface VehicleRightHolder {
  kind: 'vehicle'
  dto: RightHolderVehicleDTO
}

export type TaggedRightHolder = IndividualRightHolder | VehicleRightHolder

export type ModalEditRightHolderProps = {
  open: boolean
  rightHolderRef: string
  rightHolder: TaggedRightHolder
  onSubmit: (values: RightHolderResponseDTO) => void
  onCancel: () => void
}

const ModalEditRightHolder = (props: ModalEditRightHolderProps) => {
  const FORM_REFERENCE = 'formEditRightHolder'

  const updateRightHolderMutation = useMutation({
    mutationFn: (dto: RightHolderUpdateDTO) =>
      RightHoldersAPI.updateIndividual(props.rightHolderRef, dto),
    onSuccess: (response) => {
      const rightHolder: RightHolderResponseDTO = response.data || {}
      let name = ''

      if (rightHolder?.type === 'individual') {
        const dataIndividual = rightHolder as RightHolderIndividualDTO
        name = getRightHolderName(dataIndividual)
      } else if (rightHolder?.type === 'vehicle') {
        const dataVehicle = rightHolder as RightHolderVehicleDTO
        name = getRightHolderName(dataVehicle)
      }
      MoovyToast.showToast(
        <FormattedMessage
          id="page.rightHolders.rightHolder.modalEditRightHolder.actions.update"
          values={{ name }}
        />
      )
      props.onSubmit(rightHolder)
    }
  })

  const updateIndividual = (values: EditRightHolderValues) => {
    const { firstName, lastName, email, phoneNumber } = values

    if (props?.rightHolder.kind === 'individual') {
      const originalDTO = props.rightHolder.dto
      const dto: RightHolderUpdateIndividualDTO = {
        type: 'individual',
        firstName: getValueIfNoMatch(firstName, originalDTO.firstName),
        lastName: getValueIfNoMatch(lastName, originalDTO.lastName),
        email: getValueIfNoMatch(email, originalDTO.email),
        phoneNumber: getValueIfNoMatch(phoneNumber, originalDTO.phoneNumber)
      }
      updateRightHolderMutation.mutate(dto)
    } else {
      console.error(
        'Save prevented because there is not valid right holder type.'
      )
    }
  }

  const cancel = () => {
    updateRightHolderMutation.reset()
    props.onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        mutation={updateRightHolderMutation}
        errorTitleKey={'common.actions.update.error'}
        modalTitle={
          props?.rightHolder.kind === 'individual' ? (
            <FormattedMessage id="page.rightHolders.rightHolder.modalEditRightHolder.title.individual" />
          ) : (
            <FormattedMessage id="page.rightHolders.rightHolder.modalEditRightHolder.title.vehicle" />
          )
        }
        submitButtonContent={<FormattedMessage id="common.buttons.save" />}
      >
        <FormEditRightHolder
          formReference={FORM_REFERENCE}
          rightHolder={props.rightHolder.dto}
          onSubmit={updateIndividual}
        />
      </MoovyDialog>
    </>
  )
}

export default ModalEditRightHolder
