import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CATALOGUES_BY_OFFERING,
  QUERY_CUSTOMER_GET_BY_REF
} from '../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyListLabelValue,
  MoovyQueryHandler,
  MoovyRestricted
} from '../../../components'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import CustomersAPI from '../../../apis/customers/customersAPI'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ModalEditCustomer from './modalEditCustomer'
import { MoovyContainerBreadcrumbsProps } from '../../../components/moovyContainer/moovyContainerHeader'
import { MoovyToast } from '../../../utils/moovyToast'
import { getJoinedValue } from '../../../utils/utils'
import useCustomerData from '../../../hooks/useCustomerData'

export type CustomerInformationProps = {
  header?: string
  customerId?: string
  customBreadcrumbs?: MoovyContainerBreadcrumbsProps
}

const CustomerInformation = ({
  header,
  customerId,
  customBreadcrumbs
}: CustomerInformationProps) => {
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState(false)
  const { customer, setCustomer } = useCustomerData()
  const intl = useIntl()

  const queryCustomer = useQuery({
    queryKey: [QUERY_CUSTOMER_GET_BY_REF, customerId],
    queryFn: () => CustomersAPI.getCustomerByRef(customerId || ''),
    enabled: !!customerId,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const { name, streetAddress, city, postalCode, businessId, customerNumber } =
    queryCustomer.data ?? {}
  const defaultValues = {
    name: name || '',
    streetAddress: streetAddress || '',
    city: city || '',
    postalCode: postalCode || '',
    businessId: businessId || '',
    customerNumber: customerNumber || ''
  }

  const data = [
    {
      label: intl.formatMessage({
        id: 'page.customers.customersInformation.name'
      }),
      value: `${defaultValues.name}`
    },
    {
      label: intl.formatMessage({
        id: 'page.customers.customersInformation.streetAddress'
      }),
      value: `${getJoinedValue(
        defaultValues?.streetAddress,
        defaultValues?.postalCode,
        ', '
      )} ${defaultValues?.city}`
    },
    {
      label: intl.formatMessage({
        id: 'page.customers.customersInformation.businessId'
      }),
      value: `${defaultValues.businessId}`
    },

    {
      label: intl.formatMessage({
        id: 'page.customers.customersInformation.customerNumber'
      }),
      value: `${defaultValues.customerNumber}`
    }
  ]

  const refreshView = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_CUSTOMER_GET_BY_REF, customerId]
    })
    queryClient.invalidateQueries({
      queryKey: [QUERY_CATALOGUES_BY_OFFERING]
    })
  }

  return (
    <MoovyQueryHandler query={queryCustomer} rootPageIfNotFoundError>
      <MoovyContainer>
        <MoovyContainerHeader breadcrumbs={customBreadcrumbs}>
          {header || name}
        </MoovyContainerHeader>
        <MoovyContainerSubHeader
          title={<FormattedMessage id="page.customer.title" />}
          button={
            <MoovyRestricted
              action="EDIT"
              resource="CUSTOMER"
              visibility={!!customerId}
            >
              <MoovyButton
                startIcon={<EditRoundedIcon />}
                onClick={() => setShowModal(true)}
                showAsIconButton
                aria-label={intl.formatMessage({
                  id: 'page.customer.button.edit'
                })}
              />
            </MoovyRestricted>
          }
          divider
        />
        <MoovyContainerContent>
          <MoovyListLabelValue data={data} />
        </MoovyContainerContent>
      </MoovyContainer>
      <ModalEditCustomer
        open={showModal}
        editableCustomerRef={customerId || ''}
        defaultValues={defaultValues}
        onSubmit={(response) => {
          MoovyToast.showToast(
            <FormattedMessage
              id="page.customer.actions.updated"
              values={{ name: response?.name }}
            />
          )
          response && response.id === customer.id && setCustomer(response)
          refreshView()
          setShowModal(false)
        }}
        onCancel={() => setShowModal(false)}
      />
    </MoovyQueryHandler>
  )
}

export default CustomerInformation
