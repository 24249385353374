import * as yup from 'yup'

import {
  CatalogueResponseDTO,
  CatalogueUpdateDTO
} from '../../apis/catalogues/dto'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyDialog, MoovyForm } from '../../components'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { AxiosError } from 'axios'
import { CataloguesAPI } from '../../apis/catalogues/cataloguesAPI'
import { Formik } from 'formik'
import { MoovyToast } from '../../utils/moovyToast'
import { OfferingResponseDTO } from '../../apis/offering/dto'
import { QUERY_CATALOGUES_BY_OFFERING } from '../../apis/moovyQueryKeys'
import React from 'react'
import { getValueIfNoMatch } from '../../utils/utils'

type ModalEditCatalogueProps = {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  catalogue?: CatalogueResponseDTO
  offering?: OfferingResponseDTO
}

type FormCatalogue = {
  catalogueName: string
  amount: string
}

const ModalEditCatalogue: React.FC<ModalEditCatalogueProps> = ({
  open,
  onCancel,
  onSubmit,
  catalogue,
  offering
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const FORM_REFERENCE = 'formEditCatalogue'

  const nameSchema = {
    catalogueName: yup.string().required(
      intl.formatMessage({
        id: 'common.form.errors.empty'
      })
    )
  }

  const schema = yup.object().shape({
    ...nameSchema,
    amount: yup
      .number()
      .min(
        0,
        intl.formatMessage({
          id: 'page.offering.modalEditCatalogue.amount.minimum'
        })
      )
      .required(
        intl.formatMessage({
          id: 'common.form.errors.empty'
        })
      )
      .typeError(
        intl.formatMessage({
          id: 'common.form.errors.number'
        })
      )
  })

  const whitelistSchema = yup.object().shape(nameSchema)

  const mutationCatalogue = useMutation({
    mutationFn: (dto: CatalogueUpdateDTO) =>
      CataloguesAPI.updateCatalogue(catalogue?.id ?? '', dto),
    onSuccess: () => {
      MoovyToast.showToast(
        <FormattedMessage id="page.offering.modalEditCatalogue.mutation.success" />
      )
      onSubmit()
      queryClient.invalidateQueries({
        queryKey: [QUERY_CATALOGUES_BY_OFFERING]
      })
    }
  })

  // Handle conflict response as a amount exceeds offering error message, no localized error message from backend
  const error = mutationCatalogue.error
    ? (mutationCatalogue.error as AxiosError)
    : undefined
  const conflictErrorKey =
    error && error.response?.status === 409
      ? 'page.offering.modalCreateCatalogue.mutation.conflict'
      : undefined

  const submitHandler = (values: FormCatalogue) => {
    const amount = getValueIfNoMatch<number | undefined>(
      Number.parseInt(values.amount),
      catalogue?.amount
    )
    const name = getValueIfNoMatch<string | undefined>(
      values.catalogueName,
      catalogue?.name
    )

    if (name || amount) {
      const dto: CatalogueUpdateDTO = {
        amount: amount ? Number.parseInt(amount) : catalogue?.amount || 0,
        name
      }
      mutationCatalogue.mutate(dto)
    }
  }

  const isWhitelist = offering?.type === 'WHITELIST'

  return (
    <MoovyDialog
      open={open}
      modalTitle={
        <FormattedMessage id="page.offering.modalEditCatalogue.title" />
      }
      submitButtonContent={<FormattedMessage id="common.buttons.save" />}
      onCancel={onCancel}
      formReference={FORM_REFERENCE}
      mutation={mutationCatalogue}
      errorTitleKey={conflictErrorKey}
    >
      <Formik
        enableReinitialize={true}
        validateOnChange={false}
        initialValues={{
          catalogueName: catalogue?.name ?? '',
          amount: catalogue?.amount ? catalogue.amount.toString() : ''
        }}
        validationSchema={isWhitelist ? whitelistSchema : schema}
        onSubmit={(values) => submitHandler(values)}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit} id={FORM_REFERENCE}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormLabel
                style={{ fontWeight: 'bold' }}
                focused={false}
              >
                <FormattedMessage id="page.offering.modalEditCatalogue.catalogueName" />
              </MoovyForm.FormLabel>
              <MoovyForm.FormTextField
                name="catalogueName"
                value={values.catalogueName}
                onChange={handleChange}
                label={
                  <FormattedMessage id="page.offering.modalEditCatalogue.catalogueName" />
                }
                error={!!errors.catalogueName}
                errorText={errors.catalogueName}
              />
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.offering.modalEditCatalogue.customer" />
                }
              />
              {catalogue?.customer.name}
              <MoovyForm.FormTitle
                title={
                  <FormattedMessage id="page.offering.modalEditCatalogue.offering" />
                }
              />
              <MoovyForm.FormSwitch
                checked={true}
                label={offering?.name || ''}
                style={{ fontWeight: 'bold' }}
                disabled
              />
              {!isWhitelist && (
                <>
                  <MoovyForm.FormLabel style={{ fontWeight: 'bold' }}>
                    <FormattedMessage id="page.offering.modalEditCatalogue.amountLabel" />
                  </MoovyForm.FormLabel>
                  <MoovyForm.FormTextField
                    name="amount"
                    value={values.amount}
                    onChange={handleChange}
                    error={!!errors.amount}
                    errorText={errors.amount}
                    sx={{ minWidth: '50%' }}
                  />
                </>
              )}
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </MoovyDialog>
  )
}

export default ModalEditCatalogue
