import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CUSTOMER_GET_ALL,
  QUERY_PLACE_GET_BY_REF
} from '../../apis/moovyQueryKeys'
import FormSubPlace, { DefaultSubPlaceValues } from './formSubPlace'
import { PlaceCreateDTO, PlaceResponseDTO } from '../../apis/places/dto'
import { useMutation, useQuery } from '@tanstack/react-query'

import CustomersAPI from '../../apis/customers/customersAPI'
import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '..'
import { MoovyToast } from '../../utils/moovyToast'
import { PlacesAPI } from '../../apis/places/placesAPI'
import useAuth from '../../hooks/useAuth'
import useCustomerData from '../../hooks/useCustomerData'

export type ModalSubPlaceProps = {
  open: boolean
  customerId: string
  onSubmit: (values: PlaceResponseDTO) => void
  onCancel: () => void
}

const ModalSubPlace = (props: ModalSubPlaceProps) => {
  const FORM_REFERENCE = 'formSubPlace'
  const { place } = useCustomerData()
  const { admin } = useAuth()

  const queryCustomers = useQuery({
    queryKey: [QUERY_CUSTOMER_GET_ALL],
    queryFn: () => CustomersAPI.getCustomers(admin, { limit: 10 }),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const customers = queryCustomers.data?.data || []

  const queryPlace = useQuery({
    queryKey: [QUERY_PLACE_GET_BY_REF, place.id],
    queryFn: () => PlacesAPI.getPlaceByRef(place.id || ''),
    enabled: !!place.id
  })

  const defaultValues: DefaultSubPlaceValues = {
    name: '',
    streetAddress: queryPlace.data?.streetAddress || '',
    postalCode: queryPlace.data?.postalCode || '',
    city: queryPlace.data?.city || '',
    capacity: '',
    customerId: queryPlace.data?.customer?.id || props.customerId
  }

  const subPlaceMutation = useMutation({
    mutationFn: (dto: PlaceCreateDTO) =>
      PlacesAPI.createPlaceWithDefaultOffering(dto),
    onSuccess: (response) => {
      const place: PlaceResponseDTO = response.data || {}
      MoovyToast.showToast(
        <FormattedMessage id="components.modalSubPlace.actions.added" />
      )
      props.onSubmit(place)
    }
  })

  const addSubPlace = (values: DefaultSubPlaceValues) => {
    const { name, capacity, streetAddress, postalCode, city, customerId } =
      values
    const dto: PlaceCreateDTO = {
      name,
      streetAddress: streetAddress,
      postalCode: postalCode,
      city: city,
      customerId: customerId,
      parentId: place.id,
      capacity: Number.parseInt(capacity)
    }
    subPlaceMutation.mutate(dto)
  }

  const cancel = () => {
    subPlaceMutation.reset()
    props.onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        mutation={subPlaceMutation}
        errorTitleKey="components.modalSubPlace.actions.error.added"
        modalTitle={<FormattedMessage id="components.modalSubPlace.title" />}
        submitButtonContent={
          <FormattedMessage id="components.modalSubPlace.button.add" />
        }
      >
        <FormSubPlace
          formReference={FORM_REFERENCE}
          onSubmit={addSubPlace}
          defaultValues={defaultValues}
          customers={customers}
        />
      </MoovyDialog>
    </>
  )
}

export default ModalSubPlace
