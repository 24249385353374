import { FormattedMessage } from 'react-intl'

const MoovyErrorPage = () => {
  return (
    <div>
      <h1>
        <FormattedMessage id="moovyErrorPage.oops" />
      </h1>
      <p>
        <FormattedMessage id="moovyErrorPage.message" />
      </p>
    </div>
  )
}

export default MoovyErrorPage
