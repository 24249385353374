import { Fab, Zoom } from '@mui/material'
import { useEffect, useState } from 'react'

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'

const MoovyScrollToTop = ({}) => {
  const [showScroll, setshowScroll] = useState(false)
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const innerHeight = window.innerHeight || 0
      const y = window.scrollY
      // Show the button only if user is scrolling down.
      setshowScroll(scrollY < y && y > innerHeight / 2)
      setScrollY(y)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <>
      <Zoom in={showScroll} timeout={{ enter: 500, exit: 500 }}>
        <Fab
          size="small"
          style={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: 'transparent'
          }}
        >
          <KeyboardArrowUpRoundedIcon onClick={scrollToTop} />
        </Fab>
      </Zoom>
    </>
  )
}

export default MoovyScrollToTop
