{
  "Account recovery requires verified contact information": "Account recovery requires verified contact information",
  "Add your Profile": "Add your Profile",
  "Add your Website": "Add your Website",
  "Back to Sign In": "Back to Sign In",
  "Change Password": "Change Password",
  "Changing": "Changing",
  "Code": "Code",
  "Confirm Password": "Confirm Password",
  "Confirm Sign Up": "Confirm Sign Up",
  "Confirm SMS Code": "Confirm SMS Code",
  "Confirm TOTP Code": "Confirm TOTP Code",
  "Confirm": "Confirm",
  "Confirmation Code": "Confirmation Code",
  "Confirming": "Confirming",
  "Create a new account": "Create a new account",
  "Create Account": "Create Account",
  "Creating Account": "Creating Account",
  "Dismiss alert": "Dismiss alert",
  "Given Name": "First Name",
  "Family Name": "Last Name",
  "Name": "Name",
  "Email": "Email",
  "Enter your Birthdate": "Enter your Birthdate",
  "Enter your code": "Enter your code",
  "Enter your Confirmation Code": "Enter your Confirmation Code",
  "Enter your Email": "Enter your Email",
  "Enter your Family Name": "Enter your Family Name",
  "Enter your Given Name": "Enter your Given Name",
  "Enter your Middle Name": "Enter your Middle Name",
  "Enter your Name": "Enter your Name",
  "Enter your Nickname": "Enter your Nickname",
  "Enter your Password": "Enter your Password",
  "Enter your phone number": "Enter your phone number",
  "Enter your Preferred Username": "Enter your Preferred Username",
  "Enter your username": "Enter your username",
  "Forgot password?": "Forgot password?",
  "Forgot your password?": "Forgot your password?",
  "Hide password": "Hide password",
  "It may take a minute to arrive": "It may take a minute to arrive",
  "Loading": "Loading",
  "New password": "New password",
  "New Password": "New Password",
  "or": "or",
  "Password": "Password",
  "Phone Number": "Phone Number",
  "Please confirm your Password": "Please confirm your Password",
  "Resend Code": "Resend Code",
  "Reset your password": "Reset your password",
  "Reset your Password": "Reset your Password",
  "Reset Password": "Reset password",
  "Enter your email": "Enter your email",
  "Code *": "Code *",
  "Send code": "Send code",
  "Send Code": "Send Code",
  "Sending": "Sending",
  "Setup TOTP": "Setup TOTP",
  "Show password": "Show password",
  "Sign in to your account": "Sign in to your account",
  "Sign In with Amazon": "Sign In with Amazon",
  "Sign In with Apple": "Sign In with Apple",
  "Sign In with Facebook": "Sign In with Facebook",
  "Sign In with Google": "Sign In with Google",
  "Sign in": "Sign in",
  "Sign In": "Sign in",
  "Signing in": "Signing in",
  "Skip": "Skip",
  "Submit": "Submit",
  "Submitting": "Submitting",
  "Username": "Username",
  "Verify Contact": "Verify Contact",
  "Verify": "Verify",
  "We Emailed You": "We Emailed You",
  "We Sent A Code": "We Sent A Code",
  "We Texted You": "We Texted You",
  "Your code is on the way. To log in, enter the code we emailed to": "Your code is on the way. To log in, enter the code we emailed to",
  "Your code is on the way. To log in, enter the code we sent you": "Your code is on the way. To log in, enter the code we sent you",
  "Your code is on the way. To log in, enter the code we texted to": "Your code is on the way. To log in, enter the code we texted to",
  "User does not exist.": "User does not exist.",
  "Incorrect username or password.": "Incorrect username or password.",
  "Your passwords must match": "Your passwords must match.",
  "Password must have at least 8 characters": "Password must have at least 8 characters.",
  "An account with the given email already exists.": "An account with the given email already exists.",
  "Invalid verification code provided, please try again.": "Invalid verification code provided, please try again.",
  "Password does not conform to policy: Password must have uppercase characters": "Password does not conform to policy: Password must have uppercase characters.",
  "Attempt limit exceeded, please try after some time.": "Attempt limit exceeded, please try after some time.",
  "Username/client id combination not found.": "Username or password is invalid."
}
