import translationEn from './locales/en/translation.json'
import translationFi from './locales/fi/translation.json'
import translationLoginEn from './locales/en/loginTranslation.json'
import translationLoginFi from './locales/fi/loginTranslation.json'

export const resources = {
  en: {
    translation: translationEn
  },
  fi: {
    translation: translationFi
  }
}

export const loginResources = {
  en: {
    translation: translationLoginEn
  },
  fi: {
    translation: translationLoginFi
  }
}

const localizationResources = {
  resources,
  loginResources
}

export default localizationResources
