import { LoginCustomerType } from '../hooks/useAuth'

export type MoovyUserGroup = 'ADMIN' | 'SALE' | 'LANDLORD'
export type MoovyAction = 'ALL' | 'READ' | 'CREATE' | 'EDIT' | 'DELETE'
export type MoovyResource =
  | 'ADMIN_PLACES'
  | 'ADMIN_INVITATIONS'
  | 'LANDLORD_INVITATIONS'
  | 'TENANT_INVITATIONS'
  | 'PLACES'
  | 'RIGHT_HOLDERS'
  | 'CUSTOMER'
  | 'RIGHTS'
  | 'CATALOGUES'
  | 'OFFERING'
  | 'USERS'
  | 'CATALOGUE_REPORTS'
  | 'RIGHT_REPORTS'

export type MoovyPermissionType = {
  action: MoovyAction[]
  resource: MoovyResource
}

export const isAllowed = (
  userPermissions: MoovyPermissionType[],
  action: MoovyAction,
  resource: MoovyResource
) => {
  const userPermission: MoovyPermissionType | undefined = userPermissions.find(
    (item) => item.resource === resource
  )
  return userPermission?.action?.includes(action) || false
}

const getPermission = (
  actions: MoovyAction[],
  resource: MoovyResource
): MoovyPermissionType => {
  if (actions.includes('ALL')) {
    return {
      action: ['READ', 'CREATE', 'EDIT', 'DELETE'],
      resource: resource
    }
  } else {
    return {
      action: actions,
      resource: resource
    }
  }
}

const getUserGroups = (userGroups: string): MoovyUserGroup[] => {
  const mapToUserGroups = (backendGroups: string[]): MoovyUserGroup[] => {
    return backendGroups.reduce((mappedGroups, group) => {
      if (['ADMIN', 'SALE', 'LANDLORD'].includes(group as MoovyUserGroup)) {
        mappedGroups.push(group as MoovyUserGroup)
      }
      return mappedGroups
    }, [] as MoovyUserGroup[])
  }

  const stringGroups = userGroups
    ? userGroups.split(',').map((item) => item.toLocaleUpperCase())
    : []
  return mapToUserGroups(stringGroups)
}

export const isAdmin = (userGroups: string): boolean => {
  const moovyUserGroups = getUserGroups(userGroups)
  return moovyUserGroups.includes('ADMIN')
}

export const isTenant = (customerType: LoginCustomerType): boolean => {
  return customerType === 'T'
}

export const getUserPermission = (
  customerType: LoginCustomerType,
  userGroups: string
): MoovyPermissionType[] => {
  const moovyUserGroups = getUserGroups(userGroups)

  // User belongs to admin user group
  if (moovyUserGroups.includes('ADMIN')) {
    return [
      getPermission(['READ', 'EDIT', 'DELETE'], 'ADMIN_PLACES'),
      getPermission(['ALL'], 'LANDLORD_INVITATIONS'),
      getPermission(['READ'], 'ADMIN_INVITATIONS'),
      getPermission(['READ', 'EDIT'], 'TENANT_INVITATIONS'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'PLACES'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'RIGHT_HOLDERS'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'CUSTOMER'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'RIGHTS'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'CATALOGUES'),
      getPermission(['READ', 'EDIT', 'DELETE'], 'OFFERING'),
      getPermission(['READ'], 'USERS')
    ]
  }
  // The customer type Landlord is found for the logged user
  else if (customerType === 'L') {
    return [
      getPermission(['ALL'], 'PLACES'),
      getPermission(['ALL'], 'RIGHT_HOLDERS'),
      getPermission(['ALL'], 'ADMIN_INVITATIONS'),
      getPermission(['ALL'], 'TENANT_INVITATIONS'),
      getPermission(['READ', 'EDIT'], 'CUSTOMER'),
      getPermission(['ALL'], 'RIGHTS'),
      getPermission(['ALL'], 'CATALOGUES'),
      getPermission(['ALL'], 'OFFERING'),
      getPermission(['READ', 'CREATE', 'EDIT'], 'USERS'),
      getPermission(['ALL'], 'CATALOGUE_REPORTS'),
      getPermission(['ALL'], 'RIGHT_REPORTS')
    ]
  }
  // These are the default permissions for all other logged users like Tenant customer
  return [
    getPermission(['ALL'], 'RIGHT_HOLDERS'),
    getPermission(['ALL'], 'ADMIN_INVITATIONS'),
    getPermission(['READ', 'EDIT'], 'CUSTOMER'),
    getPermission(['ALL'], 'RIGHTS'),
    getPermission(['ALL'], 'CATALOGUES'),
    getPermission(['READ', 'CREATE', 'EDIT'], 'USERS'),
    getPermission(['ALL'], 'RIGHT_REPORTS')
  ]
}

export const appRoutes = {
  ROOT: '/',
  LOGIN_STEPS: '/login-steps',
  INVITATIONS: '/invitations',
  RIGHT_HOLDERS: '/right-holders',
  PLACES: '/places',
  CUSTOMERS: '/customers',
  REPORTS: '/reports',
  USERS: '/users',
  OFFERINGS: '/offerings',
  CATALOGUES: '/catalogues'
}

export const appSubRoutes = {
  SUB_PLACES: '/sub-places'
}

const Permissions = {
  appRoutes,
  isAllowed,
  getUserPermission
}

export default Permissions
