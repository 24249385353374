import { MoovyButton, MoovyMutationError } from '../../components'
import { PlaceCreateDTO, PlaceResponseDTO } from '../../apis/places/dto'

import FormPlace from '../places/formPlace'
import { FormattedMessage } from 'react-intl'
import { Grid } from '@mui/material'
import { LoginStepProps } from '../utils/pagesTypeUtils'
import { MoovyToast } from '../../utils/moovyToast'
import { PlacesAPI } from '../../apis/places/placesAPI'
import useCustomerData from '../../hooks/useCustomerData'
import { useMutation } from '@tanstack/react-query'

const StepPlace = (props: LoginStepProps) => {
  const { customer, setPlace } = useCustomerData()
  const FORM_REFERENCE = 'formStepAddPlace'

  const createPlaceMutation = useMutation({
    mutationFn: (dto: PlaceCreateDTO) =>
      PlacesAPI.createPlaceWithDefaultOffering(dto),
    onSuccess: (response) => {
      const place: PlaceResponseDTO = response.data
      setPlace(place)
      MoovyToast.showToast(
        <FormattedMessage
          id="page.steps.place.actions.added"
          values={{ name: place.name }}
        />
      )
      props.onStepCompleted()
    }
  })

  const createPlace = (values: any) => {
    const customerId = customer.id
    const { name, streetAddress, postalCode, city, capacity } = values
    const createPlaceDto: PlaceCreateDTO = {
      name,
      streetAddress,
      postalCode,
      city,
      customerId,
      capacity
    }
    createPlaceMutation.mutate(createPlaceDto)
  }

  return (
    <>
      <h1>
        <FormattedMessage id="page.steps.place.title" />
      </h1>
      <MoovyMutationError
        mutation={createPlaceMutation}
        errorTitleKey={'common.actions.update.error'}
      />
      <p>
        <FormattedMessage id="page.steps.place.info" />
      </p>
      <FormPlace
        onSubmit={(values) => {
          createPlace(values)
        }}
        formReference={FORM_REFERENCE}
        customers={[]}
      />
      <Grid container pt={12} justifyContent="flex-end">
        <MoovyButton
          type="submit"
          mutation={createPlaceMutation}
          form={FORM_REFERENCE}
        >
          <FormattedMessage id="common.buttons.complete" />
        </MoovyButton>
      </Grid>
    </>
  )
}

export default StepPlace
