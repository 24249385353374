import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'
import { MoovyForm } from '..'
import isEmail from 'validator/lib/isEmail'

export interface DefaultInvitationValues {
  firstName: string
  lastName: string
  email: string
}

type SubmitFormCallback = (values: DefaultInvitationValues) => void
export type FormInvitationProps = {
  onSubmit: SubmitFormCallback
  formReference: string
  defaultValues?: DefaultInvitationValues
}

const FormInvitation = (props: FormInvitationProps) => {
  const intl = useIntl()

  const getFormDefaultValues = () => {
    return {
      firstName: props.defaultValues?.firstName || '',
      lastName: props.defaultValues?.lastName || '',
      email: props.defaultValues?.email || ''
    }
  }

  const [formikValidateOnChange, setFormikValidateOnChange] = useState(false)
  const [defaultValues, setDefaultValues] = useState<DefaultInvitationValues>(
    getFormDefaultValues()
  )

  useEffect(() => {
    setDefaultValues(getFormDefaultValues())
  }, [
    props.defaultValues?.firstName,
    props.defaultValues?.lastName,
    props.defaultValues?.email
  ])

  const invitationSchema = () => {
    return yup.object().shape({
      firstName: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      lastName: yup
        .string()
        .trim()
        .required(intl.formatMessage({ id: 'common.form.errors.empty' })),
      email: yup
        .string()
        .test(
          'email',
          intl.formatMessage({ id: 'common.form.errors.email' }),
          function (email?: string) {
            return !!email && isEmail(email)
          }
        )
    })
  }

  const onSubmitFormValues = (values: DefaultInvitationValues) => {
    const { firstName, lastName, email } = values
    props.onSubmit({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim()
    })
  }

  return (
    <>
      <Formik
        validate={() => setFormikValidateOnChange(true)}
        validationSchema={invitationSchema()}
        enableReinitialize={true}
        initialValues={defaultValues}
        validateOnChange={formikValidateOnChange}
        onSubmit={(values) => {
          onSubmitFormValues(values)
        }}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit} id={props.formReference}>
            <MoovyForm.FormContainer>
              <MoovyForm.FormTextField
                name="firstName"
                data-testid="firstName"
                value={values.firstName}
                autoFocus
                label={intl.formatMessage({
                  id: 'page.invitations.formInvitation.label.firstName'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.invitations.formInvitation.label.firstName'
                })}
                onChange={handleChange}
                error={!!errors.firstName}
                errorText={errors.firstName}
              />
              <MoovyForm.FormTextField
                name="lastName"
                data-testid="lastName"
                value={values.lastName}
                label={intl.formatMessage({
                  id: 'page.invitations.formInvitation.label.lastName'
                })}
                placeholder={intl.formatMessage({
                  id: 'page.invitations.formInvitation.label.lastName'
                })}
                onChange={handleChange}
                error={!!errors.lastName}
                errorText={errors.lastName}
              />
              <MoovyForm.FormTextField
                name="email"
                data-testid="email"
                value={values.email}
                label={<FormattedMessage id="common.text.email" />}
                placeholder={intl.formatMessage({
                  id: 'common.text.email'
                })}
                onChange={handleChange}
                error={!!errors.email}
                errorText={errors.email}
              />
            </MoovyForm.FormContainer>
          </form>
        )}
      </Formik>
    </>
  )
}

export default FormInvitation
