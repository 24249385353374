import { components } from './openapi'

export const API_ROOT_URL = '/v1'
export const API_ADMIN_ROOT_URL = '/admin/v1'
export const getBaseUrl = (admin: boolean) =>
  admin ? API_ADMIN_ROOT_URL : API_ROOT_URL

export type BasicPageSortQueryParams = {
  page?: number
  limit?: number
  direction?: components['schemas']['SortDirection']
}
