import { API_ADMIN_ROOT_URL, API_ROOT_URL } from '..'
import {
  PlaceCreateDTO,
  PlaceResponseDTO,
  PlaceSortQueryParams,
  PlacesPagingDTO
} from './dto'

import { OfferingResponseDTO } from '../offering/dto'
import { PlaceUpdateDTO } from './dto'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/places`
const BASE_ADMIN_URL = `${API_ADMIN_ROOT_URL}/places`

const createPlaceWithDefaultOffering = (place: PlaceCreateDTO) => {
  place.createDefaultOffering = true
  return client.post<PlaceResponseDTO>(BASE_URL, place)
}

const updatePlace = (ref: string, place: PlaceUpdateDTO) => {
  return client.put<PlaceResponseDTO>(`${BASE_URL}/${ref}`, place)
}

const getPlaceByRef = (ref: string) => {
  return client
    .get<PlaceResponseDTO>(`${BASE_URL}/${ref}`)
    .then((response) => response.data)
}

const getOfferingsByPlace = (ref: string) => {
  return client
    .get<OfferingResponseDTO[]>(`${BASE_URL}/${ref}/offering`)
    .then((response) => response.data)
}

const getPlaces = (queryParams: PlaceSortQueryParams | undefined) => {
  return client
    .get<PlacesPagingDTO>(
      BASE_ADMIN_URL,
      queryParams
        ? {
            params: {
              ...queryParams
            }
          }
        : undefined
    )
    .then((response) => response.data)
}

export const PlacesAPI = {
  createPlaceWithDefaultOffering,
  updatePlace,
  getPlaceByRef,
  getPlaces,
  getOfferingsByPlace
}

export const PlacesAdminAPI = {
  getPlaces
}
