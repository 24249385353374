import { setLocaleState, uiSelectors } from '../redux/globalUI'
import { useDispatch, useSelector } from 'react-redux'

export default function useLocale() {
  const dispatch = useDispatch()
  const setLocale = (value: string) => dispatch(setLocaleState(value))
  const locale: string = useSelector(uiSelectors.getLocale)
  return {
    setLocale,
    locale
  }
}
