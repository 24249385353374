import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'

import { CircularProgress } from '@mui/material'
import { Formik } from 'formik'
import { MoovyForm } from '../../../../../components'
import React from 'react'
import isEmail from 'validator/lib/isEmail'

type SubmitModalCallback = (values: { email: string }) => void
export type ModalFindRightHolderProps = {
  formReference: string
  email: string
  selectableOptions: string[]
  isFetching: boolean
  onSubmit: SubmitModalCallback
}

const StepFindRightHolder = (props: ModalFindRightHolderProps) => {
  const intl = useIntl()

  const schemaValidator = () => {
    return yup.object().shape({
      email: yup
        .string()
        .test(
          'email',
          intl.formatMessage({ id: 'common.form.errors.email' }),
          function (email?: string) {
            return !!email && isEmail(email)
          }
        )
    })
  }

  return (
    <Formik
      validationSchema={schemaValidator()}
      initialValues={{ email: props.email }}
      onSubmit={(values) => {
        props.onSubmit(values)
      }}
      enableReinitialize
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
        <form onSubmit={handleSubmit} id={props.formReference}>
          <MoovyForm.FormContainer>
            <div>
              <FormattedMessage id="page.catalogues.tabCatalogue.modalFindRightHolder.info" />
            </div>
            <MoovyForm.FormAutoCompleteTextField
              options={props.selectableOptions}
              value={values.email}
              freeSolo
              onChange={(event, value) => setFieldValue('email', value ?? '')}
              renderInput={(params) => (
                <MoovyForm.FormTextField
                  {...params}
                  name="email"
                  data-testid="email"
                  value={values.email}
                  label={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.modalFindRightHolder.form.label.email'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'page.catalogues.tabCatalogue.modalFindRightHolder.form.label.email'
                  })}
                  onChange={handleChange}
                  error={!!errors.email}
                  errorText={errors.email}
                  autoFocus
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {props.isFetching ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </MoovyForm.FormContainer>
        </form>
      )}
    </Formik>
  )
}

export default StepFindRightHolder
