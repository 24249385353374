import {
  MoovyAction,
  MoovyResource,
  getUserPermission,
  isAdmin,
  isAllowed,
  isTenant
} from '../app/permissions'
import {
  authSelectors,
  setAuthenticationState,
  setCustomerTypeState,
  setTokenState
} from '../redux/auth'
import { useDispatch, useSelector } from 'react-redux'

export type LoginCustomerType = 'L' | 'T'
export default function () {
  const dispatch = useDispatch()
  const setAuthentication = (value: { userGroups: string; token: string }) =>
    dispatch(setAuthenticationState(value))
  const setToken = (value: string) => dispatch(setTokenState(value))
  const setCustomerType = (value: LoginCustomerType) =>
    dispatch(setCustomerTypeState(value))
  const customerType = useSelector(authSelectors.getCustomerType)
  const tenant = isTenant(customerType)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const roles = useSelector(authSelectors.getRoles)
  const userGroups = useSelector(authSelectors.getUserGroups)
  const admin = isAdmin(userGroups)
  const token = useSelector(authSelectors.getToken)
  const usernameShort = useSelector(authSelectors.getShortUsername)
  const isPermission = (action: MoovyAction, resource: MoovyResource) => {
    const userPermissions = getUserPermission(customerType, userGroups)
    return (
      userPermissions.length > 0 && isAllowed(userPermissions, action, resource)
    )
  }

  return {
    setAuthentication,
    setToken,
    setCustomerType,
    isAuthenticated,
    customerType,
    roles,
    token,
    isPermission,
    userGroups,
    tenant,
    admin,
    usernameShort
  }
}
