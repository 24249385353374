import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CATALOGUES_BY_OFFERING,
  QUERY_OFFERINGS_GET_BY_PLACE
} from '../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyTab
} from '../../../components'
import {
  RightHolderIndividualDTO,
  RightHolderResponseDTO,
  RightHolderVehicleDTO
} from '../../../apis/rightHolders/dto'
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query'

import { PlacesAPI } from '../../../apis/places/placesAPI'
import { SS_RIGHT_HOLDERS_TAB_VEHICLES } from '../../../redux/globalKeys'
import TableVehicles from './tableVehicles'
import useAuth from '../../../hooks/useAuth'
import useCustomerData from '../../../hooks/useCustomerData'
import { useEffect } from 'react'

const RightsAndVehicles = (props: {
  queryRightHolder: UseQueryResult<RightHolderResponseDTO, any>
  individualRightHolder?: RightHolderIndividualDTO
  vehicles: RightHolderVehicleDTO[]
}) => {
  const intl = useIntl()
  const { place } = useCustomerData()
  const { isPermission } = useAuth()
  const queryClient = useQueryClient()

  const isOfferingFetchAllowed = isPermission('READ', 'OFFERING')

  const queryOfferings = useQuery({
    queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, place.id],
    queryFn: () => PlacesAPI.getOfferingsByPlace(place.id || ''),
    enabled: false,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const offeringId = queryOfferings.data?.[0]?.id

  useEffect(() => {
    if (offeringId) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_CATALOGUES_BY_OFFERING, offeringId]
      })
    }
  }, [queryOfferings.isFetched])

  const RenderVehicles = () => {
    return (
      <>
        <MoovyContainer>
          <MoovyContainerSubHeader
            title={
              <FormattedMessage id="page.rightHolders.rightHolder.tab.rightsAndVehicles.title" />
            }
            divider
          />
          <MoovyContainerContent>
            <TableVehicles
              vehicleRightHolders={props.vehicles}
              rightHolderId={props.individualRightHolder?.id || ''}
              queryRightHolder={props.queryRightHolder}
              allowToEndRight={!!props.individualRightHolder}
              refreshOffering={() =>
                isOfferingFetchAllowed &&
                queryClient.invalidateQueries({
                  queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, place.id]
                })
              }
            />
          </MoovyContainerContent>
        </MoovyContainer>
      </>
    )
  }

  const tabHeaders: string[] = [
    intl.formatMessage({
      id: 'page.rightHolders.rightHolder.tab.rightsAndVehicles.title'
    })
  ]
  const tabPanels = [<RenderVehicles />]

  return (
    <MoovyTab
      tabKey={SS_RIGHT_HOLDERS_TAB_VEHICLES}
      tabHeaders={tabHeaders}
      tabPanels={tabPanels}
    />
  )
}

export default RightsAndVehicles
