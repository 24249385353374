import {
  EndRightDTO,
  RightLicensePlateChangeDTO,
  RightResponseDTO
} from '../../apis/rights/dto'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyButton, MoovyDialog, MoovyDialogConfirmation } from '..'

import { BackendDatetime } from '../../utils/datetimeUtils'
import FormEditVehicle from './formEditVehicle'
import { MoovyToast } from '../../utils/moovyToast'
import RightsAPI from '../../apis/rights/rightsAPI'
import useAuth from '../../hooks/useAuth'
import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'

export type ModalEditVehicleProps = {
  open: boolean
  rightId: string
  licensePlate: string
  onTerminated: () => void
  onSubmit: (values: RightResponseDTO) => void
  onCancel: () => void
}

const ModalEditVehicle = ({
  open,
  rightId,
  licensePlate,
  onTerminated,
  onSubmit,
  onCancel
}: ModalEditVehicleProps) => {
  const FORM_REFERENCE = 'formEditVehicle'
  const intl = useIntl()
  const { isPermission } = useAuth()
  const [confirmationTerminateRight, setConfirmationTerminateRight] =
    useState('')

  const changeRightMutation = useMutation({
    mutationFn: (dto: RightLicensePlateChangeDTO) =>
      RightsAPI.changeLicensePlate(rightId, dto),
    onSuccess: (response) => {
      MoovyToast.showToast(
        <FormattedMessage id="modalEditVehicle.actions.change" />
      )
      onSubmit(response.data)
    }
  })

  const updateRightHolder = (licensePlate: string) => {
    const dto: RightLicensePlateChangeDTO = {
      licensePlate: licensePlate ? licensePlate.trim().toLocaleUpperCase() : ''
    }

    if (dto.licensePlate) {
      changeRightMutation.mutate(dto)
    }
  }

  const terminateRightMutation = useMutation({
    mutationFn: (endRight: EndRightDTO) =>
      RightsAPI.endRight(rightId, endRight),
    onSuccess: (response) => {
      setConfirmationTerminateRight('')
      onTerminated()
      MoovyToast.showToast(
        <FormattedMessage id="modalEditVehicle.actions.rightEnded" />
      )
    }
  })

  const cancel = () => {
    changeRightMutation.reset()
    onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        mutation={changeRightMutation}
        errorTitleKey={'common.actions.update.error'}
        modalTitle={<FormattedMessage id="modalEditVehicle.title" />}
        submitButtonContent={<FormattedMessage id="common.buttons.update" />}
        leftSideButtons={
          isPermission('DELETE', 'RIGHTS')
            ? [
                <MoovyButton
                  color="error"
                  onClick={() => {
                    setConfirmationTerminateRight(rightId)
                  }}
                  variant="text"
                  key={'terminate'}
                >
                  <FormattedMessage id="common.buttons.terminate" />
                </MoovyButton>
              ]
            : undefined
        }
      >
        <FormEditVehicle
          formReference={FORM_REFERENCE}
          licensePlate={licensePlate}
          onSubmit={updateRightHolder}
        />
      </MoovyDialog>
      <MoovyDialogConfirmation
        open={!!confirmationTerminateRight}
        lang={{
          confirmButton: 'common.buttons.yes',
          error: 'modalEditVehicle.actions.error.end'
        }}
        header={intl.formatMessage({
          id: 'modalEditVehicle.modalRemove.header'
        })}
        content={intl.formatMessage(
          {
            id: 'modalEditVehicle.modalRemove.content'
          },
          { licensePlate: licensePlate }
        )}
        onCancel={() => setConfirmationTerminateRight('')}
        onConfirm={() =>
          terminateRightMutation.mutate({
            validityEnd: BackendDatetime.nowToUTC()
          })
        }
        mutation={terminateRightMutation}
      />
    </>
  )
}

export default ModalEditVehicle
