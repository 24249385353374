import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_CATALOGUES_ACTIVE_RIGHTS,
  QUERY_CATALOGUES_BY_OFFERING,
  QUERY_CATALOGUES_BY_REF,
  QUERY_OFFERINGS_GET_BY_ID,
  QUERY_OFFERINGS_GET_BY_PLACE,
  QUERY_RIGHT_HOLDER_GET_BY_REF
} from '../../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyRestricted,
  MoovySortAndFilter
} from '../../../components'
import {
  MoovyPaginationProps,
  PagingAndSortingValues,
  convertPaginationValues,
  getNewPageAfterItemDeletion,
  getPagingAndSortInitValues
} from '../../../components/moovyPagination'
import {
  SS_CATALOGUE_RIGHTS_PAGING,
  SS_CATALOGUE_RIGHTS_SORTING
} from '../../../redux/globalKeys'
import {
  getDirection,
  getSortItems
} from '../../../components/moovySortAndFilter/sortValues'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { ActiveRightSortType } from '../../../apis/rights/dto'
import CataloguePlaceInfo from './cataloguePlaceInfo'
import { CatalogueResponseDTO } from '../../../apis/catalogues/dto'
import CatalogueStatusInfo from './catalogueStatusInfo'
import { CataloguesAPI } from '../../../apis/catalogues/cataloguesAPI'
import { Grid } from '@mui/material'
import { OfferingsAPI } from '../../../apis/offering/offeringsAPI'
import RightHolders from './rightHolders/rightHolders'
import useAuth from '../../../hooks/useAuth'
import useGlobalUI from '../../../hooks/useGlobalUI'
import { useState } from 'react'

export type TabCatalogueProps = {
  catalogue: CatalogueResponseDTO | undefined
}

const TabCatalogue = ({ catalogue }: TabCatalogueProps) => {
  const queryClient = useQueryClient()
  const { tenant } = useAuth()
  const [showModalAddRightHolder, setShowModalAddRightHolder] = useState(false)
  const [showModalAddVehicle, setShowModalAddVehicle] = useState(false)

  const place = catalogue?.place
  const customerId = catalogue?.customer?.id
  const catalogueId = catalogue?.id

  const intl = useIntl()
  const [pagingAndSortValues, setPagingAndSortValues] = useGlobalUI({
    key: SS_CATALOGUE_RIGHTS_PAGING,
    initialValue: getPagingAndSortInitValues<ActiveRightSortType>('VEHICLE')
  })

  const queryOffering = useQuery({
    queryKey: [QUERY_OFFERINGS_GET_BY_ID, catalogue?.offering.id],
    queryFn: () => OfferingsAPI.getOfferingById(catalogue?.offering.id || ''),
    enabled: !!catalogue?.offering.id && !tenant,
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const offering = queryOffering.data

  const queryActiveRights = useQuery({
    queryKey: [
      QUERY_CATALOGUES_ACTIVE_RIGHTS,
      catalogueId,
      pagingAndSortValues.page,
      pagingAndSortValues.sort
    ],
    queryFn: () => {
      const { page, limit, sort, direction } = convertPaginationValues(
        pagingAndSortValues
      ) as PagingAndSortingValues
      return CataloguesAPI.getActiveRightsById(catalogueId || '', {
        page,
        limit,
        sort: sort as ActiveRightSortType,
        direction
      })
    },
    enabled: !!catalogueId
  })

  const pagination: MoovyPaginationProps | undefined = queryActiveRights.data
    ?.pageable
    ? {
        page: queryActiveRights.data.pageable.page,
        total: queryActiveRights.data.pageable.total,
        limit: queryActiveRights.data.pageable.limit,
        onNextPage: (page) =>
          setPagingAndSortValues({
            ...pagingAndSortValues,
            page: page.toString()
          })
      }
    : undefined

  const refreshView = (individualId?: string) => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_CATALOGUES_BY_REF, catalogueId]
    })
    if (offering?.id) {
      queryClient.invalidateQueries({
        queryKey: [QUERY_CATALOGUES_BY_OFFERING, offering.id]
      })
      queryClient.invalidateQueries({
        queryKey: [QUERY_OFFERINGS_GET_BY_ID, offering.id]
      })
    }
    queryClient.invalidateQueries({
      queryKey: [QUERY_OFFERINGS_GET_BY_PLACE, place?.id]
    })
    individualId &&
      queryClient.invalidateQueries({
        queryKey: [QUERY_RIGHT_HOLDER_GET_BY_REF, individualId]
      })
  }

  const isWhitelist = offering?.type === 'WHITELIST'

  const items = getSortItems(intl, 'ACTIVE_RIGHTS').filter(
    (item) => !isWhitelist || item.key !== 'INDIVIDUAL'
  )

  const onSubmitAdd = (individualId?: string) => {
    setShowModalAddRightHolder(false)
    setShowModalAddVehicle(false)
    refreshView(individualId)
  }
  const onSubmitEdit = (individualId?: string) => {
    refreshView(individualId)
  }

  const onTerminate = (individualId?: string) => {
    const newPage = getNewPageAfterItemDeletion(pagination)
    if (newPage) {
      setPagingAndSortValues({
        ...pagingAndSortValues,
        page: newPage
      })
    }
    refreshView(individualId)
  }

  const onCancel = () => {
    setShowModalAddVehicle(false)
    setShowModalAddRightHolder(false)
  }

  return (
    <>
      <MoovyContainer>
        <MoovyContainerSubHeader
          title={catalogue?.offering?.name}
          props={{ paddingTop: 4 }}
        />
        <MoovyContainerContent>
          <Grid container spacing={2} direction="row">
            <Grid item md={6} xs={12}>
              <CatalogueStatusInfo
                catalogue={catalogue}
                whitelistOffering={isWhitelist}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CataloguePlaceInfo place={place} />
            </Grid>
          </Grid>
          <MoovyContainerSubHeader
            props={{ pl: 0, pr: 0 }}
            title={
              <FormattedMessage id="page.catalogues.tabCatalogue.title.rightHolder" />
            }
            titleProps={{
              pb: 2
            }}
            description={
              <MoovySortAndFilter
                filterKey={SS_CATALOGUE_RIGHTS_SORTING}
                items={items}
                value={pagingAndSortValues.sort}
                onChange={(value) => {
                  pagingAndSortValues.sort !== value &&
                    setPagingAndSortValues({
                      ...pagingAndSortValues,
                      sort: value,
                      direction: getDirection(value as ActiveRightSortType)
                    })
                }}
              />
            }
            button={
              <MoovyRestricted action="CREATE" resource="RIGHT_HOLDERS">
                <div>
                  {isWhitelist ? (
                    <MoovyButton onClick={() => setShowModalAddVehicle(true)}>
                      <FormattedMessage id="page.catalogues.tabCatalogue.button.addVehicle" />
                    </MoovyButton>
                  ) : (
                    <MoovyButton
                      onClick={() => setShowModalAddRightHolder(true)}
                    >
                      <FormattedMessage id="page.catalogues.tabCatalogue.button.addIndividual" />
                    </MoovyButton>
                  )}
                </div>
              </MoovyRestricted>
            }
          />
          <RightHolders
            queryActiveRights={queryActiveRights}
            pagination={pagination}
            customerId={customerId || ''}
            catalogue={catalogue}
            place={{ id: place?.id || '', name: place?.name || '' }}
            offering={offering}
            showModalAddRightHolder={showModalAddRightHolder}
            showModalAddVehicle={showModalAddVehicle}
            onSubmitAdd={onSubmitAdd}
            onSubmitEdit={onSubmitEdit}
            onTerminate={onTerminate}
            onCancel={onCancel}
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </>
  )
}

export default TabCatalogue
