import { FormattedMessage } from 'react-intl'
import { ReactNode } from 'react'
import { styled } from '@mui/material'

interface AllocatedRightsProps {
  amount: number
  activeRights: number
  isCatalogue?: boolean
}

interface AllocatedRightItemProps {
  labelText: string | ReactNode
  value: string | ReactNode
  color?: 'RED' | 'GREEN'
}

export const AllocatedRightItem: React.FC<AllocatedRightItemProps> = ({
  labelText,
  value,
  color
}) => {
  const ColoredValue = styled('span')(
    ({ theme }) => `
    font-weight: bold;
    ${color === 'GREEN' ? `color: ${theme.palette.success.main};` : ''}
    ${color === 'RED' ? `color: ${theme.palette.error.main};` : ''}
  `
  )

  const Item = styled('span')`
    display: block;
    margin: 0;
  `
  const Value = styled('span')`
    font-weight: bold;
  `

  const StyledLabel = styled('span')`
    margin-right: 0.3rem;
  `

  return (
    <Item>
      <StyledLabel>{labelText}</StyledLabel>
      {color ? <ColoredValue>{value}</ColoredValue> : <Value>{value}</Value>}
    </Item>
  )
}

export const AllocatedRights: React.FC<AllocatedRightsProps> = ({
  amount,
  activeRights,
  isCatalogue
}) => {
  const availableRights = !isCatalogue ? amount - activeRights : activeRights
  const percentage =
    availableRights > 0 && amount > 0
      ? Math.round((availableRights / amount) * 100)
      : 0

  const greenText = !isCatalogue && percentage && percentage <= 100
  const redText =
    (!isCatalogue && availableRights <= 0) || (isCatalogue && percentage > 100)

  return (
    <>
      <AllocatedRightItem
        labelText={
          !isCatalogue ? (
            <FormattedMessage id="allocatedRights.rightsTotal" />
          ) : (
            <FormattedMessage id="allocatedRights.rightsGranted" />
          )
        }
        value={amount}
      />
      <AllocatedRightItem
        labelText={
          !isCatalogue ? (
            <FormattedMessage id="allocatedRights.available" />
          ) : (
            <FormattedMessage id="allocatedRights.rightsShared" />
          )
        }
        value={
          <>
            {availableRights > 0 ? availableRights : 0} {`(${percentage} %)`}
          </>
        }
        color={greenText || redText ? (greenText ? 'GREEN' : 'RED') : undefined}
      />
    </>
  )
}
