import {
  Breakpoint,
  Grid,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material'
import React, { ReactNode } from 'react'

interface ResponsiveListProps {
  data: {
    label: string | React.ReactNode
    value?: string | number | ReactNode
  }[]
  breakpoint?: Breakpoint
  splitByMiddle?: boolean
}

const MoovyListLabelValue: React.FC<ResponsiveListProps> = ({
  data,
  breakpoint,
  splitByMiddle
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(breakpoint || 'sm'))

  if (isMobile) {
    return (
      <List>
        {data.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={item.label}
              secondary={
                <span style={{ fontWeight: 'bold' }}>{item.value}</span>
              }
            />
          </ListItem>
        ))}
      </List>
    )
  }
  return data.map((item, index) => (
    <Grid container key={index}>
      <Grid item xs={splitByMiddle ? 5 : 4}>
        {item.label}
      </Grid>
      <Grid item xs={1}>
        :
      </Grid>
      <Grid item xs={splitByMiddle ? 6 : 5}>
        <span style={{ fontWeight: 'bold' }}>{item.value}</span>
      </Grid>
    </Grid>
  ))
}

export default MoovyListLabelValue
