import { isValidPhoneNumber as isValid } from 'libphonenumber-js'

export namespace MoovyFormRestrictions {
  export const INPUT_MAX_CHARS = 255
}

export const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  if (!phoneNumber) return false

  return isValid(phoneNumber)
}

export const isValidLicensePlate = (licensePlateNumber?: string): boolean => {
  const pattern = /^[A-ZÅÄÖa-zåäö0-9\\s-]{2,10}$/
  return !!licensePlateNumber && pattern.test(licensePlateNumber)
}
