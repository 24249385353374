import { DateTime } from 'luxon'

export namespace BackendDatetime {
  export const nowToUTC = (): string => {
    return toUTC(DateTime.local())
  }
  export const toUTC = (datetime: DateTime): string => {
    if (!datetime.isValid) {
      console.error('Invalid datetime was given. ', datetime)
      return ''
    }
    return datetime.toUTC().toISO() || ''
  }
  export const fromUTC = (
    utcDatetime: string | undefined,
    locale: string
  ): DateTime => {
    return DateTime.fromISO(utcDatetime || '').setLocale(locale)
  }
}

export namespace UIDatetime {
  export const toString = (datetime: DateTime): string => {
    return datetime.toLocaleString(DateTime.DATETIME_SHORT)
  }
}

export namespace UIDate {
  export const toString = (datetime: DateTime): string => {
    return datetime.toLocaleString(DateTime.DATE_SHORT)
  }

  export const toCustomString = (
    datetime: DateTime,
    format: string = 'yyyyMMdd'
  ): string => {
    return datetime.toFormat(format)
  }
}
