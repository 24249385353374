import { configureStore } from '@reduxjs/toolkit'
import moovyAuth from './auth'
import moovyUi from './globalUI'

export const store = configureStore({
  reducer: { moovyUi, moovyAuth }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
