import { MoovyAction, MoovyResource } from '../../app/permissions'
import React, { ReactNode } from 'react'

import MoovyRestricted from '../moovyRestricted'

export interface MoovyContainerContentProps {
  action?: MoovyAction
  resource?: MoovyResource
  children: ReactNode
}

const MoovyContainerContent: React.FC<MoovyContainerContentProps> = ({
  action,
  resource,
  children
}) => {
  return (
    <>
      {action && resource ? (
        <MoovyRestricted action={action} resource={resource}>
          {children}
        </MoovyRestricted>
      ) : (
        <>{children}</>
      )}
    </>
  )
}

export default MoovyContainerContent
