import MoovyBreadcrumbs, { BreadBrumbsRoute } from '../moovyBreadcrumbs'
import React, { ReactNode } from 'react'

export type MoovyContainerBreadcrumbsProps = {
  lastBreadcrumb: string
  customBreadcrumbs?: BreadBrumbsRoute[]
}
export type MoovyContainerHeaderProps = {
  breadcrumbs?: MoovyContainerBreadcrumbsProps
  children: ReactNode
}

const MoovyContainerHeader: React.FC<MoovyContainerHeaderProps> = ({
  breadcrumbs,
  children
}) => {
  return (
    <>
      {breadcrumbs && (
        <MoovyBreadcrumbs
          lastBreadcrumb={breadcrumbs?.lastBreadcrumb}
          customBreadcrumbs={breadcrumbs?.customBreadcrumbs}
        />
      )}
      {children}
    </>
  )
}

export default MoovyContainerHeader
