import { setUiState, uiSelectors } from '../redux/globalUI'
import { useDispatch, useSelector } from 'react-redux'

export type GlobalUiProps = {
  key: string
  initialValue: Record<string, string>
}

export default function useGlobalUI(options: GlobalUiProps) {
  const dispatch = useDispatch()

  const { key, initialValue } = options
  const updateFunction = (value: any) => dispatch(setUiState(key, value))
  const exists = useSelector((state) => uiSelectors.keyExists(state, key))
  const selector = useSelector((state) => uiSelectors.getUiState(state, key))

  // Value in Redux, function to update value
  return [exists ? selector : initialValue, updateFunction]
}
