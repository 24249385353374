import { MoovyContainer, MoovyContainerContent } from '..'
import React, { useState } from 'react'
import { UseQueryResult, useQueryClient } from '@tanstack/react-query'

import { Box } from '@mui/material'
import { InvitationResponseDTO } from '../../apis/invitations/dto'
import Invitations from './invitations'
import { QUERY_INVITATION_GET_ADMINS } from '../../apis/moovyQueryKeys'
import useAuth from '../../hooks/useAuth'

type InvitationsAdminProps = {
  queryInvitations: UseQueryResult<InvitationResponseDTO[], any>
  customerId: string
}
const InvitationsAdmin = ({
  queryInvitations,
  customerId
}: InvitationsAdminProps) => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const queryClient = useQueryClient()
  const { isPermission } = useAuth()

  /*
  // TODO Antti: Paging is missing from backend implementation
  const [pagingAndSortValues, setPagingAndSortValues] = useGlobalUI({
    key: SS_INVITATIONS_ADMIN_PAGING,
    initialValue: getPagingAndSortInitValues<InvitationSortType>('USED')
  })

  const pagination: MoovyPaginationProps | undefined = queryInvitations.data
    ?.pageable
    ? {
        page: queryInvitations.data.pageable.page,
        total: queryInvitations.data.pageable.total,
        limit: queryInvitations.data.pageable.limit,
        onNextPage: (page) =>
          setPagingAndSortValues({
            ...pagingAndSortValues,
            page: page.toString()
          })
      }
    : undefined
*/

  const onInvitationRemoved = () => {
    /*
    // TODO Antti: Paging is missing from backend implementation
    const newPage = getNewPageAfterItemDeletion(pagination)
    if (newPage) {
      setPagingAndSortValues({
        ...pagingAndSortValues,
        page: newPage
      })
    }
*/
    onRefreshView()
  }

  const onRefreshView = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_INVITATION_GET_ADMINS, customerId]
    })
  }

  return (
    <Box sx={{ paddingTop: 2 }}>
      <MoovyContainer loading={queryInvitations.isPending}>
        <MoovyContainerContent>
          <Invitations
            queryInvitations={queryInvitations}
            permissionAccess={{
              action: 'DELETE',
              resource: 'ADMIN_INVITATIONS'
            }}
            addingInvitationParams={{
              invitationType: 'ADMIN',
              landlordId: customerId
            }}
            showModalAdd={showModalAdd}
            setShowModalAdd={setShowModalAdd}
            onInvitationRemoved={onInvitationRemoved}
            onInvitationAdded={onRefreshView}
            visibleColumns={
              isPermission('DELETE', 'ADMIN_INVITATIONS')
                ? ['NAME', 'EMAIL', 'INVITED', 'REMOVE']
                : ['NAME', 'EMAIL', 'INVITED']
            }
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </Box>
  )
}

export default InvitationsAdmin
