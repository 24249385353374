import {
  EndRightDTO,
  RightLicensePlateChangeDTO,
  RightResponseDTO
} from './dto'

import { API_ROOT_URL } from '..'
import { client } from '../moovyAxiosClient'

const BASE_URL = `${API_ROOT_URL}/rights`

const changeLicensePlate = (
  rightHolderRef: string,
  rightLicensePlateChange: RightLicensePlateChangeDTO
) => {
  return client.post<RightResponseDTO>(
    `${BASE_URL}/${rightHolderRef}/change-license-plate`,
    rightLicensePlateChange
  )
}

const endRight = (rightHolderRef: string, right: EndRightDTO) => {
  return client.post<RightResponseDTO>(
    `${BASE_URL}/${rightHolderRef}/end`,
    right
  )
}

const RightsAPI = {
  changeLicensePlate,
  endRight
}

export default RightsAPI
