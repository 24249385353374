import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_INVITATION_GET_LANDLORDS
} from '../../apis/moovyQueryKeys'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerHeader,
  MoovyContainerSubHeader,
  MoovyRestricted,
  MoovyScrollToTop,
  MoovySortAndFilter
} from '..'
import {
  MoovyPaginationProps,
  PagingAndSortingValues,
  convertPaginationValues,
  getNewPageAfterItemDeletion,
  getPagingAndSortInitValues
} from '../moovyPagination'
import React, { useState } from 'react'
import {
  SS_INVITATIONS_LANDLORD_PAGING,
  SS_INVITATIONS_LANDLORD_SORTING
} from '../../redux/globalKeys'
import { getDirection, getSortItems } from '../moovySortAndFilter/sortValues'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { InvitationSortType } from '../../apis/invitations/dto'
import Invitations from './invitations'
import InvitationsAPI from '../../apis/invitations/invitationsAPI'
import useAuth from '../../hooks/useAuth'
import useGlobalUI from '../../hooks/useGlobalUI'

const InvitationsLandlord = () => {
  const { admin } = useAuth()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const [showModalAdd, setShowModalAdd] = useState(false)

  const [pagingAndSortValues, setPagingAndSortValues] = useGlobalUI({
    key: SS_INVITATIONS_LANDLORD_PAGING,
    initialValue: getPagingAndSortInitValues<InvitationSortType>(
      'CREATED_AT',
      'DESC'
    )
  })

  const queryInvitations = useQuery({
    queryKey: [
      QUERY_INVITATION_GET_LANDLORDS,
      pagingAndSortValues.page,
      pagingAndSortValues.sort
    ],
    queryFn: () => {
      const { page, limit, sort, direction } = convertPaginationValues(
        pagingAndSortValues
      ) as PagingAndSortingValues

      return InvitationsAPI.getInvitations(admin, {
        page,
        limit,
        sort: sort as InvitationSortType,
        direction
      })
    },
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const pagination: MoovyPaginationProps | undefined = queryInvitations.data
    ?.pageable
    ? {
        page: queryInvitations.data.pageable.page,
        total: queryInvitations.data.pageable.total,
        limit: queryInvitations.data.pageable.limit,
        onNextPage: (page) =>
          setPagingAndSortValues({
            ...pagingAndSortValues,
            page: page.toString()
          })
      }
    : undefined

  const items = getSortItems(intl, 'INVITATIONS')

  const onInvitationRemoved = () => {
    const newPage = getNewPageAfterItemDeletion(pagination)
    if (newPage) {
      setPagingAndSortValues({
        ...pagingAndSortValues,
        page: newPage
      })
    }
    onRefreshView()
  }

  const onRefreshView = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_INVITATION_GET_LANDLORDS]
    })
  }

  return (
    <>
      <MoovyScrollToTop />
      <MoovyContainer loading={queryInvitations.isPending}>
        <MoovyContainerHeader>
          <FormattedMessage id="page.invitations.list.title" />
        </MoovyContainerHeader>
        <MoovyContainerSubHeader
          description={
            <MoovySortAndFilter
              filterKey={SS_INVITATIONS_LANDLORD_SORTING}
              items={items}
              value={pagingAndSortValues.sort}
              onChange={(value) => {
                pagingAndSortValues.sort !== value &&
                  setPagingAndSortValues({
                    ...pagingAndSortValues,
                    sort: value,
                    direction: getDirection(value as InvitationSortType)
                  })
              }}
            />
          }
          button={
            <MoovyRestricted action="CREATE" resource="LANDLORD_INVITATIONS">
              <MoovyButton onClick={() => setShowModalAdd(true)}>
                <FormattedMessage id="page.invitations.list.button.landlord.newInvitation" />
              </MoovyButton>
            </MoovyRestricted>
          }
        />
        <MoovyContainerContent>
          <Invitations
            queryInvitations={queryInvitations}
            permissionAccess={{
              action: 'DELETE',
              resource: 'LANDLORD_INVITATIONS'
            }}
            addingInvitationParams={{
              invitationType: 'LANDLORD'
            }}
            showModalAdd={showModalAdd}
            setShowModalAdd={setShowModalAdd}
            pagination={pagination}
            onInvitationRemoved={onInvitationRemoved}
            onInvitationAdded={onRefreshView}
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </>
  )
}

export default InvitationsLandlord
