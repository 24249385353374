import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_PLACES_BY_CUSTOMER
} from '../../../apis/moovyQueryKeys'
import { MoovyContainer, MoovyContainerContent } from '../../../components'

import CustomerInformation from '../customerInformation/customerInformation'
import CustomerTabs from './customerTabs'
import CustomersAPI from '../../../apis/customers/customersAPI'
import { Grid } from '@mui/material'
import React from 'react'
import useAuth from '../../../hooks/useAuth'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

const Customer = () => {
  let { customerRef } = useParams()
  const { admin, isPermission } = useAuth()
  const customerId = customerRef || ''

  const queryPlaces = useQuery({
    queryKey: [QUERY_PLACES_BY_CUSTOMER, customerId],
    queryFn: () => CustomersAPI.getPlacesByCustomer(admin, customerId),
    enabled: !!customerId && isPermission('READ', 'PLACES'),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const enableTabPlaces = !!queryPlaces.data && queryPlaces.data.length > 0
  const enableTabAdmins = isPermission('READ', 'ADMIN_INVITATIONS')
  const showTabs = enableTabPlaces || enableTabAdmins

  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={12}>
        <CustomerInformation customerId={customerId} />
      </Grid>
      {showTabs && (
        <Grid item xs={12}>
          <MoovyContainer>
            <MoovyContainerContent>
              <CustomerTabs
                customerId={customerId}
                enableTabPlaces={enableTabPlaces}
                enableTabAdmins={enableTabAdmins}
                queryPlaces={queryPlaces}
              />
            </MoovyContainerContent>
          </MoovyContainer>
        </Grid>
      )}
    </Grid>
  )
}

export default Customer
