import { Button, CircularProgress, IconButton } from '@mui/material'
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query'

import { AxiosResponse } from 'axios'
import LoadingButton from '@mui/lab/LoadingButton'
import React from 'react'

type ButtonProps = React.ComponentProps<typeof Button>
interface MoovyIconButtonProps extends Omit<ButtonProps, 'aria-label'> {
  mutation?: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>
  query?: UseQueryResult<any, any>
  showAsIconButton: true
  'aria-label': string
}
interface MoovyButtonTextProps extends ButtonProps {
  mutation?: UseMutationResult<AxiosResponse<any, any>, unknown, any, unknown>
  query?: UseQueryResult<any, any>
  showAsIconButton?: false | undefined
}

type MoovyButtonProps = MoovyIconButtonProps | MoovyButtonTextProps

const MoovyButton = (props: MoovyButtonProps) => {
  const { mutation, query, startIcon, showAsIconButton, ...rest } = props
  const isLoading =
    props?.mutation?.isPending ||
    props?.query?.isFetching ||
    props?.query?.isLoading

  const NormalButton = () => {
    return (
      <LoadingButton
        {...rest}
        disabled={isLoading || !!props.disabled}
        loading={isLoading}
        variant={props.variant || 'contained'}
      >
        {props?.children}
      </LoadingButton>
    )
  }

  const IconOnlyButton = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          visibility: rest.hidden ? 'hidden' : undefined
        }}
      >
        <IconButton disabled={isLoading || !!props.disabled} {...rest}>
          {startIcon}
        </IconButton>
        {isLoading && (
          <CircularProgress size={24} style={{ position: 'absolute' }} />
        )}
      </div>
    )
  }

  return <>{showAsIconButton ? <IconOnlyButton /> : <NormalButton />}</>
}

export default MoovyButton
