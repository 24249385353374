import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_INVITATION_GET_ADMINS
} from '../../../../apis/moovyQueryKeys'

import { Box } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import InvitationsAPI from '../../../../apis/invitations/invitationsAPI'
import { MoovyLink } from '../../../../components'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import { useQuery } from '@tanstack/react-query'

const InvitationsOngoing = ({
  customerId,
  onLinkClicked
}: {
  customerId: string
  onLinkClicked: () => void
}) => {
  const queryInvitations = useQuery({
    queryKey: [QUERY_INVITATION_GET_ADMINS, customerId],
    queryFn: () => InvitationsAPI.getInvitationsByCustomerId(false, customerId),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const invitationsCount = queryInvitations.data
    ? queryInvitations.data.length
    : 0

  return (
    <>
      <Box pt={1} visibility={invitationsCount > 0 ? 'visible' : 'hidden'}>
        <MoovyLink onClick={onLinkClicked}>
          <Box display="flex" alignItems="center">
            <WarningRoundedIcon />
            <Box marginLeft="5px" paddingTop="2px">
              <FormattedMessage
                values={{ count: invitationsCount }}
                id="page.customers.tabAdministrators.invitationsOngoing.link"
              />
            </Box>
          </Box>
        </MoovyLink>
      </Box>
    </>
  )
}

export default InvitationsOngoing
