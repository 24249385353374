import {
  DEFAULT_CACHE_TIME_MS,
  QUERY_INVITATION_GET_TENANTS
} from '../../apis/moovyQueryKeys'
import {
  MoovyButton,
  MoovyContainer,
  MoovyContainerContent,
  MoovyContainerSubHeader,
  MoovyRestricted,
  MoovyScrollToTop
} from '..'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { FormattedMessage } from 'react-intl'
import Invitations from './invitations'
import InvitationsAPI from '../../apis/invitations/invitationsAPI'
import useAuth from '../../hooks/useAuth'

const InvitationsTenant = ({
  tenantId,
  landlordId,
  catalogueId
}: {
  tenantId: string
  landlordId: string
  catalogueId: string
}) => {
  const { admin } = useAuth()
  const queryClient = useQueryClient()

  const [showModalAdd, setShowModalAdd] = useState(false)

  const queryInvitations = useQuery({
    queryKey: [QUERY_INVITATION_GET_TENANTS, tenantId],
    queryFn: () =>
      InvitationsAPI.getInvitationsByCustomerId(admin, tenantId, true),
    staleTime: DEFAULT_CACHE_TIME_MS
  })

  const onRefreshView = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_INVITATION_GET_TENANTS]
    })
  }

  return (
    <>
      <MoovyScrollToTop />
      <MoovyContainer loading={queryInvitations.isPending}>
        <MoovyContainerSubHeader
          button={
            <MoovyRestricted action="CREATE" resource="TENANT_INVITATIONS">
              <MoovyButton onClick={() => setShowModalAdd(true)}>
                <FormattedMessage id="page.invitations.list.button.tenant.newInvitation" />
              </MoovyButton>
            </MoovyRestricted>
          }
        />
        <MoovyContainerContent>
          <Invitations
            queryInvitations={queryInvitations}
            addingInvitationParams={{
              invitationType: 'TENANT',
              landlordId,
              tenantId,
              catalogueId
            }}
            permissionAccess={{
              action: 'DELETE',
              resource: 'TENANT_INVITATIONS'
            }}
            showModalAdd={showModalAdd}
            setShowModalAdd={setShowModalAdd}
            onInvitationRemoved={onRefreshView}
            onInvitationAdded={onRefreshView}
          />
        </MoovyContainerContent>
      </MoovyContainer>
    </>
  )
}

export default InvitationsTenant
