import {
  CustomerResponseDTO,
  CustomerUpdateDTO
} from '../../../apis/customers/dto'
import FormCustomer, { DefaultCustomerValues } from '../formCustomer'
import { getValueIfNoMatch, hasAnyValues } from '../../../utils/utils'

import CustomersAPI from '../../../apis/customers/customersAPI'
import { FormattedMessage } from 'react-intl'
import { MoovyDialog } from '../../../components'
import { useMutation } from '@tanstack/react-query'

type SubmitModalCallback = (response: CustomerResponseDTO) => void
type CancelModalCallback = () => void
export type ModalEditCustomerProps = {
  open: boolean
  defaultValues: any
  editableCustomerRef: string
  onSubmit: SubmitModalCallback
  onCancel: CancelModalCallback
}

const ModalEditCustomer = (props: ModalEditCustomerProps) => {
  const FORM_REFERENCE = 'formUpdateCustomer'
  const { defaultValues } = props

  const updateCustomerMutation = useMutation({
    mutationFn: (dto: CustomerUpdateDTO) =>
      CustomersAPI.updateCustomer(props.editableCustomerRef, dto),
    onSuccess: (response) => {
      props.onSubmit(response.data)
    }
  })

  const updateCustomer = (values: DefaultCustomerValues) => {
    const { name, streetAddress, postalCode, city } = values
    const dto: CustomerUpdateDTO = {
      name: getValueIfNoMatch(name, defaultValues.name),
      streetAddress: getValueIfNoMatch(
        streetAddress,
        defaultValues.streetAddress
      ),
      postalCode: getValueIfNoMatch(postalCode, defaultValues.postalCode),
      city: getValueIfNoMatch(city, defaultValues.city),
      businessId: undefined // Not allowed to modify by frontend
    }

    if (hasAnyValues(dto)) {
      updateCustomerMutation.mutate(dto)
    }
  }

  const cancel = () => {
    updateCustomerMutation.reset()
    props.onCancel()
  }

  return (
    <>
      <MoovyDialog
        open={props.open}
        onCancel={cancel}
        formReference={FORM_REFERENCE}
        errorTitleKey="common.actions.update.error"
        modalTitle={
          <FormattedMessage id="page.customer.modalEditCustomer.title" />
        }
        submitButtonContent={<FormattedMessage id="common.buttons.save" />}
        mutation={updateCustomerMutation}
      >
        <FormCustomer
          showTitle
          onSubmit={(values) => {
            updateCustomer(values)
          }}
          formReference={FORM_REFERENCE}
          defaultValues={defaultValues}
          editView
        />
      </MoovyDialog>
    </>
  )
}

export default ModalEditCustomer
